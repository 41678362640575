export default {
  key: "HrdcSettingsCompany",
  serviceKey: "hrdc",
  name: {
    singular: "Company",
    plural: "Companies",
  },
  parent_route: "HrdcSettings",
  add: {
    name: "Company",
  },
  view: {
    name: "Company",
  },
};
