<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="claimApprovedDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          Claim Approved from HRDC
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to Approve Claim From HRDC
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="modalClaimApprove()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="claimApprovedDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <div>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR">
          <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="!loadingDialog">
            <div class="d-flex align-center text-white">
              {{ model.add.name }}
            </div>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <span v-if="(isHrdcAdminRoles || isTaxpodAdminRoles) && !btnDisabled">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  class="hidden-xs-only text-white"
                  :loading="apiSubmit.isLoading"
                  @click="claimApprovedDialog = true"
                >
                  <v-icon dark v-bind="attrs" v-on="on">
                    mdi-file-document-arrow-right
                  </v-icon>
                </v-btn>
              </template>
              <span>Approve Claim</span>
            </v-tooltip>
          </span>
        </v-toolbar>
      </div>

      <v-row>
        <v-col lg="6" md="12" sm="12">
          <v-card
            class="pa-2 ma-2"
            :style="{ backgroundColor: HRDC_CARD_COLOR }"
            outlined
          >
            <!-- Claim ID -->
            <v-col class="d-flex" cols="12" sm="12" md="12">
              <v-text-field
                dense
                filled
                disabled
                label="Claim ID"
                v-model="formData.claimId"
                :error-messages="errorField.claimId"
              ></v-text-field>
            </v-col>

            <!-- Event Date -->
            <v-col
              class="d-flex"
              cols="12"
              sm="12"
              md="12"
              v-if="uploadClientInvoice"
            >
              <v-text-field
                dense
                filled
                disabled
                label="Event Date"
                v-model="formData.eventDate"
                :error-messages="errorField.eventDate"
              ></v-text-field>
            </v-col>

            <!-- Option to upload invoice or not -->
            <v-col
              class="d-flex"
              cols="12"
              sm="12"
              md="12"
              v-if="uploadClientInvoice"
            >
              <v-select
                :items="uploadInvoiceOption"
                filled
                dense
                label="Upload Client Invoice now?"
                v-model="formData.uploadInvoiceOption"
              ></v-select>
            </v-col>

            <!-- Client Invoice  -->
            <v-row
              class="pl-3"
              cols="12"
              v-if="uploadClientInvoice && uploadInvoiceNowOrLater"
            >
              <v-col class="d-flex" sm="9" md="9">
                <div
                  v-cloak
                  @drop.prevent="
                    addDropFile($event, 'autoGeneratedTaxpodInvoice')
                  "
                  @dragover.prevent
                  class="w-100"
                >
                  <v-file-input
                    :prepend-icon="null"
                    prepend-inner-icon="mdi-paperclip"
                    dense
                    filled
                    small-chips
                    multiple
                    label="Auto Generated Client Invoice"
                    v-model="formData.autoGeneratedTaxpodInvoice"
                    :error-messages="errorField.autoGeneratedTaxpodInvoice"
                    :truncate-length="1000"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col lg="3" sm="3" md="3" class="pl-0 pr-5">
                <v-btn
                  x-large
                  :color="HRDC_BLUE_THEME_COLOR"
                  outlined
                  @click="previewAttachment('autoGeneratedTaxpodInvoice')"
                  >Preview</v-btn
                >
              </v-col>
            </v-row>

            <!-- Generate CN Request -->
            <v-col
              class="d-flex"
              cols="12"
              sm="12"
              md="12"
              v-if="uploadClientInvoice"
            >
              <v-select
                :items="generateCnOption"
                filled
                dense
                label="Generate CN Request"
                v-model="formData.generateCnOption"
                :menu-props="{
                  bottom: true,
                  offsetY: true,
                }"
                @change="changeGenerateCnOption()"
              ></v-select>
            </v-col>

            <!-- Claim Approved Date -->
            <v-col cols="12" sm="12" md="12">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    filled
                    v-model="formData.claimApprovedDate"
                    label="Claim Approved Date (YYYY-MM-DD)"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.claimApprovedDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-card>
        </v-col>

        <v-col lg="6" md="12" sm="12" v-if="isSpecialCnApplied">
          <v-card
            class="pa-2 ma-2"
            :style="{ backgroundColor: HRDC_CARD_COLOR }"
            outlined
          >
            <v-list-item>
              Credit Note will be generate based on below data:
              <v-spacer></v-spacer>
              <v-btn
                depressed
                fab
                dark
                color="primary"
                @click="toggleGenerateSpecialCnModal(true)"
              >
                <v-icon dark> mdi-pencil </v-icon>
              </v-btn>
            </v-list-item>

            <v-list-item
              v-for="(item, key) of this.specialCnRowData"
              :key="key"
            >
              <v-list-item-content>
                <div class="text-decoration-underline font-weight-bold">
                  CN {{ key + 1 }}
                </div>
                <v-list-item-title
                  >Client Inv No.: {{ item["clientInvNo"] }}</v-list-item-title
                >
                <v-list-item-title
                  >HRDC Inv No.: {{ item["hrdcInvNo"] }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  RM {{ item["cnAmount"] }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  style="white-space: normal"
                  v-html="item['cnDesc']"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>

    <GenerateSpecialCn
      :generateSpecialCnModal="generateSpecialCnModal"
      :formData="formData"
      :errorField="errorField"
      @toggleGenerateSpecialCnModal="toggleGenerateSpecialCnModal"
      @saveGenerateSpecialCnInfoChild="saveGenerateSpecialCnInfo"
    ></GenerateSpecialCn>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_einvoicing/objects/globalData";
import GenerateSpecialCn from "@/components/views/GenerateSpecialCn";

export default {
  mixins: [hrdcFunction],
  components: {
    GenerateSpecialCn,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    uploadClientInvoice() {
      if (this.formData.paymentChannel == 1) {
        return true;
      }
      return false;
    },
    uploadInvoiceNowOrLater() {
      if (this.formData.uploadInvoiceOption) {
        return true;
      }

      return false;
    },
    isSpecialCnApplied() {
      return this.specialCnRowData.length > 0;
    },
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationSingleDetail: new Api(),
    apiSubmit: new Api(),
    apiGetApplicationDetails: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    formData: {
      applicationNo: null,
      claimId: null,
      eventDate: null,
      autoGeneratedTaxpodInvoice: [],
      paymentChannel: null,
      claimApprovedDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      uploadInvoiceOption: true,
      generateCnOption: "NORMAL_CN",
      specialCnPax: null,
      hrdcInvoiceNo: null,
    },
    errorField: {
      applicationNo: null,
      claimId: null,
      eventDate: null,
      autoGeneratedTaxpodInvoice: null,
      claimApprovedDate: null,
      specialCnPax: null,
      cnAmount: [],
      clientInvNo: [],
      cnDesc: [],
      hrdcInvNo: [],
    },
    loadingDialog: false,
    claimApprovedDialog: false,
    editMode: false,
    btnDisabled: false,
    uploadInvoiceOption: [
      {
        text: "Upload Now",
        value: true,
      },
      {
        text: "Upload Later",
        value: false,
      },
    ],
    generateSpecialCnModal: false,
    specialCnFields: {
      clientInvNo: "Client Inv No.",
      hrdcInvNo: "HRDC Inv No.",
      cnAmount: "CN Amount",
      cnDesc: "CN Description",
    },
    specialCnBool: false,
    specialCnRowData: [],
  }),
  watch: {
    "formData.specialCnPax"(newValue) {
      if (newValue > 3 || newValue <= 0) {
        this.$nextTick(() => {
          this.formData.specialCnPax = null;
        });
      }
    },
  },
  async created() {
    this.showLoadingDialog();
    this.formData.applicationNo = this.$route.query._ausk;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/claim_approved/update-claim-approved`,
        method: "post",
      },
      apiGetApplicationDetails: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-application-details`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
      apiGetApplicationSingleDetail: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-single-application`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
        },
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.apiGetApplicationDetails.setCallbackCompleted((response) => {
      try {
        if (response.status) {
          const { data } = response;
          this.formData.claimId = data.claimId;
          this.formData.paymentChannel = data.paymentChannel;

          const { Event } = data;

          let eventStartDate = moment(Event.startDate).format("YYYY-MM-DD");
          let eventEndDate = moment(Event.endDate).format("YYYY-MM-DD");
          let eventPeriod = null;
          if (eventStartDate && eventEndDate) {
            eventPeriod = `${eventStartDate} - ${eventEndDate}`;
          }

          this.formData.eventDate = eventPeriod;
          this.formData.hrdcInvoiceNo = data.manualInvoiceToHrdc;
        }

        if (this.editMode) {
          this.apiGetApplicationSingleDetail.setCallbackCompleted(
            (response) => {
              try {
                const { status, data } = response;
                if (status) {
                  const claimApprovedStageId =
                    this.hrdcData.stagePriority.claimApprovedFromHrdc;
                  this.formData.claimApprovedDate = moment(
                    data.ApplicationLog.find(
                      (obj) => obj.stageId == claimApprovedStageId
                    ).startDate
                  ).format("YYYY-MM-DD");

                  for (const item of data.ApplicationAttachment) {
                    // Get the extension of file.
                    const parts = item.fileUrl.split(".");
                    const extension = parts[parts.length - 1];

                    // Create a Blob object from the data
                    const blob = new Blob([new Uint8Array(item.dataArray)]);

                    let file = null;

                    switch (item.type) {
                      case "autoGeneratedTaxpodInvoice":
                        // Create the File object
                        file = new File([blob], item.fileName, {
                          type: `application/${extension}`,
                        });

                        this.formData.autoGeneratedTaxpodInvoice = file;
                        break;
                    }
                  }
                }

                this.hideLoadingDialog();
              } catch (err) {
                console.log(err);
              }
            }
          );
          this.apiGetApplicationSingleDetail.fetch();
        } else {
          this.hideLoadingDialog();
        }
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetApplicationDetails.fetch();

    this.stages = await this.hrdcData.allStages;
    const currentStageId =
      this.hrdcData.stageIndex[
        this.$store.state[this.service.key].data.stageId
      ];
    this.rolesAssignation(this.auth.roles);
    if (!this.isHrdcAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.checkFormAlreadyFilledGreater(
      currentStageId,
      this.hrdcData.stageSlug.submittedClaimToHrdc
    );

    this.checkFormNotYetFilledLesser(
      currentStageId,
      this.hrdcData.stageSlug.submittedClaimToHrdc
    );
  },
  methods: {
    addDropFile(e, key) {
      for (const file of e.dataTransfer.files) {
        if (!file.type.match("pdf.*")) {
          this.errorField[key] = "Please select pdf file format";
          return;
        }

        this.formData[key].push(file);
      }
    },
    clearErrorMsg() {
      for (let error in this.errorField) {
        if (Array.isArray(this.errorField[error])) {
          this.errorField[error] = [];
        } else {
          this.errorField[error] = null;
        }
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    modalClaimApprove() {
      this.claimApprovedDialog = !this.claimApprovedDialog;
      this.submit();
    },
    redirectBack() {
      let route = "HrdcEinvoicingApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state[this.service.key].data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    previewAttachment(fileType) {
      if (this.formData[fileType].length == 0) {
        this.$store.dispatch("showMessage", {
          message: "No Preview Available",
          messageType: "info",
        });
        return;
      }

      let url = null;
      let newWindow = null;
      for (const file of this.formData[fileType]) {
        switch (fileType) {
          default:
            url = URL.createObjectURL(file);
            newWindow = window.open(url, "_blank");
            break;
        }

        if (newWindow) {
          newWindow.onload = function () {
            // Revoke the object URL when the window is closed
            newWindow.addEventListener("beforeunload", function () {
              URL.revokeObjectURL(url);
            });
          };
        }
      }
    },
    changeGenerateCnOption() {
      if (this.formData.generateCnOption == "SPECIAL_CN") {
        this.toggleGenerateSpecialCnModal(true);
      } else {
        this.specialCnRowData = [];
      }
    },
    toggleGenerateSpecialCnModal(val) {
      this.generateSpecialCnModal = val;

      // Remove all Special CN Data if discarded.
      if (!val) {
        this.formData.specialCnPax = null;
        for (const item in this.specialCnFields) {
          this.formData[item] = {};
        }

        this.formData.generateCnOption = "NORMAL_CN";
        this.specialCnRowData = [];
      }
    },
    saveGenerateSpecialCnInfo(specialCnData) {
      // Check for CN pax.
      if (!specialCnData.specialCnPax.lazyValue) {
        this.$set(this.errorField, "specialCnPax", "CN Pax is required");
        return false;
      }

      // Reset CN Pax error message.
      this.$set(this.errorField, "specialCnPax", null);

      const cnAmount = {};
      const clientInvNo = {};
      const hrdcInvNo = {};
      const cnDesc = {};

      let isCnError = false;
      for (const item in this.specialCnFields) {
        for (let i = 0; i < this.formData.specialCnPax; i++) {
          this.$set(this.errorField[item], i, []);
          const cnData = specialCnData[item][i].lazyValue;
          if (!cnData) {
            this.$set(
              this.errorField[item],
              i,
              `${this.specialCnFields[item]} is required`
            );
            isCnError = true;
          }

          // Store special CN data into Form Data.
          cnAmount[i] = specialCnData["cnAmount"][i].lazyValue;
          clientInvNo[i] = specialCnData["clientInvNo"][i].lazyValue;
          hrdcInvNo[i] = specialCnData["hrdcInvNo"][i].lazyValue;
          cnDesc[i] = specialCnData["cnDesc"][i].lazyValue;
        }
      }

      if (isCnError) {
        return false;
      }

      this.specialCnRowData = [];

      for (let i = 0; i < this.formData.specialCnPax; i++) {
        this.specialCnRowData.push({
          clientInvNo: clientInvNo[i],
          hrdcInvNo: hrdcInvNo[i],
          cnAmount: cnAmount[i],
          cnDesc: cnDesc[i],
        });
      }

      // Close modal after saved.
      this.generateSpecialCnModal = false;
    },
    async submit() {
      this.showLoadingDialog();

      const fd = new FormData();
      fd.append("applicationUuid", this.formData.applicationNo);
      fd.append("claimApprovedDate", this.formData.claimApprovedDate);

      let fileCounter = 0;
      for (
        let i = 0;
        i < this.formData.autoGeneratedTaxpodInvoice.length;
        i++
      ) {
        fileCounter++;
        fd.append(
          `autoGeneratedTaxpodInvoice_${i}`,
          this.formData.autoGeneratedTaxpodInvoice[i]
        );
      }

      fd.append("paymentChannel", this.formData.paymentChannel);
      fd.append("referById", this.auth.uuid);
      fd.append("editMode", this.editMode);
      fd.append("uploadInvoiceOption", this.formData.uploadInvoiceOption);
      fd.append("fileCounter", fileCounter);
      fd.append("generateCnOption", this.formData.generateCnOption);
      fd.append("specialCnRowData", JSON.stringify(this.specialCnRowData));

      this.apiSubmit.setParams(fd);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.hideLoadingDialog();
          if (!response.status) {
            this.showErrorMessage(response);
            this.$store.dispatch("showMessage", {
              message:
                response.message ?? "Please fill-in all the required fields",
              messageType: "error",
              timeout: 2000,
            });
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcEinvoicingApplication",
              query: {
                viewId: this.$store.state[this.service.key].data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>
