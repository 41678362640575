<template>
  <v-container fluid fill-height class="form-background justify-center">
    <v-row class="justify-center align-self-start">
      <v-col cols="12" lg="6">
        <div>
          <!-- Header -->
          <v-toolbar
            :color="formColor"
            dark
            prominent
            extended
            extension-height="150"
          >
            <v-btn
              icon
              class="hidden-xs-only text-white"
              @click="redirectBack()"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>

            <div class="pa-5 d-flex flex-column align-start">
              <img
                src="@public/images/assets/yyc-50-logo.png"
                height="80px"
                alt="yyc-50-logo"
              />

              <div class="text-h4 font-weight-bold pt-8">
                HRDC Application Form
              </div>
              <div class="font-italic mt-8">
                Thank you for registering with our event! To proceed with your
                HRDC application, please fill out the provided form.
              </div>
            </div>
          </v-toolbar>

          <!-- Content Page 1 -->
          <v-card tile elevation="0" v-if="currentFormPage == 1">
            <div class="px-12 py-5 form-content-background">
              <v-row class="form-font">
                <v-col cols="12">
                  <div><span class="text-danger">*</span> Required</div>
                </v-col>
                <v-col cols="12">
                  <div>
                    <span
                      class="text-h6 font-weight-bold"
                      :style="{ color: formColor }"
                      >HRDC Account Details</span
                    >
                    <br />
                    <span class="text-caption"
                      >All of the information below will only be used for HRDC
                      application purpose.</span
                    >
                  </div>
                </v-col>

                <!-- Company Name -->
                <v-col cols="12">
                  <div>
                    <span> 1. Company Name </span>
                    <span class="font-weight-bold font-italic"
                      >(As Per SSM)</span
                    >
                    <span class="text-danger"> *</span><br />
                    <span
                      class="text-caption font-italic font-weight-bold p-17"
                      :style="{ color: exampleColor }"
                      >Example: XXX SDN. BHD.</span
                    >
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0" style="padding-left: 30px">
                  <div>
                    <v-text-field
                      v-model="formData.companyName"
                      :error-messages="errorField.companyName"
                      background-color="white"
                      :color="formColor"
                      class="pa-0 custom-form-input"
                      placeholder="Enter your answer"
                      style="border-radius: 5px"
                      @input="onInputChange('companyName')"
                    ></v-text-field>
                  </div>
                </v-col>

                <!-- Company SSM Number -->
                <v-col cols="12">
                  <div>
                    <span>
                      2. Company <span><b>NEW</b></span> SSM Number
                    </span>
                    <span class="text-danger"> *</span><br />
                    <span
                      class="text-caption font-italic font-weight-bold p-17"
                      :style="{ color: exampleColor }"
                      >Example: 199701020680, Symbols is not allowed</span
                    >
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0" style="padding-left: 30px">
                  <div>
                    <v-text-field
                      ref="companySsmNo"
                      v-model="formData.companySsmNo"
                      :error-messages="errorField.companySsmNo"
                      background-color="white"
                      :color="formColor"
                      class="pa-0 custom-form-input"
                      placeholder="Enter your answer"
                      style="border-radius: 5px"
                      type="number"
                      @input="onInputChange('companySsmNo')"
                    ></v-text-field>
                  </div>
                </v-col>

                <!-- Company Address -->
                <v-col cols="12">
                  <div>
                    <span> 3. Company Billing Address </span>
                    <span class="text-danger"> *</span><br />
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0" style="padding-left: 30px">
                  <div>
                    <v-text-field
                      v-model="formData.companyAddress"
                      :error-messages="errorField.companyAddress"
                      background-color="white"
                      :color="formColor"
                      class="pa-0 custom-form-input"
                      placeholder="Enter your answer"
                      style="border-radius: 5px"
                      @input="onInputChange('companyAddress')"
                    ></v-text-field>
                  </div>
                </v-col>

                <!-- HRDC PIC Name -->
                <v-col cols="12">
                  <div>
                    <span> 4. HRDC PIC Name </span>
                    <span class="text-danger"> *</span><br />
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0" style="padding-left: 30px">
                  <div>
                    <v-text-field
                      v-model="formData.picName"
                      :error-messages="errorField.picName"
                      background-color="white"
                      :color="formColor"
                      class="pa-0 custom-form-input"
                      placeholder="Enter your answer"
                      style="border-radius: 5px"
                      @input="onInputChange('picName')"
                    ></v-text-field>
                  </div>
                </v-col>

                <!-- HRDC PIC Email Address -->
                <v-col cols="12">
                  <div>
                    <span> 5. HRDC PIC Email Address </span>
                    <span class="text-danger"> *</span><br />
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0" style="padding-left: 30px">
                  <div>
                    <v-text-field
                      v-model="formData.picEmail"
                      :error-messages="errorField.picEmail"
                      background-color="white"
                      :color="formColor"
                      class="pa-0 custom-form-input"
                      placeholder="Enter your answer"
                      style="border-radius: 5px"
                      @input="onInputChange('picEmail')"
                    ></v-text-field>
                  </div>
                </v-col>

                <!-- HRDC PIC Contact Number -->
                <v-col cols="12">
                  <div>
                    <span> 6. HRDC PIC Contact Number </span>
                    <span class="text-danger"> *</span><br />
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0" style="padding-left: 30px">
                  <div>
                    <v-text-field
                      ref="picContactNo"
                      v-model="formData.picContactNo"
                      :error-messages="errorField.picContactNo"
                      background-color="white"
                      :color="formColor"
                      class="pa-0 custom-form-input"
                      placeholder="Enter your answer"
                      style="border-radius: 5px"
                      type="number"
                      @input="onInputChange('picContactNo')"
                    ></v-text-field>
                  </div>
                </v-col>

                <v-col cols="12" class="d-flex justify-space-between">
                  <v-btn
                    :color="formColor"
                    large
                    class="text-white"
                    elevation="0"
                    @click="changeFormPage(2, true)"
                  >
                    Next
                  </v-btn>
                  <div class="progress-bar px-10" :style="{ color: formColor }">
                    Page {{ currentFormPage }} of {{ totalFormPage }} &nbsp;
                    <v-progress-linear
                      :value="currentFormProgress"
                      :color="formColor"
                    ></v-progress-linear>
                  </div>
                </v-col>

                <v-col cols="12" class="pt-0">
                  <span class="text-caption">
                    Never give out your password.
                  </span>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <!-- Content Page 2 -->
          <v-card elevation="0" tile v-if="currentFormPage == 2">
            <div class="px-12 py-5 form-content-background">
              <v-row class="form-font">
                <v-col cols="12">
                  <div><span class="text-danger">*</span> Required</div>
                </v-col>
                <v-col cols="12">
                  <div>
                    <span
                      class="text-h6 font-weight-bold"
                      :style="{ color: formColor }"
                      >Claim Details</span
                    >
                  </div>
                </v-col>

                <!-- Workshop / Event Session -->
                <v-col cols="12">
                  <div>
                    <span> 7. Workshop / Event Session </span>
                    <span class="text-danger"> *</span><br />
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0" style="padding-left: 30px">
                  <div>
                    <v-select
                      :items="eventItems"
                      v-model="formData.events"
                      :error-messages="errorField.events"
                      background-color="white"
                      :color="formColor"
                      class="pa-0 custom-form-input"
                      placeholder="Enter your answer"
                      style="border-radius: 5px"
                      :loading="apiGetEvents.isLoading"
                      @input="onInputChange('events')"
                      :menu-props="{
                        bottom: true,
                        offsetY: true,
                      }"
                      @change="changeEventDetails()"
                    ></v-select>
                  </div>
                </v-col>

                <!-- Other attachments -->
                <v-col cols="12">
                  <div>
                    <span> 8. Other Attachment (if any) </span><br />
                    <span
                      class="text-caption font-italic font-weight-bold p-17"
                      :style="{ color: exampleColor }"
                      >Please rename your file name before upload.</span
                    >
                  </div>
                </v-col>
                <v-col
                  lg="6"
                  md="12"
                  sm="12"
                  class="pt-0"
                  style="padding-left: 30px"
                >
                  <div>
                    <v-file-input
                      v-model="formData.otherAttachment"
                      :error-messages="errorField.otherAttachment"
                      background-color="white"
                      :color="formColor"
                      class="pa-0 custom-form-input file-input"
                      placeholder="Upload attachment"
                      style="border-radius: 5px"
                      prepend-icon=""
                      prepend-inner-icon="mdi-upload"
                      @input="onInputChange('otherAttachment')"
                      accept="image/*, application/pdf"
                    ></v-file-input>
                  </div>
                </v-col>

                <!-- Number of Pax -->
                <v-col cols="12">
                  <div>
                    <span> 9. Number of Pax </span>
                    <span class="text-danger"> *</span><br />
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0" style="padding-left: 30px">
                  <div>
                    <v-text-field
                      v-model="formData.noOfPax"
                      :error-messages="errorField.noOfPax"
                      background-color="white"
                      :color="formColor"
                      class="pa-0 custom-form-input"
                      placeholder="The value must be a number"
                      style="border-radius: 5px"
                      type="number"
                      @input="onInputChange('noOfPax')"
                    ></v-text-field>
                  </div>
                </v-col>

                <div
                  v-for="(pax, index) in Array.from(
                    { length: Number(formData.noOfPax) },
                    (_, i) => i + 1
                  )"
                  :key="index"
                >
                  <v-col cols="12">
                    <div>
                      <span> Participant {{ pax }} Full Name (As Per IC) </span>
                      <span class="text-danger"> *</span><br />
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-0" style="padding-left: 30px">
                    <div>
                      <v-text-field
                        ref="participantName"
                        :error-messages="errorField.participantName[index]"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input"
                        placeholder="Enter your answer"
                        style="border-radius: 5px"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <span> Participant {{ pax }} IC Number </span>
                      <span class="text-danger"> *</span><br />
                      <span
                        class="text-caption font-italic font-weight-bold"
                        :style="{ color: exampleColor }"
                        >Only number without any symbol. (etc.
                        990101881234)</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-0" style="padding-left: 30px">
                    <div>
                      <v-text-field
                        ref="participantNric"
                        :error-messages="errorField.participantNric[index]"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input"
                        placeholder="Enter your answer"
                        style="border-radius: 5px"
                        type="number"
                        @input="onInputDigits(index, 'participantNric')"
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <span> Participant {{ pax }} Email </span>
                      <span class="text-danger"> *</span><br />
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-0" style="padding-left: 30px">
                    <div>
                      <v-text-field
                        ref="participantEmail"
                        :error-messages="errorField.participantEmail[index]"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input"
                        placeholder="Enter your answer"
                        style="border-radius: 5px"
                      ></v-text-field>
                    </div>
                  </v-col>

                  <hr />
                </div>

                <!-- Is Payment Made -->
                <v-col cols="12">
                  <div>
                    <span> 10. Payment Made </span>
                    <span class="text-danger"> *</span><br />
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0" style="padding-left: 30px">
                  <div>
                    <v-select
                      :items="isPaymentMadeItems"
                      v-model="formData.isPaymentMade"
                      :error-messages="errorField.isPaymentMade"
                      background-color="white"
                      :color="formColor"
                      class="pa-0 custom-form-input"
                      placeholder="Enter your answer"
                      style="border-radius: 5px"
                      @input="onInputChange('isPaymentMade')"
                      item-text="label"
                      :menu-props="{
                        bottom: true,
                        offsetY: true,
                      }"
                    >
                      <template v-slot:item="data">
                        <div v-html="data.item.text"></div>
                      </template>
                    </v-select>
                  </div>
                </v-col>

                <!-- No Payment Made Section -->
                <div class="pa-0" v-if="formData.isPaymentMade == false">
                  <v-col cols="12">
                    <div>
                      <span> 11. Claim Amount (RM) </span>
                      <span class="text-danger"> *</span><br />
                      <span
                        class="text-caption font-italic font-weight-bold p-17"
                        :style="{ color: exampleColor }"
                        >Subject to your HRDC account balance.</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-0" style="padding-left: 30px">
                    <div>
                      <v-text-field
                        type="number"
                        v-model="formData.paymentNoMadeClaimAmount"
                        :error-messages="errorField.paymentNoMadeClaimAmount"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input"
                        placeholder="The value must be a number"
                        style="border-radius: 5px"
                        @input="onInputChange('paymentNoMadeClaimAmount')"
                      ></v-text-field>
                    </div>
                  </v-col>
                </div>

                <!-- Payment Made Section -->
                <!-- Bank Account Holder Name -->
                <div class="pa-0" v-if="formData.isPaymentMade == true">
                  <v-col cols="12">
                    <div>
                      <span> 11. Bank Account Holder Name </span>
                      <span class="text-danger"> *</span><br />
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-0" style="padding-left: 30px">
                    <div>
                      <v-text-field
                        v-model="formData.bankAccHolderName"
                        :error-messages="errorField.bankAccHolderName"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input"
                        placeholder="Enter your answer"
                        style="border-radius: 5px"
                        @input="onInputChange('bankAccHolderName')"
                      ></v-text-field>
                    </div>
                  </v-col>

                  <!-- Name of Bank -->
                  <v-col cols="12">
                    <div>
                      <span> 12. Name of Bank </span>
                      <span class="font-weight-bold font-italic"
                        >(for HRDC refund purpose)</span
                      >
                      <span class="text-danger"> *</span>
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-0" style="padding-left: 30px">
                    <div>
                      <v-autocomplete
                        :items="bankItems"
                        v-model="formData.bank"
                        :error-messages="errorField.bank"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input"
                        placeholder="Select your answer"
                        style="border-radius: 5px"
                        :loading="apiGetBanks.isLoading"
                        @input="onInputChange('bank')"
                        @change="onSelectChange('bank')"
                        :menu-props="{
                          bottom: true,
                          offsetY: true,
                        }"
                      ></v-autocomplete>
                      <v-text-field
                        v-if="viewOtherBank"
                        v-model="formData.otherBank"
                        :error-messages="errorField.otherBank"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input"
                        placeholder="Other"
                        style="border-radius: 5px"
                        @input="onInputChange('otherBank')"
                      >
                      </v-text-field>
                    </div>
                  </v-col>

                  <!-- Bank Account Number -->
                  <v-col cols="12">
                    <div>
                      <span> 13. Bank Account Number </span>
                      <span class="font-weight-bold font-italic"
                        >(for HRDC refund purpose)</span
                      >
                      <span class="text-danger"> *</span><br />
                      <span
                        class="text-caption font-italic font-weight-bold p-17"
                        :style="{ color: exampleColor }"
                        >Only number without any symbol.</span
                      >
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-0" style="padding-left: 30px">
                    <div>
                      <v-text-field
                        ref="bankAccNo"
                        v-model="formData.bankAccNo"
                        :error-messages="errorField.bankAccNo"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input"
                        placeholder="Enter your answer"
                        style="border-radius: 5px"
                        type="number"
                        @input="onInputChange('bankAccNo')"
                      ></v-text-field>
                    </div>
                  </v-col>

                  <!-- Screenshot of Refund Bank Account Details -->
                  <v-col cols="12">
                    <div>
                      <span>
                        14. Bank Statement Header / Screenshot of Bank Account
                        Details for Refund
                      </span>
                      <span class="text-danger"> *</span>
                    </div>
                  </v-col>
                  <v-col
                    lg="6"
                    md="12"
                    sm="12"
                    class="pt-0"
                    style="padding-left: 30px"
                  >
                    <div>
                      <v-file-input
                        v-model="formData.ssRefundBankAccAtch"
                        :error-messages="errorField.ssRefundBankAccAtch"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input file-input"
                        placeholder="Upload file"
                        style="border-radius: 5px"
                        prepend-icon=""
                        prepend-inner-icon="mdi-upload"
                        @input="onInputChange('ssRefundBankAccAtch')"
                      ></v-file-input>
                    </div>
                  </v-col>

                  <!-- Payment Method -->
                  <v-col cols="12">
                    <div>
                      <span> 15. Payment Method </span>
                      <span class="text-danger"> *</span><br />
                      <div
                        class="text-caption p-27"
                        :style="{ color: exampleColor }"
                      >
                        <span class="font-weight-bold">Bank Transfer:</span
                        ><br />
                        <span
                          >Company Name: YYC GST CONSULTANTS SDN BHD -
                          201301043188 (1073010-M)
                        </span>
                        <br />
                        <span>Bank Name: CIMB Bank</span> <br />
                        <span>Bank Account Number: 8603863136</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-0" style="padding-left: 30px">
                    <div>
                      <v-select
                        :items="paymentMethodItems"
                        v-model="formData.paymentMethod"
                        :error-messages="errorField.paymentMethod"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input"
                        placeholder="Select your answer"
                        style="border-radius: 5px"
                        @input="onInputChange('paymentMethod')"
                        @change="onSelectChange('paymentMethod')"
                        :menu-props="{
                          bottom: true,
                          offsetY: true,
                        }"
                      ></v-select>
                      <v-text-field
                        v-if="viewOtherPaymentMethod"
                        v-model="formData.otherPaymentMethod"
                        :error-messages="errorField.otherPaymentMethod"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input"
                        placeholder="Other"
                        style="border-radius: 5px"
                        @input="onInputChange('otherPaymentMethod')"
                      >
                      </v-text-field>
                    </div>
                  </v-col>

                  <!-- Payment Amount -->
                  <v-col cols="12">
                    <div>
                      <span> 16. Payment Amount (RM) </span>
                      <span class="text-danger"> *</span><br />
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-0" style="padding-left: 30px">
                    <div>
                      <v-text-field
                        v-model="formData.paymentAmount"
                        :error-messages="errorField.paymentAmount"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input"
                        placeholder="The value must be a number"
                        style="border-radius: 5px"
                        type="number"
                        @input="onInputChange('paymentAmount')"
                      ></v-text-field>
                    </div>
                  </v-col>

                  <!-- Claim Amount -->
                  <v-col cols="12">
                    <div>
                      <span> 17. Claim Amount (RM) </span>
                      <span class="text-danger"> *</span><br />
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-0" style="padding-left: 30px">
                    <div>
                      <v-text-field
                        v-model="formData.paymentMadeClaimAmount"
                        :error-messages="errorField.paymentMadeClaimAmount"
                        background-color="white"
                        :color="formColor"
                        class="pa-0 custom-form-input"
                        placeholder="The value must be a number"
                        style="border-radius: 5px"
                        type="number"
                        @input="onInputChange('paymentMadeClaimAmount')"
                      ></v-text-field>
                    </div>
                  </v-col>
                </div>

                <v-col cols="12" class="d-flex">
                  <v-btn
                    large
                    color="white"
                    elevation="0"
                    @click="changeFormPage(1)"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    :color="formColor"
                    large
                    class="text-white ml-6"
                    elevation="0"
                    @click="changeFormPage('submit', true)"
                  >
                    Submit
                  </v-btn>
                  <div
                    class="progress-bar px-10 ml-auto"
                    :style="{ color: formColor }"
                  >
                    Page {{ currentFormPage }} of {{ totalFormPage }} &nbsp;
                    <v-progress-linear
                      :value="currentFormProgress"
                      :color="formColor"
                    ></v-progress-linear>
                  </div>
                </v-col>

                <v-col cols="12" class="pt-0">
                  <span class="text-caption">
                    Never give out your password.
                  </span>
                </v-col>
              </v-row>
            </div>
          </v-card>

          <!-- Footer -->
          <div class="form-footer pa-10 text-caption">
            This content is created by the owner of the form. The data you
            submit will be sent to the form owner. YYC taxPOD is not responsible
            for the privacy or security practices of its customers, including
            those of this form owner. Never give out your password. <br />
            The owner of this form has not provided a privacy statement as to
            how they will use your response data. Do not provide personal or
            sensitive information.
          </div>
        </div>
      </v-col>
    </v-row>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tpdiy/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
      hrdc_tpdiy: (state) => state.hrdc_tpdiy.data,
    }),
    currentFormProgress() {
      return (this.currentFormPage / this.totalFormPage) * 100;
    },
  },
  props: ["params"],
  data: () => ({
    apiGetEvents: new Api(),
    apiGetBanks: new Api(),
    apiSubmit: new Api(),
    hrdcData: new HrdcData(),
    service: new Service(),
    model: new Model(),
    formColor: "#135875",
    exampleColor: "#616161",
    currentFormPage: 1,
    totalFormPage: 2,
    loadingDialog: false,
    eventItems: [],
    bankItems: [],
    isPaymentMadeItems: [],
    page1: {
      companyName: null,
      companySsmNo: null,
      companyAddress: null,
      picName: null,
      picEmail: null,
      picContactNo: null,
    },
    page2: {
      events: null,
      noOfPax: null,
      isPaymentMade: null,
      paymentNoMadeClaimAmount: null,
      bankAccHolderName: null,
      bank: null,
      otherBank: null,
      bankAccNo: null,
      ssRefundBankAccAtch: null,
      paymentMethod: null,
      otherPaymentMethod: null,
      paymentAmount: null,
      paymentMadeClaimAmount: null,
      otherAttachment: null,
    },
    page2PaymentMadeItem: {
      events: null,
      noOfPax: null,
      isPaymentMade: null,
      bankAccHolderName: null,
      bank: null,
      otherBank: null,
      bankAccNo: null,
      ssRefundBankAccAtch: null,
      paymentMethod: null,
      otherPaymentMethod: null,
      paymentAmount: null,
      paymentMadeClaimAmount: null,
      otherAttachment: null,
    },
    page2PaymentNoMadeItem: {
      events: null,
      noOfPax: null,
      isPaymentMade: null,
      paymentNoMadeClaimAmount: null,
      otherAttachment: null,
    },
    formData: {
      companyName: null,
      companySsmNo: null,
      companyAddress: null,
      picName: null,
      picEmail: null,
      picContactNo: null,
      events: null,
      noOfPax: null,
      isPaymentMade: null,
      paymentNoMadeClaimAmount: null,
      bankAccHolderName: null,
      bank: null,
      otherBank: null,
      bankAccNo: null,
      ssRefundBankAccAtch: null,
      paymentMethod: null,
      otherPaymentMethod: null,
      paymentAmount: null,
      paymentMadeClaimAmount: null,
      otherAttachment: null,
    },
    errorField: {
      companyName: null,
      companySsmNo: null,
      companyAddress: null,
      picName: null,
      picEmail: null,
      picContactNo: null,
      events: null,
      noOfPax: null,
      participantName: [],
      participantNric: [],
      participantEmail: [],
      isPaymentMade: null,
      paymentNoMadeClaimAmount: null,
      bankAccHolderName: null,
      bank: null,
      otherBank: null,
      bankAccNo: null,
      ssRefundBankAccAtch: null,
      paymentMethod: null,
      otherPaymentMethod: null,
      paymentAmount: null,
      otherAttachment: null,
    },
    isSubmitted: {
      page1: false,
      page2: false,
    },
    containsOtherOptions: {
      bank: "otherBank",
      paymentMethod: "otherPaymentMethod",
    },
    viewOtherPaymentMethod: false,
    viewOtherBank: false,
    fieldsNotRequiredValidate: ["otherAttachment"],
  }),
  watch: {
    "formData.noOfPax"(newValue) {
      if (newValue > 9 || newValue <= 0) {
        this.$nextTick(() => {
          this.formData.noOfPax = null;
        });
      }
    },
  },
  created() {
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) {
      this.service.set("key", this.model.serviceKey);
    }

    // Events Assignation.
    this.serviceDataAssignation(this.model.serviceKey);

    const apiDetails = {
      apiGetEvents: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/event/get-events?active=true&event_type=${
          this.hrdcType
        }`,
        method: "get",
      },
      apiGetBanks: {
        url: `${this.$service[this.service.key]}/v1/en/console/bank/get-banks`,
        method: "get",
      },
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/create-application`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.apiGetEvents.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (!status || data.length == 0) {
          this.errorField.events =
            "Unavailable to retrieve events, please contact admin for support";
        }

        if (status) {
          for (const item of data) {
            this.eventItems.push({
              text: this.formatEventTitle(item, this.hrdcType),
              value: JSON.stringify({
                uuid: item.uuid,
                title: item.title,
                eventCategory: item.eventCategory,
                isFullPaymentMadeAllow: item.isFullPaymentMadeAllow,
                isNoPaymentMadeAllow: item.isNoPaymentMadeAllow,
              }),
            });
          }
        }
      } catch (err) {
        console.log(err);
        this.errorField.events =
          "Unavailable to retrieve events, please contact admin for support";
      }
    });
    this.apiGetEvents.fetch();

    this.apiGetBanks.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;
        if (!status || data.length == 0) {
          this.errorField.bank =
            "Unavailable to retrieve banks, please contact admin for support";
        }

        if (status) {
          for (const item of data) {
            const [, ...bankNamePart] = item.bankType.split("-");
            const bankName = bankNamePart.join("-").trim();

            this.bankItems.push({
              text: bankName,
              value: bankName,
            });
          }

          // Push 'Others' option.
          this.bankItems.push({
            text: "Other",
            value: "Other",
          });
        }
      } catch (err) {
        console.log(err);
        this.errorField.bank =
          "Unavailable to retrieve banks, please contact admin for support";
      }
    });
    this.apiGetBanks.fetch();
  },
  methods: {
    redirectBack() {
      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: "HrdcTpdiyApplication",
        params: {
          modelKey: this.model.key,
        },
        query: {
          viewId: "all",
          _ausk: this.$route.query._ausk,
        },
      });
    },
    changeFormPage(pageNo, toNextPage = false) {
      // Check is to next page or back.
      let pass = false;
      let page2ItemToValidate = null;
      if (toNextPage) {
        // Mark the page as submitted.
        switch (this.currentFormPage) {
          case 1:
            this.isSubmitted.page1 = true;
            pass = this.validateData(this.page1);
            break;
          case 2:
            this.isSubmitted.page2 = true;

            // Check for No. Of Pax.
            if (this.formData.noOfPax) {
              const participantValidateFields = [
                "participantName",
                "participantNric",
                "participantEmail",
              ];
              for (const field of participantValidateFields) {
                this.validateParticipantDetails(field);
              }
            }

            // If payment made.
            if (this.formData.isPaymentMade) {
              page2ItemToValidate = this.page2PaymentMadeItem;

              const othersValidation = {
                viewOtherBank: "otherBank",
                viewOtherPaymentMethod: "otherPaymentMethod",
              };

              for (const otherItem in othersValidation) {
                if (!this[otherItem]) {
                  delete page2ItemToValidate[othersValidation[otherItem]];
                } else {
                  page2ItemToValidate[othersValidation[otherItem]] =
                    this.page2PaymentMadeItem[othersValidation[otherItem]];
                }
              }
            }

            if (!this.formData.isPaymentMade) {
              page2ItemToValidate = this.page2PaymentNoMadeItem;
            }

            pass = this.validateData(page2ItemToValidate);
            break;
        }
      }

      if (pass || !toNextPage) {
        // Clear error messages.
        this.clearErrorMsg(this[`page${this.currentFormPage}`]);

        if (pageNo == "submit") {
          this.submit();
          return;
        }

        this.currentFormPage = pageNo;
      }
    },
    validateParticipantDetails(field) {
      this.errorField[field] = [];
      for (let i = 0; i < this.formData.noOfPax; i++) {
        if (
          this.$refs[field][i].lazyValue == null ||
          this.$refs[field][i].lazyValue == "" ||
          this.$refs[field][i].lazyValue == undefined
        ) {
          this.errorField[field][i] = "This field is required";
        } else {
          this.errorField[field][i] = null;
        }
      }
    },
    validateData(items) {
      let pass = true;
      for (const item in items) {
        // If items is not required checking, pass it.
        if (this.fieldsNotRequiredValidate.includes(item)) {
          continue;
        }
        
        if (this.formData[item] === null || this.formData[item] === "") {
          this.errorField[item] = "This question is required.";
          pass = false;
        }
      }
      return pass;
    },
    onInputChange(item) {
      // Only check when user clicked to next page.
      let pageToCheck = null;
      const pages = [this.page1, this.page2];
      pages.forEach((page, index) => {
        if (item in page) {
          pageToCheck = index + 1;
        }
      });

      if (this.isSubmitted[`page${pageToCheck}`]) {
        if (this.formData[item] === null || this.formData[item] === "") {
          this.errorField[item] = "The question is required";
          return false;
        }

        this.errorField[item] = null;
      }

      // Check for only digits inputs.
      const onlyDigitFields = ["bankAccNo", "picContactNo", "companySsmNo"];
      if (onlyDigitFields.includes(item)) {
        this.onInputDigits(null, item);
      }
    },
    onInputDigits(index = null, field) {
      let input = null;
      if (index || index == 0) {
        input = this.$refs[field][index];
      } else {
        input = this.$refs[field];
      }
      input.$el.querySelector("input").value = input.$el
        .querySelector("input")
        .value.replace(/[^0-9]/g, "");
    },
    onSelectChange(item) {
      const viewMap = {
        bank: "viewOtherBank",
        paymentMethod: "viewOtherPaymentMethod",
      };

      if (this.formData[item] == "Other") {
        if (viewMap[item]) {
          this[viewMap[item]] = true;
        }
      } else {
        this[viewMap[item]] = false;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    clearErrorMsg(errors) {
      for (let error in errors) {
        this.errorField[error] = null;
      }
    },
    changeEventDetails() {
      this.isPaymentMadeItems = [];

      const eventObj = JSON.parse(this.formData.events);
      const { isFullPaymentMadeAllow, isNoPaymentMadeAllow } = eventObj;

      if (isFullPaymentMadeAllow) {
        this.isPaymentMadeItems.push({
          label: "Full Payment",
          text: "Full Payment",
          value: true,
        });
      }

      if (isNoPaymentMadeAllow) {
        this.isPaymentMadeItems.push({
          label: "No Payment",
          text: "No Payment <span style='color: red;'>(Please attach approval proof in Other Attachment before submitting application.)</span>",
          value: false,
        });
      }
    },
    submit() {
      this.showLoadingDialog();

      // Construct base data.
      const formData = new FormData();
      formData.append("userUuid", this.auth.uuid);
      formData.append("hrdcType", this.hrdcType);

      // Append event participants.
      const participantName = {};
      const participantNric = {};
      const participantEmail = {};
      for (let i = 0; i < this.formData.noOfPax; i++) {
        participantName[i] = this.$refs.participantName[i].lazyValue;
        participantNric[i] = this.$refs.participantNric[i].lazyValue;
        participantEmail[i] = this.$refs.participantEmail[i].lazyValue;
      }

      formData.append("participantName", JSON.stringify(participantName));
      formData.append("participantNric", JSON.stringify(participantNric));
      formData.append("participantEmail", JSON.stringify(participantEmail));

      for (const page1Item in this.page1) {
        formData.append(page1Item, this.formData[page1Item]);
      }

      // If payment made.
      if (this.formData.isPaymentMade) {
        for (const page2PaymentMadeItem in this.page2PaymentMadeItem) {
          // Check if data contains 'Others' option.
          if (
            this.formData[page2PaymentMadeItem] === "Other" &&
            page2PaymentMadeItem in this.containsOtherOptions
          ) {
            formData.append(
              page2PaymentMadeItem,
              this.formData[this.containsOtherOptions[page2PaymentMadeItem]]
            );

            continue;
          }

          formData.append(
            page2PaymentMadeItem,
            this.formData[page2PaymentMadeItem]
          );
        }
      }

      // If payment no made.
      if (!this.formData.isPaymentMade) {
        for (const page2PaymentNoMadeItem in this.page2PaymentNoMadeItem) {
          formData.append(
            page2PaymentNoMadeItem,
            this.formData[page2PaymentNoMadeItem]
          );
        }
      }

      this.apiSubmit.setParams(formData);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          if (!response.status) {
            this.showErrorMessage(response);
            if (response.message) {
              this.$store.dispatch("showMessage", {
                message: response.message,
                messageType: "error",
                timeout: 2000,
              });
            }

            this.hideLoadingDialog();
            return false;
          }

          if (response.status) {
            this.hideLoadingDialog();

            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "ViewHrdcTpdiyApplication",
              query: {
                viewId: this.$store.state[this.service.key].data.viewId,
                _ausk: response.application.uuid,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>

<style>
:root {
  --office-form-theme-primary-color-split: 19, 88, 117;
  --office-form-aio-renderer-background: linear-gradient(
      0deg,
      rgba(var(--office-form-theme-primary-color-split), 0.06),
      rgba(var(--office-form-theme-primary-color-split), 0.06)
    ),
    rgba(255, 255, 255, 0.9);
}

.body,
.v-application .text-h4,
.form-font {
  font-family: Segoe UI, Segoe WP, Tahoma, Arial, sans-serif !important;
}

.form-background {
  background-image: url("../../../../../public/images/assets/form-bg.jpg");
  background-size: cover;
  background-position: center;
}

.form-content-background {
  background: var(--office-form-aio-renderer-background);
  outline: transparent solid 0.0625rem;
}

.form-footer {
  background: rgba(255, 255, 255, 0.8);
}

.p-17 {
  padding-left: 17px !important;
}

.p-27 {
  padding-left: 27px !important;
}

.custom-form-input input,
.custom-form-input select {
  padding: 20px 17px;
}

.file-input .v-input__slot {
  padding: 5px;
}

.progress-bar {
  width: 24.375rem;
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background: rgba(3, 120, 124, 0.05);
  border-radius: 0.25rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.v-messages__message {
  color: #bc2f32 !important; /* Replace 'red' with any color you prefer */
}
</style>
