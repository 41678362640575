export default [
  {
    id: 1,
    uuid: "c3e1d771-087c-49e1-9a46-4322e085b53e",
    name: "AmBank Berhad / Ambank Bhd / Ambank (M) Berhad",
    abbreviation: "AMMB",
    timestampCreated: "2024-07-31T13:00:14.688Z",
    timestampUpdated: "2024-10-24T11:38:23.815Z",
    timestampDeleted: null,
    bankType: "08-AmBank Berhad",
  },
  {
    id: 2,
    uuid: "b53b3362-afa4-4e6a-889f-148fbec1bf19",
    name: "Alliance Bank Berhad",
    abbreviation: "ABMB, Alliance Islamic",
    timestampCreated: "2024-07-31T13:00:14.766Z",
    timestampUpdated: "2024-10-24T11:38:23.901Z",
    timestampDeleted: null,
    bankType: "12-Alliance Bank Berhad",
  },
  {
    id: 3,
    uuid: "4e4e333c-311a-4573-907c-3e2777be7d15",
    name: "Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad",
    abbreviation: "ARBC, ARB",
    timestampCreated: "2024-07-31T13:00:14.777Z",
    timestampUpdated: "2024-10-24T11:38:23.912Z",
    timestampDeleted: null,
    bankType:
      "53-Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad",
  },
  {
    id: 4,
    uuid: "ed9905c2-d9ef-40bd-92b7-36b768e5dfa0",
    name: "Affin Bank Berhad",
    abbreviation: "ABB",
    timestampCreated: "2024-07-31T13:00:14.786Z",
    timestampUpdated: "2024-10-24T11:38:23.925Z",
    timestampDeleted: null,
    bankType: "32-Affin Bank Berhad",
  },
  {
    id: 5,
    uuid: "93e65a16-4879-441f-bda6-6187778c819f",
    name: "Agro Bank",
    abbreviation: "AGRO",
    timestampCreated: "2024-07-31T13:00:14.796Z",
    timestampUpdated: "2024-10-24T11:38:23.936Z",
    timestampDeleted: null,
    bankType: "49-Agro Bank",
  },
  {
    id: 6,
    uuid: "e5b373cc-ddc9-4059-be19-7de20da3fc5f",
    name: "Bank of China (Malaysia) Berhad",
    abbreviation: "BOCM",
    timestampCreated: "2024-07-31T13:00:14.806Z",
    timestampUpdated: "2024-10-24T11:38:23.949Z",
    timestampDeleted: null,
    bankType: "42-Bank of China (Malaysia) Berhad",
  },
  {
    id: 7,
    uuid: "cefc3453-a893-46f2-8f36-5e4dea3458a5",
    name: "Bank Muamalat Malaysia Berhad",
    abbreviation: "BMMB",
    timestampCreated: "2024-07-31T13:00:14.815Z",
    timestampUpdated: "2024-10-24T11:38:23.972Z",
    timestampDeleted: null,
    bankType: "41-Bank Muamalat Malaysia Berhad",
  },
  {
    id: 8,
    uuid: "b8d60f11-d912-4e22-a170-9d4ba0645373",
    name: "Bank Islam Malaysia Berhad",
    abbreviation: "BIMB",
    timestampCreated: "2024-07-31T13:00:14.824Z",
    timestampUpdated: "2024-10-24T11:38:23.986Z",
    timestampDeleted: null,
    bankType: "45-Bank Islam Malaysia Berhad",
  },
  {
    id: 9,
    uuid: "e3162aea-4d26-444e-a63e-b8a5e721c241",
    name: "Bank Rakyat Berhad",
    abbreviation: "BRB, Bank Rakyat",
    timestampCreated: "2024-07-31T13:00:14.836Z",
    timestampUpdated: "2024-10-24T11:38:24.007Z",
    timestampDeleted: null,
    bankType: "02-Bank Rakyat Berhad",
  },
  {
    id: 10,
    uuid: "00c538fd-10ea-43f3-89bd-35f5a7a781ba",
    name: "Bank Simpanan Nasional Berhad",
    abbreviation: "BSN",
    timestampCreated: "2024-07-31T13:00:14.845Z",
    timestampUpdated: "2024-10-24T11:38:24.019Z",
    timestampDeleted: null,
    bankType: "10-Bank Simpanan Nasional Berhad",
  },
  {
    id: 11,
    uuid: "d0e6c284-4845-4b9f-870c-aea5d41b5242",
    name: "Bank of America",
    abbreviation: "BOA",
    timestampCreated: "2024-07-31T13:00:14.855Z",
    timestampUpdated: "2024-10-24T11:38:24.030Z",
    timestampDeleted: null,
    bankType: "07-Bank of America",
  },
  {
    id: 12,
    uuid: "964d76d9-0cd9-48cb-a4c0-f3e30be71bcc",
    name: "Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad / MUFJ BANK (MALAYSIA) BERHAD",
    abbreviation: "BTMUFB, BTMU, MUFJ Bank",
    timestampCreated: "2024-07-31T13:00:14.865Z",
    timestampUpdated: "2024-10-24T11:38:24.040Z",
    timestampDeleted: null,
    bankType: "52-Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad",
  },
  {
    id: 13,
    uuid: "f2afecf6-600a-439e-959f-a6fa05cacd43",
    name: "BNP Paribas (M) Bhd / BNP Paribas (Islamic)",
    abbreviation: "BNPP",
    timestampCreated: "2024-07-31T13:00:14.873Z",
    timestampUpdated: "2024-10-24T11:38:24.051Z",
    timestampDeleted: null,
    bankType: "60-BNP Paribas (M) Bhd / BNP Paribas (Islamic)",
  },
  {
    id: 14,
    uuid: "71d479ca-4f02-412d-872c-2ac873e4def9",
    name: "CIMB Bank Berhad / CIMB Islamic Bank Berhad",
    abbreviation: "CIMB",
    timestampCreated: "2024-07-31T13:00:14.881Z",
    timestampUpdated: "2024-10-24T11:38:24.061Z",
    timestampDeleted: null,
    bankType: "35-CIMB Bank Berhad",
  },
  {
    id: 15,
    uuid: "97df2c2c-71c3-4cd6-b2d0-deffab604a70",
    name: "Citi bank / CITIBANK BERHAD",
    abbreviation: "CITI",
    timestampCreated: "2024-07-31T13:00:14.892Z",
    timestampUpdated: "2024-10-24T11:38:24.074Z",
    timestampDeleted: null,
    bankType: "17-Citibank",
  },
  {
    id: 16,
    uuid: "f26aa623-0846-4830-952f-3bab37058902",
    name: "Deutsche Bank (M) Bhd",
    abbreviation: "DBMB",
    timestampCreated: "2024-07-31T13:00:14.908Z",
    timestampUpdated: "2024-10-24T11:38:24.084Z",
    timestampDeleted: null,
    bankType: "19-Deutsche Bank (M) Bhd",
  },
  {
    id: 17,
    uuid: "48800a4b-9754-434c-adb2-f3e657ae384b",
    name: "Hong Leong Bank Berhad/ Hong Leong Finance",
    abbreviation: "HLBB, HLB, Hong Leong Bank",
    timestampCreated: "2024-07-31T13:00:14.917Z",
    timestampUpdated: "2024-10-24T11:38:24.094Z",
    timestampDeleted: null,
    bankType: "24-Hong Leong Bank Berhad/ Hong Leong Finance",
  },
  {
    id: 18,
    uuid: "d3de0237-a97e-4ec1-8c7a-103ef042350c",
    name: "HSBC Bank Berhad / HSBC Bank Malaysia Berhad",
    abbreviation: "HSBC",
    timestampCreated: "2024-07-31T13:00:14.927Z",
    timestampUpdated: "2024-10-24T11:38:24.105Z",
    timestampDeleted: null,
    bankType: "22-HSBC Bank Berhad",
  },
  {
    id: 19,
    uuid: "3e4cb87b-03bc-4e65-9217-8de87650b228",
    name: "Industrial and Commercial Bank of China (Malaysia) Berhad",
    abbreviation: "ICBC",
    timestampCreated: "2024-07-31T13:00:14.937Z",
    timestampUpdated: "2024-10-24T11:38:24.116Z",
    timestampDeleted: null,
    bankType: "59-Indust & Comm Bank of China (M) Berhad",
  },
  {
    id: 20,
    uuid: "ea03f84c-6775-4ca4-90ed-5217ccd845a3",
    name: "JP Morgan Chase",
    abbreviation: "JPMC",
    timestampCreated: "2024-07-31T13:00:14.947Z",
    timestampUpdated: "2024-10-24T11:38:24.128Z",
    timestampDeleted: null,
    bankType: "48-JP Morgan Chase",
  },
  {
    id: 21,
    uuid: "41b2c7e0-a0b7-4408-a572-06d830d4a4ce",
    name: "Kuwait Finance House",
    abbreviation: "KFH",
    timestampCreated: "2024-07-31T13:00:14.957Z",
    timestampUpdated: "2024-10-24T11:38:24.138Z",
    timestampDeleted: null,
    bankType: "47-Kuwait Finance House",
  },
  {
    id: 22,
    uuid: "bc2c8c50-0daf-4d04-9898-f73f1c57a498",
    name: "Malayan Banking Berhad / Maybank Islamic Berhad",
    abbreviation: "MBB, Maybank",
    timestampCreated: "2024-07-31T13:00:14.966Z",
    timestampUpdated: "2024-10-24T11:38:24.160Z",
    timestampDeleted: null,
    bankType: "27-Maybank/ Malayan Banking Berhad",
  },
  {
    id: 23,
    uuid: "dffaf81b-6112-45f9-8108-7939128c2240",
    name: "Mizuho Bank (M) Berhad",
    abbreviation: "MIZUHO",
    timestampCreated: "2024-07-31T13:00:14.978Z",
    timestampUpdated: "2024-10-24T11:38:24.171Z",
    timestampDeleted: null,
    bankType: "73-Mizuho Bank (M) Berhad",
  },
  {
    id: 24,
    uuid: "ace1f9fb-2841-4998-8549-9345c1ddd014",
    name: "Oversea-Chinese Banking Corporation LImited",
    abbreviation: "OCBC, (OCBC) / OCBC Al-Amin Berhad",
    timestampCreated: "2024-07-31T13:00:14.987Z",
    timestampUpdated: "2024-10-24T11:38:24.181Z",
    timestampDeleted: null,
    bankType: "29-OCBC Bank Berhad",
  },
  {
    id: 25,
    uuid: "c1f27220-2c24-43d6-b3ae-638efc3670e0",
    name: "Public Bank Berhad/ Public Finance Berhad / PUBLIC BANK BHD",
    abbreviation: "PBB, PB Enterprise, public Islamic bank",
    timestampCreated: "2024-07-31T13:00:14.996Z",
    timestampUpdated: "2024-10-24T11:38:24.191Z",
    timestampDeleted: null,
    bankType: "33-Public Bank Berhad/ Public Finance Berhad",
  },
  {
    id: 26,
    uuid: "62ab5c62-6642-4a3d-935a-2cbcdf6e885a",
    name: "RHB Bank Berhad / RHB Islamic Bank Berhad",
    abbreviation: "RHB",
    timestampCreated: "2024-07-31T13:00:15.004Z",
    timestampUpdated: "2024-10-24T11:38:24.202Z",
    timestampDeleted: null,
    bankType: "18-RHB Bank Berhad",
  },
  {
    id: 27,
    uuid: "db34c2d8-def7-47aa-a94f-c7d67d217cf9",
    name: "Standard Chartered Bank Berhad / Standard Chartered Bank Malaysia",
    abbreviation: "SCB",
    timestampCreated: "2024-07-31T13:00:15.014Z",
    timestampUpdated: "2024-10-24T11:38:24.211Z",
    timestampDeleted: null,
    bankType: "14-Standard Chartered Bank Berhad",
  },
  {
    id: 28,
    uuid: "2054821f-6aad-4c90-8172-c8283acec5e2",
    name: "Sumitomo Mitsui Banking Corporation (M) Bhd",
    abbreviation: "SMBC",
    timestampCreated: "2024-07-31T13:00:15.023Z",
    timestampUpdated: "2024-10-24T11:38:24.222Z",
    timestampDeleted: null,
    bankType: "51-Sumitomo Mitsui Banking Corporation (M) Bhd",
  },
  {
    id: 29,
    uuid: "fb4065ab-64ba-4420-b656-34fd327084a5",
    name: "The Royal Bank of Scotland Bhd",
    abbreviation: "RBS",
    timestampCreated: "2024-07-31T13:00:15.033Z",
    timestampUpdated: "2024-10-24T11:38:24.236Z",
    timestampDeleted: null,
    bankType: "46-The Royal Bank of Scotland Bhd",
  },
  {
    id: 30,
    uuid: "9cfb0cd3-7585-4f5c-81b7-ee141a1301e5",
    name: "United Overseas Bank (Malaysia) Berhad",
    abbreviation: "UOB",
    timestampCreated: "2024-07-31T13:00:15.043Z",
    timestampUpdated: "2024-10-24T11:38:24.246Z",
    timestampDeleted: null,
    bankType: "26-United Overseas Bank (M) Bhd",
  },
  {
    id: 31,
    uuid: "38e41968-e938-419a-a6ac-74bbe0463bed",
    name: "China Construction Bank (Malaysia) Berhad",
    abbreviation: "CCB",
    timestampCreated: "2024-07-31T13:00:15.052Z",
    timestampUpdated: "2024-10-24T11:38:24.256Z",
    timestampDeleted: null,
    bankType: "65-China Construction Bank (Malaysia) Berhad",
  },
  {
    id: 32,
    uuid: "a62d54fd-b317-4d00-9022-220a31db9375",
    name: "Bangkok Bank Berhad",
    abbreviation: "BBB",
    timestampCreated: "2024-07-31T13:00:15.061Z",
    timestampUpdated: "2024-10-24T11:38:24.270Z",
    timestampDeleted: null,
    bankType: "04-Bangkok Bank Berhad",
  },
  {
    id: 33,
    uuid: "ef6da932-b40d-4f41-959e-76c0bb8f7168",
    name: "Malaysia Building Society Berhad (MBSB)",
    abbreviation: "MBSB",
    timestampCreated: "2024-07-31T13:00:15.070Z",
    timestampUpdated: "2024-10-24T11:38:24.287Z",
    timestampDeleted: null,
    bankType: "75-MBSB",
  },
];
