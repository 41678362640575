<template>
  <v-container fluid>
    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError"
        ><span class="d-block">{{ alertError }}</span></v-alert
      >
    </transition>

    <v-card>
      <v-toolbar :color="HRDC_BLUE_THEME_COLOR">
        <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title v-if="!loadingDialog">
          <div class="d-flex align-center text-white">
            {{ model.name.plural }} {{ totalProgress }}
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <div>
        <div class="pa-5">
          <v-row>
            <v-col class="d-flex justify-content-end">
              <v-btn
                depressed
                dark
                color="secondary"
                @click="ssmAuthLoginDialog = true"
                >Get SSM Auth Token API</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                depressed
                dark
                :color="HRDC_BLUE_THEME_COLOR"
                class="mr-2"
                @click="getHrdcCompanies()"
                >1. Get HRDC Companies</v-btn
              >
              <v-btn
                depressed
                dark
                color="warning"
                class="mr-2"
                @click="getSsmCompanyData('NEW_BRM')"
                >2A. Call SSM API (Get by New BRM)</v-btn
              >
              <v-btn
                depressed
                dark
                color="teal"
                @click="getSsmCompanyData('OLD_BRM')"
                >2B. Call SSM API (Get by Old BRM)</v-btn
              >
            </v-col>
          </v-row>
          <hr />
          <v-row class="d-flex">
            <table>
              <thead>
                <tr>
                  <th style="text-align: center">No.</th>
                  <th style="text-align: center">Company Name</th>
                  <th style="text-align: center">New BRM</th>
                  <th style="text-align: center">Old BRM</th>
                  <th style="text-align: center">HRDC Data</th>
                  <th style="text-align: center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="d-flex1 align-items-center"
                  v-for="(item, index) in datas"
                  :key="index"
                >
                  <td>{{ item.idx }}</td>
                  <td class="pa-2">
                    <v-text-field
                      dense
                      filled
                      v-model="datas[index].ssmCompanyName"
                    >
                    </v-text-field>
                  </td>
                  <td class="pa-2">
                    <v-text-field dense filled v-model="datas[index].ssmNewBrm">
                    </v-text-field>
                  </td>
                  <td class="pa-2">
                    <v-text-field dense filled v-model="datas[index].ssmOldBrm">
                    </v-text-field>
                  </td>
                  <td style="padding-bottom: 25px; text-align: center">
                    <v-card>
                      <div class="d-flex">
                        <v-col cols="3">Company Name: </v-col>
                        <v-col cols="6" class="text-left">
                          <v-btn
                            @click="copyContent(datas[index].oldCompanyName)"
                          >
                            {{ datas[index].oldCompanyName }}
                          </v-btn>
                        </v-col>
                      </div>
                      <div class="d-flex">
                        <v-col cols="3">Company BRM: </v-col>
                        <v-col cols="6" class="text-left">
                          <v-btn @click="copyContent(datas[index].oldSsmNo)">
                            {{ datas[index].oldSsmNo }}
                          </v-btn>
                        </v-col>
                      </div>
                    </v-card>
                  </td>
                  <td style="padding-bottom: 25px; text-align: center">
                    <v-btn
                      depressed
                      color="success"
                      @click="updateCompanyData(index)"
                    >
                      Update
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-row>
        </div>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>

    <!-- SSM Auth login dialog -->
    <v-dialog v-model="ssmAuthLoginDialog" max-width="400">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark> SSM Login </v-toolbar>

        <div class="pa-4">
          <AError :api="apiGetSsmAuthToken"></AError>

          <v-text-field dense filled v-model="ssmLoginToken" label="Token">
          </v-text-field>
          <v-text-field dense filled v-model="ssmCookie" label="Cookie">
          </v-text-field>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="getSsmAuthToken()"
          >
            Get Token
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="ssmAuthLoginDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import Service from "@/objects/service";

export default {
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    totalProgress() {
      return `(${this.finishedCompaniesLength}/${this.allCompaniesLength} - ${(
        (this.finishedCompaniesLength / this.allCompaniesLength) *
        100
      ).toFixed(2)}%)`;
    },
  },
  props: ["params"],
  data: () => ({
    apiGetCompanies: new Api(),
    apiGetSsmAuthToken: new Api(),
    apiUpdateCompanyData: new Api(),
    model: new Model(),
    service: new Service(),
    alertError: null,
    loadingDialog: false,
    datas: [],
    SSM_EINFO_SEARCH_API_URL: "https://www.ssm-einfo.my/api/v1/search",
    SSM_AUTH_TOKEN: null,
    ssmAuthLoginDialog: false,
    ssmLoginToken: null,
    ssmCookie: null,
    allCompaniesLength: 0,
    finishedCompaniesLength: 0,
  }),
  async created() {
    this.showLoadingDialog();
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiGetCompanies: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/company/get-all-companies`,
      },
      apiGetSsmAuthToken: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/company/get-ssm-login-token`,
        method: "post",
      },
      apiUpdateCompanyData: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/company/update-company-data`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].query !== undefined) {
        this[api].setQuery(apiDetails[api].query);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.rolesAssignation(this.auth.roles);

    this.SSM_AUTH_TOKEN = this.$store.state.hrdc.ssmToken;

    // Get leftover company progress.
    this.apiGetCompanies.setQuery(`all=true`);
    this.apiGetCompanies.setCallbackCompleted((response) => {
      const { allCompaniesLength, finishedCompaniesLength } = response;
      this.allCompaniesLength = allCompaniesLength;
      this.finishedCompaniesLength = finishedCompaniesLength;
    });
    this.apiGetCompanies.fetch();

    this.hideLoadingDialog();
  },
  methods: {
    redirectBack() {
      let route = "HrdcSettings";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
      });
    },
    getHrdcCompanies() {
      this.showLoadingDialog();
      this.alertError = null;

      this.apiGetCompanies.setQuery("");
      this.apiGetCompanies.setCallbackCompleted((response) => {
        this.datas = [];

        const data = response.data;

        for (let i = 0; i < data.length; i++) {
          this.datas.push({
            idx: i + 1,
            uuid: data[i].uuid,
            oldCompanyName: data[i].oldCompanyName.toUpperCase(),
            oldSsmNo: data[i].oldSsmNo.trim(),
            oldFormerSsmNo: null,
            ssmCompanyName: null,
            ssmNewBrm: null,
            ssmOldBrm: null,
          });
        }

        this.hideLoadingDialog();
      });
      this.apiGetCompanies.fetch();
    },
    getSsmCompanyData(ssmType) {
      this.alertError = null;

      if (this.datas.length == 0) {
        this.alertError = "Please retrieve company data first";
      }

      for (let i = 0; i < this.datas.length; i++) {
        let keyword = null;
        let tmpKeywordArr = null;

        if (ssmType == "NEW_BRM") {
          tmpKeywordArr = this.datas[i].oldSsmNo.split("(");
          keyword = tmpKeywordArr[0];
        }

        if (ssmType == "OLD_BRM") {
          let ssmNo = this.datas[i].oldSsmNo;
          tmpKeywordArr = ssmNo.slice(0, -1) + "-" + ssmNo.slice(-1);
          keyword = tmpKeywordArr;
        }

        this.$axios
          .post(
            this.SSM_EINFO_SEARCH_API_URL,
            {
              keyword,
              "g-recaptcha-response": 1,
              stype: 1,
              page: 1,
              searchControl: "on",
              // _token: "JWc1tyiwjjHtM6dueTm3wcA4STIUdFtRbNc3KmhF",
            },
            {
              headers: {
                Authorization: `Bearer ${this.SSM_AUTH_TOKEN}`,
              },
            }
          )
          .then((res) => {
            let data = res.data.data.data;
            if (data.length > 0) {
              data = data[0];

              const org_name = data.org_name;
              const oldSsmNo = data.new_no;
              const oldFormerSsmNo = `${data.old_no}-${data.AlphaCode}`;

              this.datas[i].ssmCompanyName = org_name;
              this.datas[i].ssmNewBrm = oldSsmNo;
              this.datas[i].ssmOldBrm = oldFormerSsmNo;
            }
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.alertError = "Authorization token expired, please refresh!";
            }

            return false;
          });
      }
    },
    getSsmAuthToken() {
      this.apiGetSsmAuthToken.setParams({
        _token: this.ssmLoginToken,
        form_name: "login",
        username: "alanwake88",
        password: "Abcde@12345",
        cookie: this.ssmCookie,
      });
      this.apiGetSsmAuthToken.setCallbackCompleted((response) => {
        try {
          this.$store.commit("assignSsmAuthToken", response);
          this.SSM_AUTH_TOKEN = response;

          this.ssmAuthLoginDialog = false;
        } catch (err) {
          console.log(err);
        }
      });

      this.apiGetSsmAuthToken.fetch();
    },
    copyContent(item) {
      const textarea = document.createElement("textarea");
      textarea.value = item.trim();
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");

      // Remove the temporary textarea
      document.body.removeChild(textarea);

      this.$store.dispatch("showMessage", {
        message: "Copied Successfully!",
        messageType: "info",
        timeout: 2000,
      });
    },
    updateCompanyData(index) {
      const data = this.datas[index];

      if (!data.ssmCompanyName || !data.ssmNewBrm || !data.ssmOldBrm) {
        this.alertError = "Please fill in all the required fields!";
        return false;
      }

      this.apiUpdateCompanyData.setParams(data);
      this.apiUpdateCompanyData.setCallbackCompleted((response) => {
        if (response) {
          this.datas = this.datas.filter((item) => item.idx !== data.idx);
          this.finishedCompaniesLength++;
        }
      });
      this.apiUpdateCompanyData.fetch();
    },
  },
};
</script>
