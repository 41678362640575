<template>
  <v-container fluid class="tb-layout-browse1">
    <v-card class="pa-2">
      <BreadHeader :title="headerTitle">
        <MenuNewApplication
          :isDmAdminRolesChild="isDmAdminRoles"
          @addNewApplication="addNewApplication"
          @addNewBulkApplication="toggleCreateBulkApplicationDialog"
        ></MenuNewApplication>
      </BreadHeader>

      <v-row class="tb-filter-section">
        <v-row no-gutters>
          <v-col md="4">
            <div class="tb-left-second-section">
              <div>
                <h5
                  v-if="switchToAdvanceFilter == true"
                  @click="switchToAdvanceFilter = false"
                  style="cursor: pointer"
                >
                  Switch to Normal Filter
                </h5>
                <h5
                  v-if="switchToAdvanceFilter == true"
                  @click="switchToAdvanceFilter = true"
                  style="cursor: pointer"
                >
                  Switch to Advance Filter
                </h5>
              </div>
              <div>
                <h4 v-on:click="isShow = !isShow" style="float: left">
                  Filter
                  <v-icon left> mdi mdi-menu-down </v-icon>
                </h4>
                <span v-if="switchToAdvanceFilter == false">
                  {{ conditions.length }} Filter Applied
                </span>
              </div>
            </div>
          </v-col>
          <v-col md="8">
            <!-- include column selection compnent -->

            <BreadTabulatorSelectColumn
              ref="selectColumn"
              :model="model"
              @applyColumnSelection="applyColumnSelection"
            ></BreadTabulatorSelectColumn>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2">
          <v-col md="12">
            <div class="tb-normal-filter" v-show="isShow">
              <div
                class="tb-filter-area"
                v-show="switchToAdvanceFilter == false"
              >
                <BreadTabulatorFilterable
                  ref="filter"
                  :model="model"
                  :setConditions="setConditions"
                ></BreadTabulatorFilterable>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-row>

      <!-- defult search area-->
      <div class="tb-search-area">
        <v-row>
          <v-col md="6">
            <BreadTabulatorSearchable
              ref="search"
              :model="model"
              :setConditions="setConditions"
            ></BreadTabulatorSearchable>
            <div>
              <span
                id="record-count"
                style="float: left; font-size: 12px"
              ></span>
            </div>
          </v-col>

          <v-col md="6" class="d-flex justify-end">
            <v-btn-toggle dense multiple class="mr-1">
              <!-- have to include data download component-->
              <BreadTabulatorExportAll
                ref="exportData"
                :model="model"
                :apiUrl="apiGetBrowseUrl()"
                @downloadData="downloadData"
              >
              </BreadTabulatorExportAll>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </div>

      <BreadTabulatorViewLatest
        ref="customView"
        :model="model"
        :selectedCustomColums="selectedCustomColums"
        :selectedAdvanceFilterConditions="selectedAdvanceFilterConditions"
        :selectedFilterConditions="selectedFilterConditions"
        :cColumnSort="cColumnSort"
        :displayRowCount="displayRowCount"
        :listAllViews="listAllViews"
        :allResizeColumns="allResizeColumns"
        @changeTableDetails="changeTableDetails"
      ></BreadTabulatorViewLatest>

      <BreadTabulator
        ref="tabulatorDataTable"
        layout="fitColumns"
        :autoColumns="false"
        :paginationSize="paginationSize"
        :paginationSizeSelector="paginationSizeSelector"
        :apiUrl="apiGetBrowseUrl()"
        :columns="columns"
        :modelKey="modelKey"
        :columnSelectionDisabledCount="columnSelectionDisabledCount"
        @getCurrentPageSize="getCurrentPageSize"
        @getCurrentSort="getCurrentSort"
        @getCurrentColumnWidth="getCurrentColumnWidth"
        @getCurrentPageNumber="getCurrentPageNumber"
      ></BreadTabulator>

      <v-dialog
        v-model="flagDialog"
        max-width="500"
        @click:outside="clearErrorMsg()"
      >
        <v-card>
          <v-toolbar color="#063058" dark>Add a Remark</v-toolbar>
          <div class="text-h6 pa-5">
            <v-alert
              dense
              border="left"
              icon="mdi-alert"
              type="error"
              class="text-pre-wrap"
              v-if="api.error"
              ><span class="d-block">{{ this.api.error }}</span></v-alert
            >

            <v-textarea
              v-model="flagRemarks"
              :error-messages="errorField.flagRemarks"
              auto-grow
              filled
              label="Remark"
            >
              {{ flagRemarks }}
              <template v-slot:append>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"> mdi-information </v-icon>
                  </template>
                  Flag case is required to put remarks
                </v-tooltip>
              </template>
            </v-textarea>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green lighten-1"
              class="text-light"
              @click="updateFlagAndRemark()"
            >
              {{ flagOrUnflagText }}
            </v-btn>

            <v-btn
              v-if="flagOrUnflagText == 'Unflag'"
              color="blue lighten-2"
              class="text-light"
              @click="updateFlagRemarkOnly()"
            >
              Update Flag Remark
            </v-btn>

            <v-btn
              color="red lighten-1"
              class="text-light"
              @click="closeUpdateFlagAndRemarkDialog()"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <NewBulkApplicationModal
        :createBulkApplicationDialog="createBulkApplicationDialog"
        :hrdcThemeColor="HRDC_BLUE_THEME_COLOR"
        :bulkActions="bulkActions"
        :eventLists="eventLists"
        :serviceKey="service.key"
        :formData="formData"
        :errorField="errorField"
        :excelDatas="excelDatas"
        :errorDetected="errorDetected"
        @toggleCreateBulkApplicationDialog="toggleCreateBulkApplicationDialog"
        @executeBulkAction="executeBulkAction"
        @copyContent="copyContent"
        @readExcelData="readExcelData"
        @importData="submit"
        @uploadSsBankAtch="uploadSsBankAtch"
      ></NewBulkApplicationModal>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>
<script>
import store from "@/store";
import * as XLSX from "xlsx";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tpdiy/objects/globalData";
import MenuNewApplication from "@/components/views/MenuNewApplication";
import NewBulkApplicationModal from "@/components/views/NewBulkApplicationModal";

export default {
  mixins: [hrdcFunction],
  components: {
    MenuNewApplication,
    NewBulkApplicationModal,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
      hrdc_tpdiy: (state) => state.hrdc_tpdiy.data,
    }),
    headerTitle() {
      return `${process.env.VUE_APP_SERVICE_HRDC_TPDIY_APP_NAME} ${this.model.name.plural}`;
    },
  },
  props: ["params"],
  data: () => ({
    api: new Api(),
    apiGetEvents: new Api(),
    apiCreateBulkApplications: new Api(),
    conditions: [],
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    listAllViews: [],
    dataStatus: "active",
    columns: [],
    paginationSize: 10,
    paginationSizeSelector: [10, 50, 100, 200, 500],
    modelKey: "",
    parentId: "",
    cColumnSort: [],
    displayRowCount: 10,
    selectedCustomColums: [],
    selectedAdvanceFilterConditions: [],
    selectedFilterConditions: [],
    switchToAdvanceFilter: false,
    isShow: false,
    allResizeColumns: [],
    columnSelectionDisabledCount: 5,
    currentPageNo: 1,
    flagDialog: false,
    flagDetails: null,
    flagRemarks: null,
    flagOrUnflagText: "Flag",
    errorField: {
      flagRemarks: null,
      bulkApplicationAttachment: null,
      ssRefundBankAccAtch: null,
    },
    loadingDialog: false,
    createBulkApplicationDialog: false,
    bulkActions: [
      {
        text: "Download Excel Template (Blank)",
        value: "downloadBlankExcelTemplate",
      },
      {
        text: "Download Excel Template (Dummy Data)",
        value: "downloadDummyExcelTemplate",
      },
    ],
    eventLists: [],
    dbEventLists: [],
    excelDatas: [],
    formData: {
      bulkApplicationAttachment: null,
      ssRefundBankAccAtch: [],
    },
    errorDetected: false,
    bulkSsRefundBankAccAtch: [],
    matchSsArr: [],
    excel_template: null,
    excel_template_name: null,
  }),
  async created() {
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    this.modelKey = this.$route.params.modelKey;
    this.parentId = this.$route.params.parentId;

    // Events Assignation.
    this.serviceDataAssignation(this.model.serviceKey);

    const apiDetails = {
      apiGetEvents: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/event/get-events?active=true&event_type=${
          this.hrdcType
        }`,
      },
      apiCreateBulkApplications: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/create-bulk-application`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.columns = this.$_.filter(
      this.model.browse.table.headers,
      (headers) => {
        return headers;
      }
    );

    var columnIndex = 0;
    for (var column of this.columns) {
      // Add click company cell direct to view details.
      if (column.field == "company") {
        this.columns[columnIndex].cellClick = (e, cell) => {
          const { applicationUuid, stageId } = cell.getData();

          this.$store.commit("assignHrdcTpdiyData", {
            applicationUuid,
            stageId,
            viewId: this.$route.query.viewId,
          });

          this.$router.push({
            name: "ViewHrdcTpdiyApplication",
            query: {
              currentPageNo: this.currentPageNo,
              _ausk: applicationUuid,
            },
          });
        };

        this.columns[columnIndex].contextMenu = [
          {
            label: "View Details",
            action: (_, cell) => {
              const { applicationUuid, stageId } = cell.getData();

              this.$store.commit("assignHrdcTpdiyData", {
                applicationUuid,
                stageId,
                viewId: this.$route.query.viewId,
              });

              const routeData = this.$router.resolve({
                name: "ViewHrdcTpdiyApplication",
                query: {
                  currentPageNo: this.currentPageNo,
                  _ausk: applicationUuid,
                },
              });
              window.open(routeData.href, "_blank");
            },
          },
        ];
      }

      columnIndex++;
    }

    this.columns.push({
      title: "Flag",
      field: "applicationUuid",
      dataType: "onlyLikeEqualNotEqual",
      filterable: true,
      searchable: true,
      sortable: false,
      headerFilter: false,
      selectableColumn: true,
      hozAlign: "left",
      headerWordWrap: true,
      headerSort: false,
      width: "80",
      formatter: (cell) => {
        const data = cell.getData();
        const flagVal = data.isFlag;
        return this.flagCol(flagVal);
      },
      cellClick: (e, cell) => {
        const data = cell.getData();
        const flagVal = data.isFlag;
        let flagText = "Unflag";
        if (!flagVal) {
          flagText = "Flag";
        }

        // Add the flag text here.
        this.flagOrUnflagText = flagText;

        this.flagRemarks = data.flagRemarks;
        this.flagDialog = !this.flagDialog;
        this.flagDetails = cell;
      },
    });

    this.columns.push({
      title: "Next Action",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      width: "100",
      formatter: function () {
        const btn = `
          <button type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light elevation-2 v-size--small">
            <span class="v-btn__content">
              <i aria-hidden="true" class="v-icon notranslate mdi mdi-send-variant theme--light "></i>
            </span>
          </button>
        `;

        return btn;
      },
      htmlOutput: true,
      cellClick: (_, cell) => {
        const viewId = this.$route.query.viewId;
        const applicationUuid = cell.getRow().getData().applicationUuid;
        const data = cell.getData();

        // If application deleted, redirect to View Details page.
        if (data.timestampDeleted) {
          this.$router.push({
            name: "ViewHrdcTpdiyApplication",
            query: {
              currentPageNo: this.currentPageNo,
              _ausk: applicationUuid, // ausk=Application UUID Secret Key
            },
          });

          return;
        }

        const stageId = data.stageId;

        // Construct next form to redirect.
        let route = this.nextFormRedirect(stageId);
        let params = {
          applicationUuid,
          stageId,
          viewId,
        };

        if (route !== "") {
          store.commit("assignHrdcTpdiyData", params);

          this.$router.push({
            name: route,
            query: {
              currentPageNo: this.currentPageNo,
              _ausk: applicationUuid, // ausk=Application UUID Secret Key
              viewId: "all",
            },
          });
        }
      },
    });

    this.columns.push({
      title: "Actions",
      headerSort: false,
      editor: "list",
      hozAlign: "center",
      headerHozAlign: "center",
      editorParams: {
        valuesLookup: (cell) => {
          const cellData = cell.getData();
          const paymentChannel = cellData.isPaymentMade;
          const options = [
            {
              label: "View Details",
              value: "ViewHrdcTpdiyApplication",
            },
            {
              label: "Edit Details",
              value: "EditHrdcTpdiyApplication",
            },
            {
              label: "General Info",
              value: "HrdcTpdiyAddGeneralInfo",
            },
            {
              label: "Send HRDC Email to Client",
              value: "HrdcTpdiySendEmailToClient",
            },
            {
              label: "Grant Approval & Revert Form",
              value: "HrdcTpdiyClientRevertForm",
            },
            {
              label: "Manual Invoice to HRDC",
              value: "HrdcTpdiyManualInvoiceToHrdc",
            },
            {
              label: "Claim Submitted to HRDC",
              value: "HrdcTpdiyClaimSubmitToHrdc",
            },
            {
              label: "Claim Approved from HRDC",
              value: "HrdcTpdiyClaimApprovedFromHrdc",
            },
            {
              label: "Payment Received from HRDC",
              value: "HrdcTpdiyPaymentReceivedFromHrdc",
            },
          ];

          if (paymentChannel) {
            options.push(
              {
                label: "Refund Email Submission",
                value: "HrdcTpdiyRefundEmailSubmission",
              },
              {
                label: "Refund Email Approval 1",
                value: "HrdcTpdiyRefundEmailApproval1",
              },
              {
                label: "Refund Email Approval 2",
                value: "HrdcTpdiyRefundEmailApproval2",
              },
              {
                label: "Refund Email AR to AP",
                value: "HrdcTpdiyRefundEmailArToAp",
              },
              {
                label: "Email Payment Advice to Client",
                value: "HrdcTpdiyEmailPaymentAdviceToClient",
              }
            );
          }

          return options;
        },
      },
      formatter: function () {
        const btn = `
            <button type="button" class="v-btn v-btn--is-elevated dropdown-toggle v-btn--has-bg theme--light text-white v-size--small" style="background-color: #063058;">
              <span class="v-btn__content">
                Actions
              </span>
            </button>
          `;

        return btn;
      },
      htmlOutput: true,
      cellEdited: (cell) => {
        const viewId = this.$route.query.viewId;
        const selectedValue = cell.getValue();
        const applicationUuid = cell.getRow().getData().applicationUuid;
        const data = cell.getData();
        const stageId = data.stageId;
        let route = selectedValue;
        let params = {
          applicationUuid,
          stageId,
          viewId,
        };

        if (route !== "") {
          store.commit("assignHrdcTpdiyData", params);

          this.$router.push({
            name: route,
            query: {
              currentPageNo: this.currentPageNo,
              _ausk: applicationUuid,
            },
          });
        }
      },
    });

    this.apiGetEvents.setCallbackCompleted((response) => {
      try {
        const { status, data } = response;

        if (status) {
          for (const item of data) {
            this.eventLists.push({
              text: `${this.formatEventTitle(item, this.hrdcType)} #${item.id}`,
              value: item.id,
            });

            this.dbEventLists.push({
              id: item.id,
              uuid: item.uuid,
              title: item.title,
              eventCategory: item.eventCategory,
            });
          }
        }
      } catch (err) {
        console.log(err);
        this.errorField.events =
          "Unavailable to retrieve events, please contact admin for support";
      }
    });
    this.apiGetEvents.fetch();

    this.rolesAssignation(this.auth.roles);
  },
  mounted() {
    const viewId = this.$route.query.viewId;

    if (viewId !== "all" && viewId !== "trashed") {
      this.getAllViews(parseInt(viewId, 10));
    } else {
      this.getAllViews(viewId);
    }
  },
  methods: {
    getConditions() {
      return this.conditions;
    },
    setConditions(conditions) {
      this.selectedFilterConditions = conditions;
      this.conditions = conditions;
      this.$refs.tabulatorDataTable.getFilterCondition(conditions);
    },
    apiGetBrowseUrl() {
      // Check if user have salesperson role, then assign only this salesperson can see back own sales.
      let browseUrl = `${this.$service[this.service.key]}/v1/en/console/${
        this.model.key
      }?hrdcType=${this.hrdcType}`;

      return browseUrl;
    },
    apiGetBrowseTrashUrl() {
      let browseDeleteUrl = `
        ${this.$service[this.service.key]}/v1/en/console/${
        this.model.key
      }?deleted=true&hrdcType=${this.hrdcType}
      `;

      if (this.isSalespersonRoles && this.auth.roles.length == 1) {
        browseDeleteUrl = browseDeleteUrl + `&email=${this.auth.email}`;
      }

      return browseDeleteUrl;
    },
    setFiltersViaConditions(conditions) {
      this.$refs.filter.setFiltersViaConditions(conditions);
    },
    getCurrentPageSize(pagesize) {
      this.displayRowCount = pagesize;
    },
    getCurrentSort(sorters) {
      this.cColumnSort = sorters;
      this.cColumnSort = sorters.map((sorter) => {
        return {
          column: sorter.field,
          dir: sorter.dir,
        };
      });
    },

    getCurrentColumnWidth(resizedColumns) {
      const resizedColumnField = resizedColumns.getField();
      const resizedColumnWidth = resizedColumns.getWidth();

      //set the resized column width and filed name to the array
      const resizedColumnWidthAndField = {
        field: resizedColumnField,
        width: resizedColumnWidth,
      };

      const index = this.allResizeColumns.findIndex((item) => {
        return item.field === resizedColumnField;
      });

      if (index !== -1) {
        this.allResizeColumns[index].width = resizedColumnWidth;
      } else {
        this.allResizeColumns.push(resizedColumnWidthAndField);
      }
    },
    changeTableDetails(listAllViews, viewId) {
      // get the view details from listAllViews where id is equal to viewId
      if (viewId == "trashed") {
        this.$refs.tabulatorDataTable.callApiUrl(this.apiGetBrowseTrashUrl());
        this.$refs.selectColumn.showAllColumns([]);
        this.$refs.tabulatorDataTable.callSavedHeaderWidth([
          {
            field: "company",
            width: 300,
          },
        ]);
        this.$refs.search.getFilterCondition([]); /// add this one
        this.$refs.customView.changeSelectedTabStatus("trashed", 0);
      } else if (viewId == "all") {
        this.$refs.tabulatorDataTable.callSavedHeaderWidth([
          {
            field: "company",
            width: 300,
          },
        ]);
        this.$refs.selectColumn.showAllColumns([]);
        this.$refs.search.getFilterCondition([]);
        this.$refs.customView.changeSelectedTabStatus("all", 1);
        this.setCurrentPageTo();
      } else {
        const tabIndex =
          listAllViews.indexOf(listAllViews.find((x) => x.id === viewId)) + 2;
        this.$refs.customView.changeSelectedTabStatus(viewId, tabIndex);
        const singleViewDetail = listAllViews.filter((item) => {
          return item.id === viewId;
        });

        if (singleViewDetail.length > 0) {
          if (
            JSON.parse(singleViewDetail[0].manageColumnCustomWidth).length > 0
          ) {
            this.$refs.tabulatorDataTable.callSavedHeaderWidth(
              JSON.parse(singleViewDetail[0].manageColumnCustomWidth)
            );
          }

          this.$refs.selectColumn.showViewSelectedColumns(
            JSON.parse(singleViewDetail[0].cColumnCondition)
          );

          // calling API
          if (JSON.parse(singleViewDetail[0].filterCondition).length > 0) {
            this.$refs.filter.setFiltersViaConditions(
              JSON.parse(singleViewDetail[0].filterCondition)
            );
          }

          // calling API
          if (JSON.parse(singleViewDetail[0].cColumnSort).length > 0) {
            this.$refs.tabulatorDataTable.callSavedColumnSort(
              JSON.parse(singleViewDetail[0].cColumnSort)
            );
          }

          // calling API
          if (singleViewDetail[0].displayRowCount != this.paginationSize) {
            this.$refs.tabulatorDataTable.callSavedPageSize(
              singleViewDetail[0].displayRowCount
            );
          }
          // add following one
          this.$refs.search.getFilterCondition(
            JSON.parse(singleViewDetail[0].filterCondition)
          );
        }

        this.setCurrentPageTo();
      }
    },
    getAllViews(viewId) {
      this.api.setUrl(
        `${this.$service.crm}/v1/en/console/crmView/list?modelKey=${this.model.crmViewKey}`
      );
      this.api.setCallbackCompleted((response) => {
        // get all private views when the ouwnerUuid is equal to auth.uuid
        const privateViews = response.filter((view) => {
          return view.isPrivate === true && view.ownerUuid === this.auth.uuid;
        });

        // get all public views
        const publicViews = response.filter((view) => {
          return view.isPrivate === false;
        });

        // push all private and public views to listAllViews
        this.listAllViews = [...publicViews, ...privateViews];
        this.changeTableDetails(this.listAllViews, viewId);
      });
      this.api.fetch();
    },
    applyColumnSelection(selectedColumns) {
      this.selectedCustomColums = selectedColumns;
      this.$refs.tabulatorDataTable.applyColumnSelection(selectedColumns);
    },
    getCurrentPageNumber(pageno) {
      this.currentPageNo = pageno;
    },
    setCurrentPageTo() {
      var pageNo =
        this.$store.state.developer.data.paginationPageNo != null
          ? this.$store.state.developer.data.paginationPageNo
          : 1;
      this.$refs.tabulatorDataTable.tabulator.setPage(pageNo);
    },
    nextFormRedirect(stageId) {
      let route = null;
      switch (stageId) {
        case this.hrdcData.stagePriority.applicationSubmitted:
          route = "ViewHrdcTpdiyApplication";
          break;

        case this.hrdcData.stagePriority.applicationApproved:
          route = "HrdcTpdiySendEmailToClient";
          break;

        case this.hrdcData.stagePriority.sentConfirmationEmailToClient:
        case this.hrdcData.stagePriority.clientRevertFormIncomplete:
        case this.hrdcData.stagePriority.grantApproved:
        case this.hrdcData.stagePriority.formsCompleted:
          route = "HrdcTpdiyClientRevertForm";
          break;

        case this.hrdcData.stagePriority.clientRevertT3Form:
          route = "HrdcTpdiyManualInvoiceToHrdc";
          break;

        case this.hrdcData.stagePriority.manualInvoiceToHrdc:
          route = "HrdcTpdiyClaimSubmitToHrdc";
          break;

        case this.hrdcData.stagePriority.submittedClaimToHrdc:
          route = "HrdcTpdiyClaimApprovedFromHrdc";
          break;

        case this.hrdcData.stagePriority.claimApprovedFromHrdc:
          route = "HrdcTpdiyPaymentReceivedFromHrdc";
          break;

        case this.hrdcData.stagePriority.creditNoteRequested:
          route = "CreditNoteDocumented";
          break;

        case this.hrdcData.stagePriority.creditNoteDocumented:
        case this.hrdcData.stagePriority.creditNoteGenerated:
          route = "HrdcTpdiyRefundEmailSubmission";
          break;

        case this.hrdcData.stagePriority.refundEmailSubmission:
          route = "HrdcTpdiyRefundEmailApproval1";
          break;

        case this.hrdcData.stagePriority.refundEmailApproval1:
          route = "HrdcTpdiyRefundEmailApproval2";
          break;

        case this.hrdcData.stagePriority.refundEmailApproval2:
        case this.hrdcData.stagePriority.refundEmailApToCimbRejected:
          route = "HrdcTpdiyRefundEmailArToAp";
          break;

        case this.hrdcData.stagePriority.refundEmailArToAp:
          route = "HrdcTpdiyRefundEmailApToCimb";
          break;

        case this.hrdcData.stagePriority.apSubmitRefundToCimb:
          route = "HrdcTpdiyEmailPaymentAdviceToClient";
          break;

        default:
          route = "ViewHrdcTpdiyApplication";
          break;
      }

      return route;
    },
    flagCol(flagVal) {
      if (flagVal) {
        return `<i aria-hidden="true" class="v-icon notranslate mdi mdi-flag theme--light red--text normal-flag"></i>`;
      } else {
        return `<i aria-hidden="true" class="v-icon notranslate mdi mdi-flag-outline theme--light normal-flag"></i>`;
      }
    },
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    updateFlagRemarkOnly() {
      const cell = this.flagDetails;
      const applicationUuid = cell.getValue();

      this.api.setMethod("POST");
      this.api.setUrl(
        `${
          this.$service[this.service.key]
        }/v1/en/console/application/update-flag-remark-only`
      );
      this.api.setParams({
        applicationUuid,
        flagRemarks: this.flagRemarks,
      });
      this.api.setCallbackCompleted((response) => {
        const { status, message } = response;

        if (status) {
          this.$store.dispatch("showMessage", {
            message,
            messageType: "success",
            timeout: 2000,
          });

          cell.getData().flagRemarks = this.flagRemarks;

          this.flagDialog = !this.flagDialog;
          this.clearErrorMsg();
        }

        if (!status) {
          this.showErrorMessage(response);
        }
      });
      this.api.fetch();
    },
    updateFlagAndRemark() {
      const cell = this.flagDetails;
      const data = cell.getData();
      const applicationUuid = cell.getValue();
      const flagVal = data.isFlag;

      this.api.setMethod("POST");
      this.api.setUrl(
        `${
          this.$service[this.service.key]
        }/v1/en/console/application/update-flag-status`
      );
      this.api.setParams({
        userUuid: this.auth.uuid, // Need to record who flag the case.
        applicationUuid,
        flagVal,
        flagRemarks: this.flagRemarks, // Add the remarks.
      });
      this.api.setCallbackCompleted((response) => {
        try {
          const { status, message } = response;
          if (status) {
            // Set new flag data.
            cell.getData().isFlag = !flagVal;
            cell.getData().flagRemarks = this.flagRemarks;

            // Toggle flag color.
            const htmlElement = this.flagCol(!flagVal);
            const newElement = document.createElement("div");
            newElement.insertAdjacentHTML("beforeend", htmlElement);
            cell.getElement().children[0].replaceWith(newElement);

            const iconElement = document.createElement("i");
            iconElement.setAttribute("aria-hidden", "true");
            iconElement.classList.add(
              "v-icon",
              "notranslate",
              "mdi",
              "mdi-flag",
              "theme--light",
              "red--text",
              "normal-flag"
            );

            const numberRow = cell
              .getRow()
              .getElement()
              .querySelector(".number-class");

            // If flaggged.
            if (numberRow) {
              if (!flagVal) {
                numberRow.insertAdjacentHTML("beforeend", " "); // Add space
                numberRow.insertAdjacentElement("beforeend", iconElement);
              } else {
                const flagIconElement = numberRow.querySelector(".normal-flag");
                flagIconElement.remove();
              }
            }

            this.$store.dispatch("showMessage", {
              message,
              messageType: "success",
            });

            this.flagDialog = !this.flagDialog;
            this.clearErrorMsg();
          }

          if (!status) {
            this.showErrorMessage(response);
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.api.fetch();
    },
    closeUpdateFlagAndRemarkDialog() {
      this.flagDialog = !this.flagDialog;
      this.flagRemarks = null;
      this.clearErrorMsg();
    },
    downloadData(fileType, rowsType) {
      this.isLoading = true;

      switch (fileType) {
        case "csv":
          this.downloadCsvData(rowsType);
          break;
        case "xlsx":
          this.downloadExcelData(rowsType);
          break;
        case "pdf":
          this.downloadPdfData(rowsType);
          break;
        default:
          break;
      }
    },
    downloadCsvData(rowsType) {
      this.$refs.tabulatorDataTable.tabulator.download(
        "csv",
        `${this.model.key}.csv`,
        {
          sheetName: `${this.model.key}`,
        },
        rowsType == "selected" ? "selected" : "all"
      );
      this.isLoading = false;
      // unchecked all the selected rows
      this.$refs.tabulatorDataTable.tabulator.deselectRow();
    },
    downloadExcelData(rowsType) {
      this.$refs.tabulatorDataTable.tabulator.download(
        "xlsx",
        `${this.model.key}.xlsx`,
        {
          sheetName: `${this.model.key}`,
        },
        rowsType == "selected" ? "selected" : "all"
      );
      this.isLoading = false;
      this.$refs.tabulatorDataTable.tabulator.deselectRow();
    },
    downloadPdfData(rowsType) {
      this.$refs.tabulatorDataTable.tabulator.download(
        "pdf",
        `${this.model.key}.pdf`,
        {
          orientation: "portrait", //set page orientation to portrait
          title: "Demo Product Details", //add title to report
        },
        rowsType == "selected" ? "selected" : "all"
      );
      this.isLoading = false;
      this.$refs.tabulatorDataTable.tabulator.deselectRow();
    },
    addNewApplication() {
      this.$store.commit("assignHrdcTpdiyData", {
        viewId: this.$route.query.viewId,
      });

      this.$router.push({
        name: "AddHrdcTpdiyApplication",
        query: {
          currentPageNo: this.currentPageNo,
          viewId: "all",
        },
      });
    },
    toggleCreateBulkApplicationDialog(val) {
      this.createBulkApplicationDialog = val;
    },
    executeBulkAction(action) {
      switch (action) {
        default:
        case "downloadBlankExcelTemplate":
          this.excel_template =
            this.EVENT_BULK_NEW_APPLICATION_TEMPLATE_FILE_URL;
          this.excel_template_name =
            this.EVENT_BULK_NEW_APPLICATION_TEMPLATE_NAME;
          break;

        case "downloadDummyExcelTemplate":
          this.excel_template =
            this.EVENT_BULK_NEW_APPLICATION_DUMMY_TEMPLATE_FILE_URL;
          this.excel_template_name =
            this.EVENT_BULK_NEW_APPLICATION_DUMMY_TEMPLATE_NAME;
          break;
      }

      this.downloadSampleTemplateFunc();
    },
    downloadSampleTemplateFunc() {
      this.showLoadingDialog();
      const streamUrl = `${
        this.$service[this.service.key]
      }/v1/en/console/application/get-attachment-blob`;
      this.$axios
        .get(streamUrl, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            filePath: this.excel_template,
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.excel_template_name);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          if ((error.response.status == 404) | true) {
            this.$store.dispatch("showMessage", {
              message:
                "Failed to Preview/Download attachment, kindly contact administrator",
              messageType: "error",
              timeout: 2000,
            });
          }
        })
        .finally(() => {
          this.hideLoadingDialog();
        });
    },
    copyContent(item) {
      navigator.clipboard.writeText(item.trim());

      this.$store.dispatch("showMessage", {
        message: "Copied Successfully!",
        messageType: "info",
        timeout: 2000,
      });
    },
    async readExcelData() {
      try {
        this.showLoadingDialog();
        this.excelDatas = [];
        this.errorDetected = false;
        this.clearErrorMsg();
        const file = this.formData.bulkApplicationAttachment;
        if (file) {
          const data = await file.arrayBuffer();

          const workbook = XLSX.read(data, { type: "array" });

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          const options = { header: 1, range: 1 }; // skips the first row
          const excelData = XLSX.utils.sheet_to_json(worksheet, options);

          // Check whether Group has count more than 9 (max allow participant per application=9, due to grant policy by eTris).
          const maxPaxGroupCountObj = {};

          // Process excel data.
          let i = 0;
          const excelDatas = [];
          for (const datas of excelData) {
            if (datas.length > 0) {
              const [
                group,
                participantName,
                participantNric,
                participantEmail,
                generalRemarks,
              ] = datas.slice(14, 19);

              // Check if group inside excelDatas, to assign first time data.
              const grpObj = excelDatas.find((item) => item.group == group);
              if (!grpObj) {
                i = 0;
                excelDatas.push({
                  group,
                  userUuid: this.auth.uuid,
                  hrdcType: this.hrdcType,
                  companyName: datas[0].toUpperCase(),
                  companySsmNo: datas[1].replaceAll("-", ""),
                  companyAddress: datas[2].toUpperCase(),
                  picName: datas[3],
                  picEmail: datas[4],
                  picContactNo: datas[5],
                  events: JSON.stringify(this.getEventFromExcel(datas[6])),
                  noOfPax: 0,
                  isPaymentMade: this.getPaymentMadeFromExcel(datas[7]),
                  bankAccHolderName: datas[8] ? datas[8].toUpperCase() : null,
                  bank: this.convertBankName(datas[9]),
                  bankAccNo: datas[10]
                    ? String(datas[10]).replaceAll("-", "")
                    : null,
                  ssRefundBankAccAtch: null,
                  paymentMethod: datas[11],
                  paymentAmount: datas[12],
                  claimAmount: datas[13],
                  participantName: {},
                  participantNric: {},
                  participantEmail: {},
                  generalRemarks,
                });
              }

              const totalGroupObj = excelDatas.find(
                (item) => item.group == group
              );
              totalGroupObj.noOfPax++;
              totalGroupObj.participantName[i] = participantName;
              totalGroupObj.participantNric[i] = participantNric;
              totalGroupObj.participantEmail[i] = participantEmail;

              if (!maxPaxGroupCountObj[group]) {
                maxPaxGroupCountObj[group] = 0;
              }

              maxPaxGroupCountObj[group]++;
              i++;
            }
          }

          if (Object.values(maxPaxGroupCountObj).some((value) => value > 9)) {
            this.errorField.bulkApplicationAttachment =
              "Detected pax with greater than 9 pax per application!";
            this.errorDetected = true;
            return false;
          }

          this.excelDatas = excelDatas;
          // console.log(this.excelDatas);
        }
      } catch (e) {
        console.log(e.message);
        this.errorField.bulkApplicationAttachment =
          "Please upload a valid excel template!";
        this.errorDetected = true;
      } finally {
        this.hideLoadingDialog();
      }
    },
    submit() {
      this.showLoadingDialog();

      const fd = new FormData();
      fd.append("datas", JSON.stringify(this.excelDatas));
      fd.append(
        "bulkApplicationAttachment",
        this.formData.bulkApplicationAttachment
      );
      fd.append("matchSsArr", this.matchSsArr);
      for (const bulkSsRefundBankAccAtch of this.bulkSsRefundBankAccAtch) {
        fd.append(
          `ssRefundBankAccAtch_${bulkSsRefundBankAccAtch.index}`,
          bulkSsRefundBankAccAtch.file
        );
      }
      fd.append(
        "EVENT_BULK_CASE_ASSIGNATION_PIC_EMAIL",
        this.EVENT_BULK_CASE_ASSIGNATION_PIC_EMAIL
      );
      fd.append(
        "EVENT_BULK_CASE_ASSIGNATION_PIC_NAME",
        this.EVENT_BULK_CASE_ASSIGNATION_PIC_NAME
      );

      this.apiCreateBulkApplications.setParams(fd);
      this.apiCreateBulkApplications.setCallbackCompleted((response) => {
        try {
          if (!response.status) {
            this.showErrorMessage(response);
            if (response.message) {
              this.$store.dispatch("showMessage", {
                message: response.message,
                messageType: "error",
                timeout: 2000,
              });
            }

            return false;
          }

          if (response.status) {
            this.createBulkApplicationDialog = false;
            this.$refs.tabulatorDataTable.callApiUrl(this.apiGetBrowseUrl());

            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.hideLoadingDialog();
        }
      });
      this.apiCreateBulkApplications.fetch();
    },
    getEventFromExcel(data) {
      const eventDataArr = data.split("#");
      const eventData = this.dbEventLists.find(
        (item) => item.id == eventDataArr[1]
      );
      if (!eventData) {
        this.errorField.bulkApplicationAttachment =
          "Invalid event detected, please copy the correct event!";

        this.errorDetected = true;
        return false;
      }

      return eventData;
    },
    uploadSsBankAtch(data) {
      // Assign bank account ss to respective data.
      const excelDataObj = this.excelDatas.find(
        (item) => item.group == data.grp
      );
      if (excelDataObj) {
        excelDataObj.ssRefundBankAccAtch =
          this.formData.ssRefundBankAccAtch[data.index];
      }

      this.bulkSsRefundBankAccAtch.push({
        index: data.index,
        file: this.formData.ssRefundBankAccAtch[data.index],
      });

      this.matchSsArr.push(data.index);
    },
    getPaymentMadeFromExcel(data) {
      switch (data) {
        default:
        case "Full Payment":
          return true;

        case "No Payment":
          return false;
      }
    },
    convertBankName(data) {
      if (!data) {
        return data;
      }

      const [, bankName] = data.split("-");
      return bankName;
    },
  },
};
</script>
