import LayoutNested from "@/components/layouts/LayoutNested.vue";

export default {
  path: `/service/:serviceKey`,
  component: LayoutNested,
  children: [
    {
      path: "application/view_tpdiy",
      name: "ViewHrdcTpdiyApplication",
      component: () =>
        import("@/services/hrdc_tpdiy/views/application/View.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyApplication",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/edit_tpdiy",
      name: "EditHrdcTpdiyApplication",
      component: () =>
        import("@/services/hrdc_tpdiy/views/application/Edit.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyApplication",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/add_tpdiy",
      name: "AddHrdcTpdiyApplication",
      component: () =>
        import("@/services/hrdc_tpdiy/views/application/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyApplication",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application_tpdiy",
      name: "HrdcTpdiyApplication",
      component: () =>
        import("@/services/hrdc_tpdiy/views/application/Index.vue"),
      props: {
        params: {
          modelKey: "hrdcTpdiyApplication",
        },
      },
    },
    {
      path: "application/email/client_tpdiy",
      name: "HrdcTpdiySendEmailToClient",
      component: () =>
        import("@/services/hrdc_tpdiy/views/email_client/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyEmailClient",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/revert_form_tpdiy",
      name: "HrdcTpdiyClientRevertForm",
      component: () => import("@/services/hrdc_tpdiy/views/revert/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyRevertForm",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/manual_invoice_tpdiy",
      name: "HrdcTpdiyManualInvoiceToHrdc",
      component: () =>
        import("@/services/hrdc_tpdiy/views/manual_invoice/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyManualInvoice",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/claim_submit_tpdiy",
      name: "HrdcTpdiyClaimSubmitToHrdc",
      component: () =>
        import("@/services/hrdc_tpdiy/views/claim_submit/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyClaimSubmit",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/claim_approved_tpdiy",
      name: "HrdcTpdiyClaimApprovedFromHrdc",
      component: () =>
        import("@/services/hrdc_tpdiy/views/claim_approved/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyClaimApproved",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/payment_received_tpdiy",
      name: "HrdcTpdiyPaymentReceivedFromHrdc",
      component: () =>
        import("@/services/hrdc_tpdiy/views/payment_received/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyPaymentReceived",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_submission_tpdiy",
      name: "HrdcTpdiyRefundEmailSubmission",
      component: () =>
        import("@/services/hrdc_tpdiy/views/refund_email_submission/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyRefundEmailSubmission",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_approval_1_tpdiy",
      name: "HrdcTpdiyRefundEmailApproval1",
      component: () =>
        import("@/services/hrdc_tpdiy/views/refund_email_approval_1/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyRefundEmailApproval1",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_approval_2_tpdiy",
      name: "HrdcTpdiyRefundEmailApproval2",
      component: () =>
        import("@/services/hrdc_tpdiy/views/refund_email_approval_2/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyRefundEmailApproval2",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_ar_to_ap_tpdiy",
      name: "HrdcTpdiyRefundEmailArToAp",
      component: () =>
        import("@/services/hrdc_tpdiy/views/refund_email_ar_to_ap/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyRefundEmailArToAp",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "refund_ap_to_cimb_tpdiy",
      name: "HrdcTpdiyRefundEmailApToCimb",
      component: () =>
        import(
          "@/services/hrdc_tpdiy/views/finance_ap_submit_refund_to_cimb/Index.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyRefundEmailApToCimb",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/email_payment_advice_to_client_tpdiy",
      name: "HrdcTpdiyEmailPaymentAdviceToClient",
      component: () =>
        import(
          "@/services/hrdc_tpdiy/views/email_payment_advice_to_client/Add.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyEmailPaymentAdviceToClient",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "submission_date_tpdiy",
      name: "HrdcTpdiyApplicationSubmissionDate",
      component: () =>
        import("@/services/hrdc_tpdiy/views/application/SubmissionDate.vue"),
      props: {
        params: {
          modelKey: "hrdcTpdiyApplicationSubmissionDate",
        },
      },
    },
    {
      path: "report_analysis_tpdiy",
      name: "HrdcTpdiyReportAnalysis",
      component: () =>
        import("@/services/hrdc_tpdiy/views/report/ReportAnalysis.vue"),
      props: {
        params: {
          modelKey: "HrdcTpdiyReportAnalysis",
        },
      },
    },
    {
      path: "general_info_tpdiy",
      name: "HrdcTpdiyAddGeneralInfo",
      component: () =>
        import("@/services/hrdc_tpdiy/views/general_info/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcTpdiyGeneralInfo",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "settings_tpdiy",
      name: "HrdcTpdiySettings",
      component: () => import("@/services/hrdc_tpdiy/views/settings/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcTpdiySettingsPrice",
        },
      },
    },
    {
      path: "settings/price_tpdiy",
      name: "HrdcTpdiySettingsPrice",
      component: () =>
        import("@/services/hrdc_tpdiy/views/settings/price/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcTpdiySettingsPrice",
        },
      },
    },
    {
      path: "settings/error_log_tpdiy",
      name: "HrdcTpdiySettingsErrorLog",
      component: () =>
        import("@/services/hrdc_tpdiy/views/settings/error_log/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcTpdiySettingsErrorLog",
        },
      },
    },
    {
      path: "settings/events_tpdiy",
      name: "HrdcTpdiySettingsEvents",
      component: () =>
        import("@/services/hrdc_tpdiy/views/settings/event/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcTpdiySettingsEvents",
        },
      },
    },
    {
      path: "settings/xero_tpdiy",
      name: "HrdcTpdiySettingsXeroToken",
      component: () =>
        import("@/services/hrdc_tpdiy/views/settings/xero_token/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcTpdiySettingsXero",
        },
      },
    },
    {
      path: "settings/attachment_tpdiy",
      name: "HrdcTpdiySettingsAttachment",
      component: () =>
        import("@/services/hrdc_tpdiy/views/settings/attachment/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcTpdiySettingsAttachment",
        },
      },
    },
    {
      path: "callback_tpdiy",
      component: () => import("@/services/hrdc_tpdiy/views/callback/Index.vue"),
      props: {
        params: {
          modelKey: "hrdcTpdiyApplication",
        },
      },
    },
    {
      path: "callback_xero_tpdiy",
      component: () =>
        import("@/services/hrdc_tpdiy/views/callback/xero/Index.vue"),
    },
  ],
};
