<template>
  <v-container fluid>
    <v-dialog v-model="alertEditApplicationDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          Edit Application
        </v-toolbar>

        <div class="pa-4">Are you sure to edit this application ?</div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="editApplication()"
          >
            Update
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="alertEditApplicationDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div>
      <v-toolbar>
        <v-btn icon class="hidden-xs-only" @click="redirectBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title v-if="!loadingDialog">
          <div class="d-flex align-center">
            {{ model.edit.name }}
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <span v-if="this.isHrdcSuperAdminRoles">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                class="hidden-xs-only"
                color="black"
                @click.stop="alertEditApplicationDialog = true"
              >
                <v-icon dark v-bind="attrs" v-on="on">
                  mdi-content-save
                </v-icon>
              </v-btn>
            </template>
            <span>Save</span>
          </v-tooltip>
        </span>
      </v-toolbar>
    </div>

    <v-card v-if="!loadingDialog" tile>
      <v-expansion-panels v-model="panel" multiple focusable>
        <v-row>
          <div class="col-md-6">
            <v-col>
              <!-- Company Details -->
              <v-expansion-panel>
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    COMPANY DETAILS
                    <v-icon color="white"> mdi-office-building-outline </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Company Name (Filled By Applicant):
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.oriCompanyName"
                          :error-messages="errorField.oriCompanyName"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters v-show="paymentChannelDisabled">
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Company Name (Vision):
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.oldCompanyName"
                          :error-messages="errorField.oldCompanyName"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters v-show="!paymentChannelDisabled">
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Company Name (Vision):
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-autocomplete
                          :items="companyData"
                          v-model="companyDetails.companyId"
                          :error-messages="errorField.companyId"
                          dense
                          filled
                          clearable
                          :disabled="paymentChannelDisabled"
                          hint="We kindly advice double check on patching Company Name with Vision data, as sometimes patching process may went wrong, change to the correct Company that exists in Vision or contact admin for support"
                          persistent-hint
                          @change="getCompanySubscriptionPeriod($event)"
                          :loading="visionCompanyIsLoading"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Company SSM No.: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.companySsmNo"
                          :error-messages="errorField.companySsmNo"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Company Address: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.companyAddress"
                          :error-messages="errorField.companyAddress"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row
                      no-gutters
                      class="align-items-center"
                      v-if="!paymentChannelDisabled"
                    >
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Subscription Period:
                        </span>
                      </v-col>
                      <v-col cols="3" class="px-0">
                        <v-menu
                          ref="menuSubscriptionStartDate"
                          v-model="menuSubscriptionStartDate"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              filled
                              v-model="
                                companyDetails.finalSubscriptionStartDate
                              "
                              :error-messages="
                                errorField.finalSubscriptionStartDate
                              "
                              label="Subscription Start Date (YYYY-MM-DD)"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="companyDetails.finalSubscriptionStartDate"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="menuSubscriptionStartDate = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.menuSubscriptionStartDate.save(date)
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      to
                      <v-col cols="3">
                        <v-menu
                          ref="menuSubscriptionEndDate"
                          v-model="menuSubscriptionEndDate"
                          :close-on-content-click="false"
                          :return-value.sync="date"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              filled
                              v-model="companyDetails.finalSubscriptionEndDate"
                              :error-messages="
                                errorField.finalSubscriptionEndDate
                              "
                              label="Subscription End Date (YYYY-MM-DD)"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="companyDetails.finalSubscriptionEndDate"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="menuSubscriptionEndDate = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menuSubscriptionEndDate.save(date)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>

                    <hr />

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Client PIC Name: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-combobox
                          filled
                          dense
                          multiple
                          small-chips
                          :items="companyDetails.picNameItems"
                          v-model="companyDetails.picName"
                          :error-messages="errorField.picName"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              small
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                              <v-icon
                                small
                                @click="data.parent.selectItem(data.item)"
                              >
                                $delete
                              </v-icon>
                            </v-chip>
                          </template>
                          <template v-slot:append>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on"> mdi-information </v-icon>
                              </template>
                              Press <kbd>enter/tab</kbd> to insert multiple
                              email
                            </v-tooltip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Client PIC Email: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-combobox
                          filled
                          dense
                          multiple
                          small-chips
                          :items="companyDetails.picEmailItems"
                          v-model="companyDetails.picEmail"
                          :error-messages="errorField.picEmail"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              small
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                              <v-icon
                                small
                                @click="data.parent.selectItem(data.item)"
                              >
                                $delete
                              </v-icon>
                            </v-chip>
                          </template>
                          <template v-slot:append>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on"> mdi-information </v-icon>
                              </template>
                              Press <kbd>enter/tab</kbd> to insert multiple
                              email
                            </v-tooltip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Client PIC Contact No:
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-combobox
                          filled
                          dense
                          multiple
                          small-chips
                          :items="companyDetails.picContactNoItems"
                          v-model="companyDetails.picContactNo"
                          :error-messages="errorField.picContactNo"
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              small
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item }}
                              <v-icon
                                small
                                @click="data.parent.selectItem(data.item)"
                              >
                                $delete
                              </v-icon>
                            </v-chip>
                          </template>
                          <template v-slot:append>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on"> mdi-information </v-icon>
                              </template>
                              Press <kbd>enter/tab</kbd> to insert multiple
                              email
                            </v-tooltip>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>

                    <hr />

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Bank Name: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.bankUuid"
                          :error-messages="errorField.bankUuid"
                          clearable
                          :disabled="paymentChannelDisabled"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Bank Account No: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.bankAccNo"
                          :error-messages="errorField.bankAccNo"
                          :disabled="paymentChannelDisabled"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Bank Account Holder Name:
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          filled
                          dense
                          v-model="companyDetails.bankAccHolderName"
                          :error-messages="errorField.bankAccHolderName"
                          :disabled="paymentChannelDisabled"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-col>
          </div>
          <div class="col-md-6">
            <v-col>
              <!-- Application Details -->
              <v-expansion-panel>
                <!-- <v-expansion-panel-header> -->
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    APPLICATION DETAILS
                    <v-icon color="white">
                      mdi-application-edit-outline
                    </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Programme Name: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="applicationDetails.programmeName"
                          :error-messages="errorField.programmeName"
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          taxPOD Pricing Filled By Applicant:
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          disabled
                          v-model="
                            companyDetails.taxpodPricingFilledByApplicant
                          "
                          :error-messages="
                            errorField.taxpodPricingFilledByApplicant
                          "
                          filled
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> taxPOD Pricing : </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="companyDetails.taxpodPricing"
                          :error-messages="errorField.taxpodPricing"
                          filled
                          dense
                          clearable
                          hide-spin-buttons
                          type="number"
                          label="etc: 2888, 2388, 1998, ..."
                          @input="updateProgrammeName()"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> taxPOD Type : </span>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          v-model="applicationDetails.taxpodType"
                          :items="[
                            {
                              text: 'New Sale',
                              value: 1,
                            },
                            {
                              text: 'Renewal',
                              value: 2,
                            },
                          ]"
                          dense
                          filled
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Payment Channel : </span>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          @change="changePaymentChannel()"
                          v-model="applicationDetails.paymentChannel"
                          :items="[
                            {
                              text: 'Pay & Refund',
                              value: 1,
                            },
                            {
                              text: 'Claim Basis',
                              value: 2,
                            },
                          ]"
                          dense
                          filled
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row no-gutters v-if="!paymentChannelDisabled">
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Payment Mode : </span>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          v-model="applicationDetails.paymentMode"
                          :error-messages="errorField.paymentMode"
                          :items="paymentMode"
                          dense
                          filled
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Screenshot of Bank Account Details for Refund:
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <div
                          v-cloak
                          @drop.prevent="addDropFile($event, 'ssOfBankAcc')"
                          @dragover.prevent
                          class="w-100"
                        >
                          <v-file-input
                            :prepend-icon="null"
                            prepend-inner-icon="mdi-paperclip"
                            dense
                            filled
                            small-chips
                            v-model="applicationDetails.ssOfBankAcc"
                            :error-messages="errorField.ssOfBankAcc"
                            :truncate-length="1000"
                            :disabled="paymentChannelDisabled"
                          ></v-file-input>
                        </div>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2">
                          Is Application Duplicate:
                        </span>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          v-model="applicationDetails.isApplicationDuplicate"
                          :items="[
                            {
                              text: 'Yes',
                              value: true,
                            },
                            {
                              text: 'No',
                              value: false,
                            },
                          ]"
                          dense
                          filled
                        ></v-select>
                      </v-col>
                    </v-row>

                    <hr />

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Responder Email: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-autocomplete
                          v-model="applicationDetails.referByEmail"
                          :items="csDetails.salespersonData"
                          dense
                          filled
                          label="Responder Email"
                          @change="appendSalespersonName()"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Responder Name: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="applicationDetails.referByName"
                          :error-messages="errorField.referByName"
                          filled
                          dense
                          disabled
                          label="Responder Name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Enrollment Details -->
              <v-expansion-panel v-if="paymentChannelDisabled">
                <!-- <v-expansion-panel-header> -->
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    ENROLLMENT DETAILS
                    <v-icon color="white"> mdi-form-select </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: HRDC_CARD_COLOR }"
                    outlined
                  >
                    <div>
                      <v-row
                        class="d-flex justify-space-between"
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                      >
                        <v-col cols="2">
                          <span class="text-subtitle-2"> First Name: </span>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="enrollmentDetails.firstNameAccEnrollment"
                            :error-messages="errorField.firstNameAccEnrollment"
                            filled
                            dense
                            clearable
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>

                        <v-col cols="2">
                          <span class="text-subtitle-2"> Last Name: </span>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="enrollmentDetails.lastNameAccEnrollment"
                            :error-messages="errorField.lastNameAccEnrollment"
                            filled
                            dense
                            clearable
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row
                        class="d-flex justify-space-between"
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                      >
                        <v-col cols="2">
                          <span class="text-subtitle-2"> Contact No: </span>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="enrollmentDetails.contactNoAccEnrollment"
                            :error-messages="errorField.contactNoAccEnrollment"
                            filled
                            dense
                            clearable
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>

                        <v-col cols="2">
                          <span class="text-subtitle-2"> Email: </span>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="enrollmentDetails.emailAccEnrollment"
                            :error-messages="errorField.emailAccEnrollment"
                            filled
                            dense
                            clearable
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>

                    <div
                      v-for="(inputData, index) in dynamicEnrollmentDetails"
                      :key="index"
                    >
                      <hr />

                      <v-row
                        class="d-flex justify-space-between"
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                      >
                        <v-col cols="2">
                          <span class="text-subtitle-2"> First Name: </span>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="inputData.firstNameAccEnrollment"
                            :rules="[rules.required]"
                            filled
                            dense
                            clearable
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>

                        <v-col cols="2">
                          <span class="text-subtitle-2"> Last Name: </span>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="inputData.lastNameAccEnrollment"
                            :rules="[rules.required]"
                            filled
                            dense
                            clearable
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row
                        class="d-flex justify-space-between"
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                      >
                        <v-col cols="2">
                          <span class="text-subtitle-2"> Contact No: </span>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="inputData.contactNoAccEnrollment"
                            :rules="[rules.required]"
                            filled
                            dense
                            clearable
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>

                        <v-col cols="2">
                          <span class="text-subtitle-2"> Email: </span>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            v-model="inputData.emailAccEnrollment"
                            :rules="[rules.required, rules.email]"
                            filled
                            dense
                            clearable
                            hide-spin-buttons
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>

                    <div class="d-flex justify-content-end">
                      <v-btn
                        class="mx-2"
                        fab
                        dark
                        small
                        color="#063058"
                        @click="addEnrollmentDetails()"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                      <v-btn
                        v-if="dynamicEnrollmentDetails.length > 0"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="error"
                        @click="removeEnrollmentDetails()"
                      >
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                    </div>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <!-- Customer Service Details -->
              <v-expansion-panel>
                <!-- <v-expansion-panel-header> -->
                <v-expansion-panel-header
                  style="color: white"
                  class="expansion-details-gradient"
                >
                  <span class="text-h6">
                    CUSTOMER SERVICE DETAILS
                    <v-icon color="white"> mdi-face-agent </v-icon>
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4">
                  <v-card
                    class="pa-3"
                    :style="{ backgroundColor: HRDC_CARD_COLOR }"
                    outlined
                  >
                    <v-row no-gutters>
                      <v-col cols="4">
                        <span class="text-subtitle-2"> Customer Service: </span>
                      </v-col>
                      <v-col cols="8">
                        <v-autocomplete
                          dense
                          filled
                          label="Customer Service"
                          v-model="csDetails.customerService"
                          :error-messages="errorField.customerService"
                          :items="csDetails.customerServiceData"
                          multiple
                          small-chips
                        >
                          <template v-slot:selection="data">
                            <v-chip
                              small
                              :key="JSON.stringify(data.item)"
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              :disabled="data.disabled"
                              @click:close="data.parent.selectItem(data.item)"
                            >
                              {{ data.item.text }}
                              <v-icon
                                small
                                @click="data.parent.selectItem(data.item)"
                              >
                                $delete
                              </v-icon>
                            </v-chip>
                          </template>

                          <template v-slot:append>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on"> mdi-information </v-icon>
                              </template>
                              Press <kbd>enter/tab</kbd> to insert multiple
                              email
                            </v-tooltip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-col>
          </div>
        </v-row>
      </v-expansion-panels>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>

    <v-skeleton-loader
      v-if="loadingDialog"
      :loading="loadingDialog"
      class="mx-auto rounded-0"
      type="table-heading, card, table-heading, card, table-heading, card, table-heading, card"
    >
    </v-skeleton-loader>

    <v-dialog v-model="loadingBar" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
    paymentChannelDisabled() {
      let disabled = false;
      if (this.applicationDetails.paymentChannel == 2) {
        disabled = true;
      }

      return disabled;
    },
    visionCompanyIsLoading() {
      if (this.companyData) {
        return false;
      }

      return true;
    },
  },
  props: ["params"],
  data: () => ({
    apiGetApplicationDetails: new Api(),
    apiEditApplication: new Api(),
    apiGetCompanyList: new Api(),
    apiGetCsAndSalespersonList: new Api(),
    model: new Model(),
    hrdcData: new HrdcData(),
    service: new Service(),
    allStages: [],
    loadingDialog: false,
    loadingBar: false,
    panel: [0, 1, 2, 3, 4, 5, 6, 7],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    menu2: false,
    companyDetails: {
      companyUuid: null,
      companyId: null,
      oriCompanyName: null,
      oldCompanyName: null,
      companySsmNo: null,
      companyAddress: null,
      picName: [],
      picNameItems: [],
      picEmail: [],
      picEmailItems: [],
      picContactNo: [],
      picContactNoItems: [],
      bankUuid: null,
      bankAccNo: null,
      bankAccHolderName: null,
      taxpodPricingFilledByApplicant: null,
      taxpodPricing: null,
      oriBankUuid: null,
      oriBankAccNo: null,
      oriBankAccHolderName: null,
      finalSubscriptionStartDate: null,
      finalSubscriptionEndDate: null,
    },
    errorField: {
      companyUuid: null,
      companyId: null,
      oriCompanyName: null,
      oldCompanyName: null,
      companySsmNo: null,
      companyAddress: null,
      picName: null,
      picContactNo: null,
      bankUuid: null,
      bankAccNo: null,
      bankAccHolderName: null,
      customerService: null,
      taxpodPricingFilledByApplicant: null,
      taxpodPricing: null,
      programmeName: null,
      paymentMode: null,
      referByName: null,
      referByEmail: null,
      subscriptionStartDate: null,
      finalSubscriptionStartDate: null,
      finalSubscriptionEndDate: null,
    },
    salespersonDetails: {
      salespersonName: null,
      salespersonEmail: null,
      salespersonContactNo: null,
    },
    csDetails: {
      customerService: null,
      customerServiceData: [],
      salespersonData: [],
    },
    applicationDetails: {
      paymentChannel: null,
      taxpodType: null,
      ssOfBankAcc: null,
      oriSsOfBankAcc: null,
      programmeName: null,
      paymentMode: null,
      referByName: null,
      referByEmail: null,
      isApplicationDuplicate: false,
    },
    enrollmentDetails: {
      firstNameAccEnrollment: null,
      lastNameAccEnrollment: null,
      contactNoAccEnrollment: null,
      emailAccEnrollment: null,
    },
    alertEditApplicationDialog: false,
    visionApiUrl: null,
    visionApiAuthorization: null,
    companyData: null,
    isPricingCorrect: false,
    isPaymentMade: null,
    taxpodType: null,
    dynamicEnrollmentDetails: [],
    oriDynamicEnrollmentDetails: [],
    dynamicEnrollmentCount: 0,
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    paymentMode: ["Stripe", "Credit card", "Bank transfer", "Cheque"],
    salespersonData: [],
    menuSubscriptionStartDate: false,
    menuSubscriptionEndDate: false,
  }),
  async created() {
    this.loadingDialog = !this.loadingDialog;
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    this.allStages = await this.hrdcData.allStages;
    this.visionApiUrl = process.env.VUE_APP_API_URL_VISION;
    console.log(process.env.VUE_APP_ENV)
    this.visionApiAuthorization =
      this.VUE_APP_API_AUTHORIZATION_VISION;
    this.rolesAssignation(this.auth.roles);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiGetApplicationDetails: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-application-details`,
        method: "post",
        params: {
          applicationUuid: this.$route.query._ausk,
          type: "MULTIPLE_ENROLLMENT_DETAILS",
        },
      },
      apiEditApplication: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/edit-application`,
        method: "post",
      },
      apiGetCompanyList: {
        url: `${this.visionApiUrl}/special-access/companies`,
        headers: {
          Authorization: this.visionApiAuthorization,
        },
      },
      apiGetCsAndSalespersonList: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-cs-and-salesperson-list`,
        method: "post",
        params: {
          internalRoleSlugs: ["taxpod-customer-service"],
        },
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
      if (apiDetails[api].headers !== undefined) {
        this[api].setHeaders(apiDetails[api].headers);
      }
    }

    // Get company data.
    this.apiGetCompanyList.setCallbackCompleted((response) => {
      try {
        if (response.status == "success") {
          const { data } = response;

          const keys = Object.keys(data);
          const reversedKeys = keys.reverse();
          const companyDataV2 = {};
          reversedKeys.forEach((key) => {
            companyDataV2[" " + key] = data[key]; // Do not remove the space!!!
          });

          const companyData = [];
          for (const key in companyDataV2) {
            let company = companyDataV2[key][0];
            if (company.company_name) {
              companyData.push({
                text: company.company_name,
                value: key.trim(),
                subscription_start_date: company.subscription_created_at,
                subscription_end_date: company.subscription_expired_at,
              });
            }
          }

          this.companyData = companyData;
        }
      } catch (err) {
        console.log(err);
      }
    });
    this.apiGetCompanyList.fetch();

    this.apiGetApplicationDetails.fetch();
    this.apiGetApplicationDetails.setCallbackCompleted((response) => {
      try {
        const datas = response.data;

        if (!response.status) {
          this.$store.commit("assignDeveloperData", {
            paginationPageNo: this.$route.query.currentPageNo,
          });

          this.$router.push({
            name: "HrdcApplication",
            query: {
              viewId: this.$store.state.hrdc.data.viewId,
              tabIndex: 0,
            },
          });
          this.$store.dispatch("showMessage", {
            message: datas,
            messageType: "error",
            timeout: 2000,
          });
          return;
        }

        // Just take first row to display basic info.
        const data = response.data[0];

        if (!this.staticTaxpodPricing.includes(data.totalPrice)) {
          this.isPricingCorrect = true;
        }

        // Company details.
        this.companyDetails = this.transformCompanyDetails(data, response);

        // Application Details.
        this.applicationDetails.paymentChannel = data.paymentChannel;
        this.applicationDetails.taxpodType = data.taxpodType;
        this.applicationDetails.programmeName = data.programmeName;
        this.applicationDetails.paymentMode = data.paymentMode;
        this.applicationDetails.referByName = data.referByName;
        this.applicationDetails.referByEmail = data.referByEmail;
        this.applicationDetails.isApplicationDuplicate =
          data.isApplicationDuplicate;

        for (const item of response.attachments) {
          // Create a Blob object from the data
          const blob = new Blob([new Uint8Array(item.dataArray)]);

          // Create the File object
          const file = new File([blob], item.fileName, {
            type: `application/${item.extension}`,
          });

          if (item.type == "Screenshot of Bank Account Details for Refund") {
            this.applicationDetails.ssOfBankAcc = file;
            this.applicationDetails.oriSsOfBankAcc = file;
          }
        }

        // Salesperson details.
        this.salespersonDetails.salespersonName = data.salesperson.name ?? "-";
        this.salespersonDetails.salespersonEmail =
          data.salesperson.email ?? "-";
        this.salespersonDetails.salespersonContactNo =
          data.salesperson.mobile ?? "-";

        // Customer Service details.
        const csArrTmp = [];
        if (response.applicationCs.length > 0) {
          for (const customerService of response.applicationCs) {
            csArrTmp.push({
              text: customerService.csEmail,
              value: customerService.csUuid,
            });
          }
          this.csDetails.customerService = csArrTmp;
        }

        // Enrollment details.
        this.enrollmentDetails.firstNameAccEnrollment =
          data.firstNameAccEnrollment;
        this.enrollmentDetails.lastNameAccEnrollment =
          data.lastNameAccEnrollment;
        this.enrollmentDetails.contactNoAccEnrollment =
          data.contactNoAccEnrollment;
        this.enrollmentDetails.emailAccEnrollment = data.emailAccEnrollment;

        // Dynamic Enrollment details.
        if (response.dynamicEnrollmentDetails.length > 0) {
          this.dynamicEnrollmentDetails = response.dynamicEnrollmentDetails.map(
            (enrollmentDetail) => ({
              firstNameAccEnrollment: enrollmentDetail.firstNameAccEnrollment,
              lastNameAccEnrollment: enrollmentDetail.lastNameAccEnrollment,
              contactNoAccEnrollment: enrollmentDetail.contactNoAccEnrollment,
              emailAccEnrollment: enrollmentDetail.emailAccEnrollment,
            })
          );

          this.oriDynamicEnrollmentDetails = [...this.dynamicEnrollmentDetails];
        }

        // Check whether company exists in vision or not.
        this.isPaymentMade = data.isPaymentMade;
        this.taxpodType = data.taxpodType;

        // Get CS Listing.
        this.apiGetCsAndSalespersonList.setCallbackCompleted((response) => {
          try {
            const { data, status, salespersonData } = response;

            if (status) {
              // Handle if sso server down, just display empty items for cs lists.
              if (data) {
                for (const item of data) {
                  this.csDetails.customerServiceData.push({
                    text: item.email,
                    value: item.uuid,
                  });
                }
              }

              if (salespersonData) {
                this.salespersonData = salespersonData;
                for (const salespersonItem of salespersonData) {
                  this.csDetails.salespersonData.push(salespersonItem.email);
                }
              }
            }
            this.loadingDialog = !this.loadingDialog;
          } catch (err) {
            console.log(err);
          }
        });
        this.apiGetCsAndSalespersonList.fetch();
      } catch (err) {
        console.log(err);
      }
    });
  },
  mounted() {},
  methods: {
    formatNumber(number) {
      if (number) {
        return number.toFixed(2);
      }
    },
    isArrayofObjects(arr) {
      if (arr !== null) {
        return (
          arr.length > 0 &&
          typeof arr[0] === "object" &&
          arr[0] !== null &&
          !Array.isArray(arr[0])
        );
      }
    },
    changePaymentChannel() {
      this.clearErrorMsg();
      if (this.applicationDetails.paymentChannel == 1) {
        this.companyDetails.bankUuid = this.companyDetails.oriBankUuid;
        this.companyDetails.bankAccNo = this.companyDetails.oriBankAccNo;
        this.companyDetails.bankAccHolderName =
          this.companyDetails.oriBankAccHolderName;
        this.applicationDetails.ssOfBankAcc =
          this.applicationDetails.oriSsOfBankAcc;
      } else {
        this.companyDetails.bankUuid = null;
        this.companyDetails.bankAccNo = null;
        this.companyDetails.bankAccHolderName = null;
        this.applicationDetails.ssOfBankAcc = null;
      }
    },
    updateProgrammeName() {
      let taxpodPricing = Number(this.companyDetails.taxpodPricing);

      // Get base pricing.
      taxpodPricing = this.getTaxpodBasePricing(taxpodPricing);
      const programmeNameObj = this.staticTaxpodData.find(
        (item) => item.price == taxpodPricing
      );
      if (programmeNameObj) {
        this.applicationDetails.programmeName = `taxPOD Masterclass ${programmeNameObj.flyer}`;
      }
    },
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = response.data[key];
      }
    },
    addEnrollmentDetails() {
      if (this.dynamicEnrollmentCount < 0) {
        this.dynamicEnrollmentCount = 1;
      }

      // Find whether got index of dynamic enrollment details or not.
      const enrollmentDetail =
        this.oriDynamicEnrollmentDetails[this.dynamicEnrollmentCount - 1];

      this.dynamicEnrollmentDetails.push({
        firstNameAccEnrollment:
          enrollmentDetail?.firstNameAccEnrollment || null,
        lastNameAccEnrollment: enrollmentDetail?.lastNameAccEnrollment || null,
        contactNoAccEnrollment:
          enrollmentDetail?.contactNoAccEnrollment || null,
        emailAccEnrollment: enrollmentDetail?.emailAccEnrollment || null,
      });

      this.dynamicEnrollmentCount++;
    },
    removeEnrollmentDetails() {
      this.dynamicEnrollmentDetails.pop();
      this.dynamicEnrollmentCount--;
    },
    // Create function to capture original company data.
    transformCompanyDetails(data, response) {
      return {
        companyUuid: data.companyUuid,
        companyId: `${data.oldCompanyId}`,
        oriCompanyName: `${data.oriCompanyName}`,
        oldCompanyName: `${data.oldCompanyName}`,
        companySsmNo: data.oldSsmNo,
        companyAddress: data.oldAddress,
        picName: response.companyPicData
          .map((item) => item.picName)
          .filter((item) => item !== null),
        picNameItems: response.companyPicData
          .map((item) => item.picName)
          .filter((item) => item !== null),
        picEmail: response.companyPicData
          .map((item) => item.picEmail)
          .filter((item) => item !== null),
        picEmailItems: response.companyPicData
          .map((item) => item.picEmail)
          .filter((item) => item !== null),
        picContactNo: response.companyPicData
          .map((item) => item.picContactNo)
          .filter((item) => item !== null),
        picContactNoItems: response.companyPicData
          .map((item) => item.picContactNo)
          .filter((item) => item !== null),
        bankUuid: data.bankUuid ?? null,
        bankAccNo:
          data.oldBankAccount == null || data.oldBankAccount == "null"
            ? null
            : data.oldBankAccount.replace(/\s+/g, ""),
        bankAccHolderName:
          data.oldBankAccountHolderName == null || data.oldBankAccount == "null"
            ? null
            : data.oldBankAccountHolderName,
        oriBankUuid: data.bankUuid ?? null,
        oriBankAccNo:
          data.oldBankAccount == null || data.oldBankAccount == "null"
            ? null
            : data.oldBankAccount.replace(/\s+/g, ""),
        oriBankAccHolderName:
          data.oldBankAccountHolderName == null || data.oldBankAccount == "null"
            ? null
            : data.oldBankAccountHolderName,
        taxpodPricingFilledByApplicant: data.oriPrice,
        taxpodPricing: data.totalPrice,
        finalSubscriptionStartDate: data.subscriptionStartDate,
        finalSubscriptionEndDate: data.subscriptionEndDate,
      };
    },
    checkRoleAllows(checkRole) {
      return this.auth.roles.some((role) => role.slug.includes(checkRole));
    },
    appendSalespersonName() {
      // Get the salesperson name.
      const salespersonObj = this.salespersonData.find(
        (item) => item.email == this.applicationDetails.referByEmail
      );

      this.applicationDetails.referByName = salespersonObj.name;
    },
    getCompanySubscriptionPeriod($event) {
      const companyTaxpodSubscriptionData = this.companyData.find(
        (item) => item.value == $event
      );

      if (companyTaxpodSubscriptionData) {
        this.companyDetails.finalSubscriptionStartDate = moment(
          companyTaxpodSubscriptionData.subscription_start_date
        ).format("YYYY-MM-DD");
        this.companyDetails.finalSubscriptionEndDate = moment(
          companyTaxpodSubscriptionData.subscription_end_date
        ).format("YYYY-MM-DD");
      }
    },
    redirectBack() {
      let route = "HrdcApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc.data.viewId,
          _ausk: this.$route.query._ausk,
        },
      });
    },
    editApplication() {
      this.loadingBar = !this.loadingBar;
      // Get the selected company object based on the companyId
      let company = this.companyData.find(
        (company) => company.value === this.companyDetails.companyId
      );

      let companyNameInVision = null;
      let companyName = this.companyDetails.oriCompanyName;
      let subscriptionStartDate = null;
      let subscriptionEndDate = null;

      if (company !== undefined) {
        companyNameInVision = company.text;
        subscriptionStartDate = company.subscription_start_date;
        subscriptionEndDate = company.subscription_end_date;
      }

      if (this.isArrayofObjects(this.csDetails.customerService)) {
        this.csDetails.customerService = this.csDetails.customerService.map(
          (item) => item.value
        );
      }

      // Clear bank & screenshot if Claim Basis.
      if (this.applicationDetails.paymentChannel == 2) {
        this.companyDetails.bankUuid = null;
        this.companyDetails.bankAccNo = null;
        this.companyDetails.bankAccHolderName = null;
      }

      const fd = new FormData();
      fd.append("userUuid", this.auth.uuid);
      fd.append("applicationUuid", this.$route.query._ausk);
      fd.append("companyNameInVision", companyNameInVision);
      fd.append("companyUuid", this.companyDetails.companyUuid);
      fd.append("companySsmNo", this.companyDetails.companySsmNo);
      fd.append("companyId", this.companyDetails.companyId);
      fd.append("companyName", companyName);
      fd.append("oldCompanyName", this.companyDetails.oldCompanyName);
      fd.append("subscriptionStartDate", subscriptionStartDate);
      fd.append("subscriptionEndDate", subscriptionEndDate);
      fd.append("companyAddress", this.companyDetails.companyAddress);
      fd.append("picName", this.companyDetails.picName.join(","));
      fd.append("picEmail", this.companyDetails.picEmail.join(","));
      fd.append("picContactNo", this.companyDetails.picContactNo.join(","));
      fd.append("bankUuid", this.companyDetails.bankUuid);
      fd.append("bankAccNo", this.companyDetails.bankAccNo);
      fd.append("bankAccHolderName", this.companyDetails.bankAccHolderName);
      fd.append("customerService", this.csDetails.customerService);
      fd.append("taxpodPricing", this.companyDetails.taxpodPricing);
      fd.append("paymentChannel", this.applicationDetails.paymentChannel);
      fd.append("taxpodType", this.applicationDetails.taxpodType);
      fd.append("ssOfBankAcc", this.applicationDetails.ssOfBankAcc);
      fd.append(
        "firstNameAccEnrollment",
        this.enrollmentDetails.firstNameAccEnrollment
      );
      fd.append(
        "lastNameAccEnrollment",
        this.enrollmentDetails.lastNameAccEnrollment
      );
      fd.append(
        "contactNoAccEnrollment",
        this.enrollmentDetails.contactNoAccEnrollment
      );
      fd.append(
        "emailAccEnrollment",
        this.enrollmentDetails.emailAccEnrollment
      );
      fd.append(
        "dynamicEnrollmentDetails",
        JSON.stringify(this.dynamicEnrollmentDetails)
      );
      fd.append("programmeName", this.applicationDetails.programmeName);
      fd.append("paymentMode", this.applicationDetails.paymentMode);
      fd.append("referByName", this.applicationDetails.referByName);
      fd.append("referByEmail", this.applicationDetails.referByEmail);
      fd.append(
        "isApplicationDuplicate",
        this.applicationDetails.isApplicationDuplicate
      );
      fd.append(
        "finalSubscriptionStartDate",
        this.companyDetails.finalSubscriptionStartDate
      );
      fd.append(
        "finalSubscriptionEndDate",
        this.companyDetails.finalSubscriptionEndDate
      );

      this.apiEditApplication.setParams(fd);
      this.apiEditApplication.setCallbackCompleted((response) => {
        try {
          this.loadingBar = !this.loadingBar;
          this.clearErrorMsg();
          if (!response.status) {
            this.showErrorMessage(response);
            this.$store.dispatch("showMessage", {
              message: "Please fill-in all the fields",
              messageType: "error",
              timeout: 2000,
            });
          }

          if (response.status) {
            let route = "HrdcApplication";
            if (this.params.backTo) {
              route = this.params.backTo;
            }

            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: route,
              params: {
                modelKey: this.model.key,
                backTo: "",
              },
              query: {
                viewId: this.$store.state.hrdc.data.viewId,
                _ausk: this.$route.query._ausk,
              },
            });

            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
            return;
          }
          this.loadingBar = !this.loadingBar;
        } catch (err) {
          console.log(err);
        }
      });
      this.apiEditApplication.fetch();

      this.loadingBar = !this.loadingBar;
      this.alertEditApplicationDialog = !this.alertEditApplicationDialog;
    },
  },
};
</script>

<style scope>
.v-tabs-bar.v-tabs-bar--is-mobile .v-tab,
.v-tabs-bar .v-tab {
  margin-left: 0px !important;
}
.v-messages__message {
  color: red;
}

.expansion-details-gradient {
  background-color: rgba(6, 48, 88, 1);
  /* background-image: linear-gradient(
    to right,
    rgba(6, 48, 88, 1),
    rgba(241, 87, 43, 1)
  ); */
}

.v-expansion-panel-content__wrap {
  padding: 0 10px 16px;
}

.theme--light.v-expansion-panels.v-expansion-panels--focusable
  .v-expansion-panel-header--active::before {
  opacity: 0;
}
</style>