import LayoutNested from "@/components/layouts/LayoutNested.vue";

export default {
  path: `/service/:serviceKey`,
  component: LayoutNested,
  children: [
    {
      path: "application/view",
      name: "ViewHrdcApplication",
      component: () => import("@/services/hrdc/views/application/View.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcApplication",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/edit",
      name: "EditHrdcApplication",
      component: () => import("@/services/hrdc/views/application/Edit.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcApplication",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application",
      name: "HrdcApplication",
      component: () => import("@/services/hrdc/views/application/Index.vue"),
      props: {
        params: {
          modelKey: "hrdcApplication",
        },
      },
    },
    {
      path: "application/email/client",
      name: "SendHrdcEmailToClient",
      component: () => import("@/services/hrdc/views/email_client/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEmailClient",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/revert_form",
      name: "ClientRevertForm",
      component: () => import("@/services/hrdc/views/revert/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcRevertForm",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/manual_invoice",
      name: "ManualInvoiceToHrdc",
      component: () => import("@/services/hrdc/views/manual_invoice/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcManualInvoice",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/claim_submit",
      name: "ClaimSubmitToHrdc",
      component: () => import("@/services/hrdc/views/claim_submit/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcClaimSubmit",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/claim_approved",
      name: "ClaimApprovedFromHrdc",
      component: () => import("@/services/hrdc/views/claim_approved/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcClaimApproved",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/payment_received",
      name: "PaymentReceivedFromHrdc",
      component: () => import("@/services/hrdc/views/payment_received/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcPaymentReceived",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_submission",
      name: "RefundEmailSubmission",
      component: () =>
        import("@/services/hrdc/views/refund_email_submission/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcRefundEmailSubmission",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_approval_1",
      name: "RefundEmailApproval1",
      component: () =>
        import("@/services/hrdc/views/refund_email_approval_1/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcRefundEmailApproval1",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_approval_2",
      name: "RefundEmailApproval2",
      component: () =>
        import("@/services/hrdc/views/refund_email_approval_2/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcRefundEmailApproval2",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/refund_email_ar_to_ap",
      name: "RefundEmailArToAp",
      component: () =>
        import("@/services/hrdc/views/refund_email_ar_to_ap/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcRefundEmailArToAp",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "refund-ap-to-cimb",
      name: "RefundEmailApToCimb",
      component: () =>
        import(
          "@/services/hrdc/views/finance_ap_submit_refund_to_cimb/Index.vue"
        ),
      props: (route) => ({
        params: {
          modelKey: "hrdcRefundEmailApToCimb",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/email_payment_advice_to_client",
      name: "EmailPaymentAdviceToClient",
      component: () =>
        import("@/services/hrdc/views/email_payment_advice_to_client/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEmailPaymentAdviceToClient",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "application/enrollment_detail",
      name: "EnrollmentDetail",
      component: () =>
        import("@/services/hrdc/views/enrollment_detail/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcEnrollmentDetail",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "submission_date",
      name: "HrdcApplicationSubmissionDate",
      component: () =>
        import("@/services/hrdc/views/application/SubmissionDate.vue"),
      props: {
        params: {
          modelKey: "hrdcApplicationSubmissionDate",
        },
      },
    },
    {
      path: "report_analysis",
      name: "HrdcReportAnalysis",
      component: () =>
        import("@/services/hrdc/views/report/ReportAnalysis.vue"),
      props: {
        params: {
          modelKey: "HrdcReportAnalysis",
        },
      },
    },
    {
      path: "general_info",
      name: "AddGeneralInfo",
      component: () => import("@/services/hrdc/views/general_info/Add.vue"),
      props: (route) => ({
        params: {
          modelKey: "hrdcGeneralInfo",
          backTo: route.params.backTo,
        },
      }),
    },
    {
      path: "settings",
      name: "HrdcSettings",
      component: () => import("@/services/hrdc/views/settings/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcSettings",
        },
      },
    },
    {
      path: "settings/price",
      name: "HrdcSettingsPrice",
      component: () => import("@/services/hrdc/views/settings/price/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcSettingsPrice",
        },
      },
    },
    {
      path: "settings/error_log",
      name: "HrdcSettingsErrorLog",
      component: () =>
        import("@/services/hrdc/views/settings/error_log/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcSettingsErrorLog",
        },
      },
    },
    {
      path: "settings/job",
      name: "HrdcSettingsJob",
      component: () => import("@/services/hrdc/views/settings/job/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcSettingsJob",
        },
      },
    },
    {
      path: "settings/xero_token",
      name: "HrdcSettingsXeroToken",
      component: () => import("@/services/hrdc/views/settings/xero_token/Index.vue"),
      props: {
        params: {
          modelKey: "HrdcSettingsXero",
        },
      },
    },
    {
      path: "settings/attachment",
      name: "HrdcSettingsAttachment",
      component: () =>
        import(
          "@/services/hrdc/views/settings/attachment/Index.vue"
        ),
      props: {
        params: {
          modelKey: "HrdcSettingsAttachment",
        },
      },
    },
    {
      path: "settings/survey",
      name: "HrdcSettingsSurvey",
      component: () =>
        import(
          "@/services/hrdc/views/settings/survey/Index.vue"
        ),
      props: {
        params: {
          modelKey: "HrdcSettingsSurvey",
        },
      },
    },
    {
      path: "settings/company",
      name: "HrdcSettingsCompany",
      component: () =>
        import(
          "@/services/hrdc/views/settings/company/Index.vue"
        ),
      props: {
        params: {
          modelKey: "HrdcSettingsCompany",
        },
      },
    },
    {
      path: "callback",
      component: () => import("@/services/hrdc/views/callback/Index.vue"),
      props: {
        params: {
          modelKey: "hrdcApplication",
        },
      },
    },
    {
      path: "callback_xero",
      component: () => import("@/services/hrdc/views/callback/xero/Index.vue"),
    },
  ],
};
