<template>
  <v-container fluid class="tb-layout-browse1">
    <!-- Retrieve previous date excel dialog -->
    <v-dialog
      ref="dialog"
      v-model="prevRecordDialog"
      :return-value.sync="prevRecordDate"
      width="290px"
    >
      <v-date-picker
        v-model="prevRecordDate"
        scrollable
        :color="HRDC_BLUE_THEME_COLOR"
      >
        <v-spacer></v-spacer>
        <v-btn text @click="triggerPrevRecord()"> Cancel </v-btn>
        <v-btn text color="success" @click="retrievePrevRecord(prevRecordDate)">
          View
        </v-btn>
      </v-date-picker>
    </v-dialog>

    <!-- Dialog for Generating PV Status -->
    <v-dialog max-width="700" v-model="generatePvDialog" persistent>
      <v-card>
        <v-toolbar dark :color="HRDC_BLUE_THEME_COLOR"
          >Generating PV Status . . .</v-toolbar
        >
        <div class="text-h2 pa-6">
          <v-simple-table dense class="bordered-table">
            <template v-slot:default>
              <thead>
                <tr class="grey lighten-2">
                  <th class="text-left">Application</th>
                  <th class="text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in generatingPvApplications"
                  :key="index"
                >
                  <td>{{ item.application }}</td>
                  <td class="d-flex justify-center align-center">
                    <v-progress-circular
                      v-if="item.status == PAYMENT_VOUCHER_EXECUTING_STATUS"
                      indeterminate
                      size="20"
                      color="amber"
                    ></v-progress-circular>
                    <v-icon
                      v-else
                      :color="generatingPvStatusIcon(item.status).color"
                      >{{ generatingPvStatusIcon(item.status).icon }}</v-icon
                    >
                  </td>
                </tr>
                <tr v-show="!generatingPvApplications">
                  <td colspan="2" class="text-center">
                    Preparing data
                    <v-progress-circular
                      indeterminate
                      size="20"
                      color="amber"
                    ></v-progress-circular>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="toggleGeneratePvDialog(false)"
            :disabled="isGeneratePvDialogDisabled"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog for Retrieve Previous Record -->
    <v-dialog max-width="1000" v-model="recordsDialog">
      <v-card>
        <v-toolbar dark :color="HRDC_BLUE_THEME_COLOR"
          >Previous Record</v-toolbar
        >
        <div class="text-h2 pa-6">
          <v-card-title class="pa-0">
            Date: {{ prevRecordTitleDate }}
            <v-spacer></v-spacer>
            <a href="#" @click="jsonToExcel(retrievedExcelDatas)">
              Download Excel
            </a>
          </v-card-title>
          <v-simple-table dense class="bordered-table">
            <template v-slot:default>
              <thead>
                <tr class="grey lighten-2">
                  <th class="text-left">Beneficial Acc Name</th>
                  <th class="text-left">Price (RM)</th>
                  <th class="text-left">Reference</th>
                  <th class="text-left">Bank</th>
                  <th class="text-left" width="40">Payment Voucher</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in retrievedDatas" :key="key">
                  <td>{{ item.Payee }}</td>
                  <td>{{ item.Paid }}</td>
                  <td>{{ item.Reference }}</td>
                  <td>{{ item["Bank Type"] }}</td>
                  <td class="text-center">
                    <v-icon v-if="!item.pvFileUrl" disabled
                      >mdi-file-eye-outline</v-icon
                    >
                    <v-icon
                      v-else
                      :disabled="!item.pvFileUrl"
                      color="red lighten-2"
                      v-for="(data, key) in item.pvFileUrl"
                      :key="key"
                      @click="previewPv(data.fileUrl)"
                      >mdi-file-eye-outline</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <v-card-actions class="justify-end">
          <v-btn text @click="toggleRecordsDialog(false)">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="pa-2">
      <transition name="fade">
        <v-alert
          dense
          border="left"
          type="warning"
          class="text-pre-wrap"
          v-if="alertError.length > 0"
          ><span
            class="d-block"
            v-for="(error, index) in alertError"
            :key="index"
            >{{ error }}</span
          ></v-alert
        >
      </transition>

      <BreadHeader :title="headerTitle"> </BreadHeader>

      <v-row class="tb-filter-section d-none1">
        <v-row no-gutters>
          <v-col md="4">
            <div class="tb-left-second-section">
              <div>
                <h5
                  v-if="switchToAdvanceFilter == true"
                  @click="switchToAdvanceFilter = false"
                  style="cursor: pointer"
                >
                  Switch to Normal Filter
                </h5>
                <h5
                  v-if="switchToAdvanceFilter == true"
                  @click="switchToAdvanceFilter = true"
                  style="cursor: pointer"
                >
                  Switch to Advance Filter
                </h5>
              </div>
              <div>
                <h4 v-on:click="isShow = !isShow" style="float: left">
                  Filter
                  <v-icon left> mdi mdi-menu-down </v-icon>
                </h4>
                <span v-if="switchToAdvanceFilter == false">
                  {{ conditions.length }} Filter Applied
                </span>
              </div>
            </div>
          </v-col>
          <v-col md="8">
            <!-- include column selection compnent -->
            <BreadTabulatorSelectColumn
              ref="selectColumn"
              :model="model"
              @applyColumnSelection="applyColumnSelection"
            >
            </BreadTabulatorSelectColumn>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-2">
          <v-col md="12">
            <div class="tb-normal-filter" v-show="isShow">
              <div
                class="tb-filter-area"
                v-show="switchToAdvanceFilter == false"
              >
                <BreadTabulatorFilterable
                  ref="filter"
                  :model="model"
                  :setConditions="setConditions"
                >
                </BreadTabulatorFilterable>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-row>

      <!-- defult search area-->
      <div class="tb-search-area">
        <v-row>
          <v-col md="6">
            <BreadTabulatorSearchable
              ref="search"
              :model="model"
              :setConditions="setConditions"
            >
            </BreadTabulatorSearchable>
            <div>
              <span
                id="record-count"
                style="float: left; font-size: 12px"
              ></span>
            </div>
          </v-col>

          <v-col md="6" class="d-flex justify-end">
            <v-btn-toggle
              dense
              multiple
              class="mr-1"
              v-if="isFinanceApAdminRoles && !btnDisabled"
            >
              <ApApplicationSelection
                :apApplicationSelectionDialog="apApplicationSelectionDialog"
                :apiUrl="this.apiGetBrowseUrl()"
                @showRefundClientForm="showRefundClientForm"
                @toggleSelectionDialog="toggleSelectionDialog"
                @downloadExcel="downloadExcelData"
                @prevRecord="triggerPrevRecord"
              ></ApApplicationSelection>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </div>

      <BreadTabulator
        ref="tabulatorDataTable"
        layout="fitColumns"
        :autoColumns="false"
        :paginationSize="paginationSize"
        :paginationSizeSelector="paginationSizeSelector"
        :apiUrl="apiGetBrowseUrl()"
        :columns="columns"
        :modelKey="modelKey"
        :columnSelectionDisabledCount="columnSelectionDisabledCount"
        @getCurrentPageSize="getCurrentPageSize"
        @getCurrentSort="getCurrentSort"
        @getCurrentColumnWidth="getCurrentColumnWidth"
        @getCurrentPageNumber="getCurrentPageNumber"
      >
      </BreadTabulator>
    </v-card>
    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
    <ApRejectButton
      :rejectDialog="rejectDialog"
      :errorField="errorField"
      :formData="formData"
      @closeRejectDialog="closeRejectDialog()"
      @rejectApplicationToAR="rejectApplicationToAR"
    ></ApRejectButton>
  </v-container>
</template>
<script>
import Fuse from "fuse.js";
import JSZip from "jszip";
import axios from "axios";
import * as XLSX from "xlsx";
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc/objects/globalData";
import ApRejectButton from "@/services/hrdc/views/finance_ap_submit_refund_to_cimb/ApRejectButton";
import ApApplicationSelection from "@/services/hrdc/views/finance_ap_submit_refund_to_cimb/ApApplicationSelection";

export default {
  mixins: [hrdcFunction],
  components: {
    ApApplicationSelection,
    ApRejectButton,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
      hrdc: (state) => state.hrdc.data,
    }),
    headerTitle() {
      return `${process.env.VUE_APP_SERVICE_HRDC_TAXPOD_APP_NAME} ${this.model.name.plural}`;
    },
  },
  props: ["params"],
  data: () => ({
    api: new Api(),
    apiSubmit: new Api(),
    apiRejectApplicationToAR: new Api(),
    apiRetrievePrevApRecord: new Api(),
    apiScanPdfText: new Api(),
    apiDownloadOrPreviewAttachment: new Api(),
    conditions: [],
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    listAllViews: [],
    btnDisabled: false,
    columns: [],
    paginationSize: 10,
    paginationSizeSelector: [10, 50, 100, 200, 500],
    modelKey: "",
    cColumnSort: [],
    displayRowCount: 10,
    selectedCustomColums: [],
    selectedFilterConditions: [],
    switchToAdvanceFilter: false,
    isShow: false,
    allResizeColumns: [],
    columnSelectionDisabledCount: 3,
    currentPageNo: 1,
    errorField: {
      rejectReason: null,
    },
    formData: {
      applicationUuid: null,
      rejectReason: null,
    },
    alertError: [],
    loadingDialog: false,
    rejectDialog: false,
    apApplicationSelectionDialog: false,
    prevRecordDialog: false,
    prevRecordDate: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    generatePvDialog: false,
    generatingPvApplications: null,
    isGeneratePvDialogDisabled: true,
    recordsDialog: false,
    retrievedDatas: [],
    retrievedExcelDatas: [],
    prevRecordTitleDate: null,
  }),
  async created() {
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    this.modelKey = this.params.modelKey;
    this.columns = this.$_.filter(
      this.model.browse.table.headers,
      (headers) => {
        return headers;
      }
    );

    this.serviceDataAssignation(this.model.serviceKey);

    const apiDetails = {
      api: {
        url: `${this.$service.crm}/v1/en/console/crmView/list?modelKey=${this.model.key}`,
      },
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/finance_refund/create-finance-refund-bulk`,
        method: "post",
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.data.token}`,
        },
      },
      apiRejectApplicationToAR: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/finance_refund/reject-application-to-ar`,
        method: "post",
      },
      apiRetrievePrevApRecord: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/finance_refund/retrieve-previous-ap-record`,
      },
      apiScanPdfText: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/finance_refund/scan-pdf`,
        method: "post",
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.data.token}`,
        },
      },
      apiDownloadOrPreviewAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/azure-download-or-preview-application-attachment`,
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
      if (apiDetails[api].headers !== undefined) {
        this[api].setHeaders(apiDetails[api].headers);
      }
    }

    var columnIndex = 0;
    for (var column of this.columns) {
      // Add click company cell direct to view details.
      if (column.field == "bankAccountHolderName") {
        this.columns[columnIndex].cellClick = (_, cell) => {
          const {
            applicationUuid,
            stageId,
            firstNameAccEnrollment,
            lastNameAccEnrollment,
            contactNoAccEnrollment,
            emailAccEnrollment,
          } = cell.getData();

          this.$store.commit("assignHrdcData", {
            applicationUuid,
            stageId,
            firstNameAccEnrollment,
            lastNameAccEnrollment,
            contactNoAccEnrollment,
            emailAccEnrollment,
            viewId: this.$route.query.viewId,
          });

          this.$router.push({
            name: "ViewHrdcApplication",
            query: {
              currentPageNo: this.currentPageNo,
              _ausk: applicationUuid,
            },
            params: {
              backTo: "RefundEmailApToCimb",
            },
          });
        };
      }

      columnIndex++;
    }

    this.columns.push({
      title: "Action",
      field: "action",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      width: "140",
      formatter: (cell, _, onRendered) => {
        const data = cell.getData();
        const previewAtchUrl = `${process.env.VUE_APP_SERVICE_URL_HRDC}/v1/en/console/application/get-attachment-via-stage`;
        const streamUrl = `${process.env.VUE_APP_SERVICE_URL_HRDC}/v1/en/console/application/azure-download-or-preview-application-attachment`;

        let btn = `
          <button type="button" class="view-pdf text-light v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small" style="background-color: ${this.HRDC_BLUE_THEME_COLOR}">
            View PDF
          </button>
        `;

        // Only finance-ap roles can reject application to AR stages.
        if (this.isFinanceApAdminRoles) {
          btn += `
            <button type="button" class="reject-btn text-light v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small red lighten-2">
              Reject
            </button>
          `;
        }

        onRendered(() => {
          cell
            .getElement()
            .querySelector(".view-pdf")
            .addEventListener("click", async (e) => {
              this.showLoadingDialog();

              e.stopPropagation(); // Prevent row click event

              // Get fileUrl by applicationUuid & preview.
              await axios
                .post(
                  previewAtchUrl,
                  {
                    applicationNo: data.applicationUuid,
                    stageId: this.hrdcData.stagePriority.refundEmailArToAp,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${this.$store.state.auth.data.token}`,
                    },
                  }
                )
                .then(async (response) => {
                  if (!response.status) {
                    throw new Error(response.data);
                  }

                  const { data } = response.data;
                  const compiledPdfObj = data[0];
                  const fileUrl = compiledPdfObj.fileUrl;

                  await axios
                    .get(streamUrl, {
                      responseType: "blob",
                      headers: {
                        "Content-Type": "application/json",
                      },
                      params: {
                        filePath: fileUrl,
                      },
                    })
                    .then((response) => {
                      const blobUrl = URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                      );

                      window.open(blobUrl, "_blank");

                      this.hideLoadingDialog();
                    })
                    .catch((error) => {
                      if ((error.response.status == 404) | true) {
                        this.$store.dispatch("showMessage", {
                          message:
                            "Failed to Preview/Download attachment, kindly contact administrator",
                          messageType: "error",
                          timeout: 2000,
                        });
                      }
                      this.hideLoadingDialog();
                    });
                })
                .catch((err) => {
                  console.log(err);
                  this.hideLoadingDialog();
                  this.$store.dispatch("showMessage", {
                    message:
                      "Failed to Preview/Download attachment, kindly contact administrator",
                    messageType: "error",
                    timeout: 2000,
                  });
                  return false;
                });
            });

          if (this.isFinanceApAdminRoles) {
            cell
              .getElement()
              .querySelector(".reject-btn")
              .addEventListener("click", async () => {
                this.rejectDialog = true;
                this.formData.applicationUuid = cell.getData().applicationUuid;
              });
          }
        });

        return btn;
      },
      htmlOutput: true,
    });

    this.rolesAssignation(this.auth.roles);

    this.stages = await this.hrdcData.allStages;

    this.banks = this.model.banks;

    this.rolesAssignation(this.auth.roles);

    if (!this.isFinanceApAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }
  },
  mounted() {
    const viewId = this.$route.query.viewId;

    if (viewId !== "all" && viewId !== "trashed") {
      this.getAllViews(parseInt(viewId, 10));
    } else {
      this.getAllViews(viewId);
    }
  },
  methods: {
    getConditions() {
      return this.conditions;
    },
    setConditions(conditions) {
      this.selectedFilterConditions = conditions;
      this.conditions = conditions;
      this.$refs.tabulatorDataTable.getFilterCondition(conditions);
    },
    apiGetBrowseUrl() {
      // Check if user have salesperson role, then assign only this salesperson can see back own sales.
      let browseUrl = `${this.$service[this.service.key]}/v1/en/console/${
        this.model.key
      }`;

      if (this.isSalespersonRoles && this.auth.roles.length == 1) {
        browseUrl = browseUrl + `?email=${this.auth.email}`;
      }

      return browseUrl;
    },
    apiGetBrowseTrashUrl() {
      let browseDeleteUrl = `
          ${this.$service[this.service.key]}/v1/en/console/${
        this.model.key
      }?deleted=true
        `;

      if (this.isSalespersonRoles && this.auth.roles.length == 1) {
        browseDeleteUrl = browseDeleteUrl + `&email=${this.auth.email}`;
      }

      return browseDeleteUrl;
    },
    setFiltersViaConditions(conditions) {
      this.$refs.filter.setFiltersViaConditions(conditions);
    },
    getCurrentPageSize(pagesize) {
      this.displayRowCount = pagesize;
    },
    getCurrentSort(sorters) {
      this.cColumnSort = sorters;
      this.cColumnSort = sorters.map((sorter) => {
        return {
          column: sorter.field,
          dir: sorter.dir,
        };
      });
    },
    getCurrentColumnWidth(resizedColumns) {
      const resizedColumnField = resizedColumns.getField();
      const resizedColumnWidth = resizedColumns.getWidth();

      //set the resized column width and filed name to the array
      const resizedColumnWidthAndField = {
        field: resizedColumnField,
        width: resizedColumnWidth,
      };

      const index = this.allResizeColumns.findIndex((item) => {
        return item.field === resizedColumnField;
      });

      if (index !== -1) {
        this.allResizeColumns[index].width = resizedColumnWidth;
      } else {
        this.allResizeColumns.push(resizedColumnWidthAndField);
      }
    },
    changeTableDetails(listAllViews, viewId) {
      // get the view details from listAllViews where id is equal to viewId
      if (viewId == "trashed") {
        this.$refs.tabulatorDataTable.callApiUrl(this.apiGetBrowseTrashUrl());
        this.$refs.selectColumn.showAllColumns([]);
        this.$refs.tabulatorDataTable.callSavedHeaderWidth([]);
        this.$refs.search.getFilterCondition([]); /// add this one
        this.$refs.customView.changeSelectedTabStatus("trashed", 0);
      } else if (viewId == "all") {
        this.$refs.tabulatorDataTable.callSavedHeaderWidth([
          {
            field: "rowSelection",
            width: 50,
          },
          {
            field: "index",
            width: 70,
          },
          {
            field: "price",
            width: 150,
          },
          {
            field: "action",
            width: 200,
          },
        ]);
        this.$refs.selectColumn.showAllColumns([]);
        this.$refs.search.getFilterCondition([]);
        this.$refs.customView.changeSelectedTabStatus("all", 1);
        this.setCurrentPageTo();
      } else {
        const tabIndex =
          listAllViews.indexOf(listAllViews.find((x) => x.id === viewId)) + 2;
        this.$refs.customView.changeSelectedTabStatus(viewId, tabIndex);
        const singleViewDetail = listAllViews.filter((item) => {
          return item.id === viewId;
        });

        if (singleViewDetail.length > 0) {
          if (
            JSON.parse(singleViewDetail[0].manageColumnCustomWidth).length > 0
          ) {
            this.$refs.tabulatorDataTable.callSavedHeaderWidth(
              JSON.parse(singleViewDetail[0].manageColumnCustomWidth)
            );
          }

          this.$refs.selectColumn.showViewSelectedColumns(
            JSON.parse(singleViewDetail[0].cColumnCondition)
          );

          // calling API
          if (JSON.parse(singleViewDetail[0].filterCondition).length > 0) {
            this.$refs.filter.setFiltersViaConditions(
              JSON.parse(singleViewDetail[0].filterCondition)
            );
          }

          // calling API
          if (JSON.parse(singleViewDetail[0].cColumnSort).length > 0) {
            this.$refs.tabulatorDataTable.callSavedColumnSort(
              JSON.parse(singleViewDetail[0].cColumnSort)
            );
          }

          // calling API
          if (singleViewDetail[0].displayRowCount != this.paginationSize) {
            this.$refs.tabulatorDataTable.callSavedPageSize(
              singleViewDetail[0].displayRowCount
            );
          }
          // add following one
          this.$refs.search.getFilterCondition(
            JSON.parse(singleViewDetail[0].filterCondition)
          );
        }

        this.setCurrentPageTo();
      }
    },
    getAllViews(viewId) {
      this.api.setCallbackCompleted((response) => {
        // get all private views when the ouwnerUuid is equal to auth.uuid
        const privateViews = response.filter((view) => {
          return view.isPrivate === true && view.ownerUuid === this.auth.uuid;
        });

        // get all public views
        const publicViews = response.filter((view) => {
          return view.isPrivate === false;
        });

        // push all private and public views to listAllViews
        this.listAllViews = [...publicViews, ...privateViews];
        this.changeTableDetails(this.listAllViews, viewId);
      });
      this.api.fetch();
    },
    applyColumnSelection(selectedColumns) {
      this.selectedCustomColums = selectedColumns;
      this.$refs.tabulatorDataTable.applyColumnSelection(selectedColumns);
    },
    getCurrentPageNumber(pageno) {
      this.currentPageNo = pageno;
    },
    setCurrentPageTo() {
      var pageNo =
        this.$store.state.developer.data.paginationPageNo != null
          ? this.$store.state.developer.data.paginationPageNo
          : 1;
      this.$refs.tabulatorDataTable.tabulator.setPage(pageNo);
      this.$store.commit("assignDeveloperData", {
        paginationPageNo: null,
      });
    },
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    showRefundClientForm(apSelectedDate) {
      let allRecords =
        this.$refs.tabulatorDataTable.tabulator.getSelectedData();

      // Check if any row is selected or not
      if (allRecords.length == 0) {
        this.$store.dispatch("showMessage", {
          message: "Please select at least one row!",
          messageType: "error",
          timeout: 2000,
        });
        return;
      }

      // Check only allow 10 attachments run concurrently.
      if (allRecords.length > 10) {
        this.$store.dispatch("showMessage", {
          message: "Please select at most 10 applications at one time.",
          messageType: "error",
          timeout: 2000,
        });
        return;
      }

      let selectedRecords = allRecords.map((row) => {
        const {
          applicationUuid,
          creditNoteNo,
          price,
          cnDate,
          bankAccountHolderName: oriCompanyName,
          compiledPdfFileUrl,
          cnFileUrl,
        } = row;
        return {
          applicationUuid,
          creditNoteNo,
          reference: `${row.creditNoteNo}/${row.autoGeneratedTaxpodInvoiceNo}`,
          price,
          cnDate,
          companyName: oriCompanyName,
          compiledPdfFileUrl,
          cnFileUrl,
        };
      });

      if (selectedRecords) {
        this.submit(selectedRecords, apSelectedDate);
      }
    },
    async downloadExcelData() {
      let allRecords =
        this.$refs.tabulatorDataTable.tabulator.getSelectedData();

      if (allRecords.length == 0) {
        this.$store.dispatch("showMessage", {
          message: "Please select at least one row!",
          messageType: "error",
          timeout: 2000,
        });
        return;
      }

      this.jsonToExcel(this.getSelectedRowToExcel());
      this.$refs.tabulatorDataTable.tabulator.deselectRow();
    },
    triggerPrevRecord() {
      this.prevRecordDialog = !this.prevRecordDialog;
    },
    retrievePrevRecord(date) {
      this.showLoadingDialog();

      this.prevRecordTitleDate = date;

      this.apiRetrievePrevApRecord.setQuery(
        `date=${date}&stageId=${this.hrdcData.stagePriority.apSubmitRefundToCimb}&pvStageId=${this.hrdcData.stagePriority.paymentVoucher}`
      );
      this.apiRetrievePrevApRecord.setCallbackCompleted((response) => {
        try {
          const { status, data, message } = response;

          if (!status) {
            this.$store.dispatch("showMessage", {
              message,
              messageType: "error",
              timeout: 2000,
            });

            return false;
          }

          if (status) {
            // To empty previous record.
            this.retrievedDatas = [];
            this.retrievedExcelDatas = [];

            for (const item of data) {
              const datas = {
                Requestor: this.learnTaxPodEmail,
                Reference:
                  item.creditNoteNo + "/" + item.autoGeneratedTaxpodInvoiceNo,
                Payee: this.getPayeeName(item.bankAccountHolderName),
                Paid: this.getPrice(item.price),
                "Bank Type": this.getBankName(item.bankName),
                "Account No.": this.getAccountNo(item.bankAccountNo),
              };
              this.retrievedExcelDatas.push(datas);

              const datasWithPv = { ...datas, pvFileUrl: item.fileUrl };
              this.retrievedDatas.push(datasWithPv);
            }

            this.triggerPrevRecord();
            this.toggleRecordsDialog(true);
          }
        } catch (err) {
          console.log(err.message);
          this.$store.dispatch("showMessage", {
            message: "Error, please contact administrator for support!",
            messageType: "error",
            timeout: 2000,
          });
        } finally {
          this.hideLoadingDialog();
        }
      });
      this.apiRetrievePrevApRecord.fetch();
    },
    jsonToExcel(data) {
      try {
        // Convert JSON data to a worksheet
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();

        for (let i = 2; i <= data.length + 1; i++) {
          ws[`D${i}`].z = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        }

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        // Trigger the download
        XLSX.writeFile(wb, `HRDC ${this.name1} Refund Details.xlsx`);
      } catch (err) {
        console.log(err);
      }
    },
    findBankType(bankName) {
      const options = {
        includeScore: true,
        shouldSort: true,
        isCaseSensitive: false,
        threshold: 0.3,
        keys: ["name", "bankType", "abbreviation"],
      };

      // Check whether able to direct get bank from bank lists or not.
      const getBankFromBankLists = this.banks.find((item) => {
        const splitName = item.bankType.split("-");
        return splitName[1] && splitName[1].trim() === bankName;
      });

      if (getBankFromBankLists) {
        return getBankFromBankLists.bankType;
      }

      let searchTextFormat = bankName
        .replace(/\b(?:bank|berhad|bhd|MALAYSIA|islamic|(M))\b/gi, "")
        .trim();

      const fuse = new Fuse(this.banks, options);
      const result = fuse.search(searchTextFormat);

      if (result.length == 0) {
        return bankName;
      } else {
        let bankType = result[0].item.bankType
          ? result[0].item.bankType
          : bankName;
        return bankType;
      }
    },
    getSelectedRowToExcel() {
      return this.$refs.tabulatorDataTable.tabulator
        .getSelectedData()
        .map((row) => {
          const {
            autoGeneratedTaxpodInvoiceNo,
            creditNoteNo,
            price,
            bankAccountHolderName,
            bankName,
            bankAccountNo,
          } = row;

          return {
            Requestor: this.learnTaxPodEmail,
            Reference: creditNoteNo + "/" + autoGeneratedTaxpodInvoiceNo,
            Payee: this.getPayeeName(bankAccountHolderName),
            Paid: this.getPrice(price),
            "Bank Type": this.getBankName(bankName),
            "Account No.": this.getAccountNo(bankAccountNo),
          };
        });
    },
    getPayeeName(bankAccountHolderName) {
      return bankAccountHolderName.toUpperCase().replace(/\u00A0/g, " ");
    },
    getAccountNo(bankAccNo) {
      return bankAccNo.replace(/[-\s]/g, "");
    },
    getBankName(bankName) {
      return this.findBankType(bankName);
    },
    getPrice(price) {
      return parseFloat(price.toFixed(2).toLocaleString());
    },
    async submit(selectedRecords, cimbSubmitedDate) {
      this.toggleGeneratePvDialog(true);
      this.toggleSelectionDialog(false);

      const zip = new JSZip();
      const companyCountMap = {};

      const extraApplications = [];
      for (const application of selectedRecords) {
        // Check whether is this application consist of >1 CN.
        const [cnPart, invPart] = application.reference.split("/");
        const invArr = invPart.split(",");

        // Split the CN claim amount.
        this.apiScanPdfText.setParams({
          fileUrl: application.cnFileUrl,
        });

        await this.$axios({
          method: this.apiScanPdfText.method,
          url: this.apiScanPdfText.url,
          data: this.apiScanPdfText.params,
          headers: this.apiScanPdfText.headers,
        }).then((res) => {
          const response = res.data;
          if (!response.status) {
            this.$store.dispatch("showMessage", {
              message: response.message,
              messageType: "error",
              timeout: 2000,
            });
            return false;
          }

          if (response.status) {
            for (const [index, cnNo] of cnPart.split(",").entries()) {
              const cnObj = response.data.find((item) => item.cnNo == cnNo);
              if (cnObj) {
                extraApplications.push({
                  applicationUuid: application.applicationUuid,
                  creditNoteNo: cnNo,
                  reference: `${cnNo}/${invArr[index]}`,
                  price: cnObj.price,
                  cnDate: application.cnDate,
                  companyName: application.companyName,
                  compiledPdfFileUrl: application.compiledPdfFileUrl,
                });
              }
            }
          }
        });
      }

      const updatedRecords = [...extraApplications];

      for (const record of updatedRecords) {
        // Check for duplicate company that claims > 1 set.
        if (companyCountMap[record.companyName]) {
          companyCountMap[record.companyName] += 1; // Increment count for future duplicates
          record.companyName = `${record.companyName} - ${
            companyCountMap[record.companyName]
          }`;
        } else {
          companyCountMap[record.companyName] = 1;
        }
      }

      this.generatingPvApplications = updatedRecords.map((item) => {
        return {
          applicationUuid: item.applicationUuid,
          application: `YYCGST- ${item.companyName.toUpperCase()}`,
          status: this.PAYMENT_VOUCHER_PENDING_STATUS,
          creditNoteNo: item.creditNoteNo,
        };
      });

      let data = {
        financeRefundToClientDate: cimbSubmitedDate,
        userUuid: this.auth.uuid,
        username: this.auth.name,
        editMode: this.editMode,
        // Below data is to prepare for xero api call in backend.
        xeroExchangeCodeUrl: process.env.VUE_APP_XERO_EXCHANGE_CODE_URL,
        grantType: "refresh_token",
        clientId: process.env.VUE_APP_XERO_CLIENT_ID,
        basicAuthToken: this.getXeroBasicAuthorizationCode(),
        currentDate: this.localeDate().format("YYYY-MM-DD HH:mm:ss"),
      };

      for (const record of updatedRecords) {
        // Update application status that is currently running.
        let beneficialName = `YYCGST- ${record.companyName.toUpperCase()}`;
        const index = this.generatingPvApplications.findIndex(
          (item) =>
            item.applicationUuid === record.applicationUuid &&
            item.creditNoteNo === record.creditNoteNo
        );

        this.generatingPvApplications[index].status =
          this.PAYMENT_VOUCHER_EXECUTING_STATUS;

        data.selectedApplications = [record];

        await this.$axios({
          method: this.apiSubmit.method,
          url: this.apiSubmit.url,
          data,
          headers: this.apiSubmit.headers,
          responseType: "arraybuffer",
        })
          .then((response) => {
            const decoder = new TextDecoder("utf-8");
            response = decoder.decode(new Uint8Array(response.data));
            response = JSON.parse(response);

            if (!response.status) {
              throw new Error(response.message);
            }

            if (response.status) {
              this.generatingPvApplications[index].status =
                this.PAYMENT_VOUCHER_SUCCESS_STATUS;

              // Append all the return response zip arraybuffer to zip instance.
              zip.file(`${beneficialName}.pdf`, response.data.data, {
                compression: "DEFLATE",
                compressionOptions: {
                  level: 1,
                },
              });
            }
          })
          .catch((err) => {
            console.log(err.message);
            this.generatingPvApplications[index].status =
              this.PAYMENT_VOUCHER_FAILED_STATUS;
          });
      }

      if (Object.keys(zip.files).length == 0) {
        this.$store.dispatch("showMessage", {
          message: "Failed update, please contact administrator for support!",
          messageType: "error",
          timeout: 2000,
        });

        this.isGeneratePvDialogDisabled = false;
        return false;
      }

      // Compile all zip instance and download.
      zip
        .generateAsync({
          type: "arraybuffer",
          compression: "DEFLATE",
          compressionOptions: {
            level: 1,
          },
        })
        .then((content) => {
          const url = window.URL.createObjectURL(
            new Blob([new Uint8Array(content)])
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `YYGST HRDC ${this.name1} Refund.zip`);
          document.body.appendChild(link);
          link.click();

          // Only able to close dialog once downloaded zip file.
          this.isGeneratePvDialogDisabled = false;
        })
        .then(() => {
          this.$refs.tabulatorDataTable.callApiUrl(this.apiGetBrowseUrl());
          this.getAllViews("all");
          this.$store.dispatch("showMessage", {
            message: "Updated Successfully!",
            messageType: "success",
            timeout: 2000,
          });
        })
        .catch((err) => {
          this.showErrorMessage(err);
          if (err.message) {
            this.$store.dispatch("showMessage", {
              message: err.message,
              messageType: "error",
              timeout: 2000,
            });
          }
        });
    },
    generatingPvStatusIcon(status) {
      switch (status) {
        case this.PAYMENT_VOUCHER_SUCCESS_STATUS:
          return {
            icon: "mdi-check-circle",
            color: "green",
          };
        case this.PAYMENT_VOUCHER_PENDING_STATUS:
          return {
            icon: "mdi-clock",
            color: "deep-orange lighten-2",
          };
        case this.PAYMENT_VOUCHER_FAILED_STATUS:
          return {
            icon: "mdi-close-circle",
            color: "red",
          };
        default:
          return {
            icon: "mdi-help-circle",
            color: "black",
          };
      }
    },
    previewPv(fileUrl) {
      this.showLoadingDialog();

      this.$axios
        .get(this.apiDownloadOrPreviewAttachment.url, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            filePath: fileUrl,
          },
        })
        .then((response) => {
          const blobUrl = URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );

          window.open(blobUrl, "_blank");
        })
        .catch((error) => {
          if ((error.response.status == 404) | true) {
            this.$store.dispatch("showMessage", {
              message:
                "Failed to Preview/Download attachment, kindly contact administrator",
              messageType: "error",
              timeout: 2000,
            });
          }
          this.atchLoadingDialog = !this.atchLoadingDialog;
        })
        .finally(() => {
          this.hideLoadingDialog();
        });
    },
    toggleGeneratePvDialog(val) {
      this.generatePvDialog = val;
      if (val == false) {
        this.generatingPvApplications = null;
        this.isGeneratePvDialogDisabled = true;
      }
    },
    closeRejectDialog() {
      this.clearErrorMsg();
      this.rejectDialog = false;
    },
    toggleSelectionDialog(val) {
      this.apApplicationSelectionDialog = val;
    },
    toggleRecordsDialog(val) {
      this.recordsDialog = val;
    },
    rejectApplicationToAR(formData) {
      this.showLoadingDialog();

      this.clearErrorMsg();
      this.apiRejectApplicationToAR.setParams({
        ...formData,
        revertBackToStageId:
          this.hrdcData.stagePriority.refundEmailApToCimbRejected,
        refundEmailApToCimbRejectedStageId:
          this.hrdcData.stagePriority.refundEmailApToCimbRejected,
        userUuid: this.auth.uuid,
      });
      this.apiRejectApplicationToAR.setCallbackCompleted((response) => {
        try {
          if (!response.status) {
            this.showErrorMessage(response);
            this.hideLoadingDialog();
          }

          if (response.status) {
            this.closeRejectDialog();
            this.formData.rejectReason = null;

            this.$router.go();
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });

            this.hideLoadingDialog();
          }
        } catch (err) {
          console.log(err);
          this.alertError.push(err.message);
          return false;
        }
      });
      this.apiRejectApplicationToAR.fetch();
    },
  },
};
</script>

<style>
.bordered-table,
.bordered-table th,
.bordered-table td {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
