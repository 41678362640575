const NEW_SALE_CELL_COLOR = "#FFFFC2";
const RENEWAL_CELL_COLOR = "#F5DEB3";
const PAY_AND_REFUND_CELL_COLOR = "#CFD0CF";
const CLAIM_BASIS_CELL_COLOR = "#ECECEC";
const COMPANY_UNUSUAL_COLOR = "#FA8072";
const SUCCESS_COLOR = "#B4D3B2";

import Fuse from "fuse.js";
import moment from "moment";
import banks from "@/objects/bank";
import stages from "@/objects/stages";

export default {
  key: "refundApToCimb",
  serviceKey: "hrdc_tpdiy",
  banks,
  name: {
    singular: "Refund AP to CIMB",
    plural: "Refund AP to CIMB",
  },
  parents: [
    //
  ],
  add: {
    name: "Add Application",
  },
  view: {
    name: "View Application",
  },
  edit: {
    name: "Edit Application",
  },
  browse: {
    table: {
      headers: [
        {
          formatter: "rowSelection",
          field: "rowSelection",
          titleFormatter: "rowSelection",
          titleFormatterParams: {
            rowRange: "active", //only toggle the values of the active filtered rows
          },
          hozAlign: "left",
          headerSort: false,
          filterable: false,
          searchable: false,
          headerFilter: false,
          selectableColumn: false,
          visible: true,
        },
        {
          title: "No.",
          field: "index",
          filterable: false,
          searchable: false,
          headerFilter: false,
          headerSort: false,
          selectableColumn: false,
          visible: true,
          width: 90,
          formatter: (cell) => {
            cell.getElement().classList.add("number-class");
            const rowData = cell.getData();
            let data = rowData.rownum;

            if (rowData.isApplicationDuplicate) {
              data += ` <i title="Application Duplicate" aria-hidden="true" class="v-icon notranslate mdi mdi-content-duplicate theme--light orange--text"></i>`;
            }

            if (rowData.isFlag) {
              data += ` <i aria-hidden="true" class="v-icon notranslate mdi mdi-flag theme--light red--text normal-flag"></i>`;
            }

            return data;
          },
        },
        {
          title: "Payee",
          field: "bankAccountHolderName",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: true,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          // width: 300,
          formatter: (cell) => {
            const data = cell.getData();
            const payeeName = data.bankAccountHolderName;
            return `<span style="color:blue;">${payeeName.toUpperCase()}</span>`;
          },
        },
        {
          title: "Client",
          field: "client",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          // widthGrow: 2,
          headerWordWrap: true,
          showByDefault: false,
        },
        {
          title: "Client Contact No.",
          field: "clientPicContactNo",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          headerSort: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: false,
        },
        {
          title: "Customer Service",
          field: "csName",
          dataType: "onlyLikeEqualNotEqual",
          headerSort: false,
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          visible: true,
          showByDefault: false,
        },
        {
          title: "Responder",
          field: "responderName",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: false,
        },
        {
          title: "taxPOD Type",
          field: "taxpodType",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: false,
          formatter(cell) {
            let taxpodType = cell.getValue();
            if (taxpodType == 1) {
              cell.getElement().style.backgroundColor = NEW_SALE_CELL_COLOR;
              taxpodType = "New Sale";
            }
            if (taxpodType == 2) {
              cell.getElement().style.backgroundColor = RENEWAL_CELL_COLOR;
              taxpodType = "Renewal";
            }

            return taxpodType;
          },
        },
        {
          title: "Payment Channel",
          field: "isPaymentMade",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: false,
          formatter(cell) {
            let isPaymentMade = cell.getValue();
            if (isPaymentMade == true) {
              cell.getElement().style.backgroundColor =
                PAY_AND_REFUND_CELL_COLOR;
              isPaymentMade = "Pay & Refund";
            }
            if (isPaymentMade == false) {
              cell.getElement().style.backgroundColor = CLAIM_BASIS_CELL_COLOR;
              isPaymentMade = "Claim Basis";
            }

            return isPaymentMade;
          },
        },
        {
          title: "Price",
          field: "price",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          formatter(cell) {
            return "RM " + parseInt(cell.getValue()).toLocaleString();
          },
        },
        {
          title: "Claim ID",
          field: "claimId",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "center",
          headerWordWrap: true,
          showByDefault: false,
          formatter(cell) {
            const claimId = cell.getValue();
            if (claimId) {
              return claimId;
            }
            return "-";
          },
        },
        {
          title: "Grant Approval No.",
          field: "grantApprovalNo",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "center",
          headerWordWrap: true,
          showByDefault: false,
          formatter(cell) {
            const grantApprovalNo = cell.getValue();
            if (grantApprovalNo) {
              return grantApprovalNo;
            }
            return "-";
          },
        },
        {
          title: "Reference (CN No./Client INV)",
          field: "creditNoteNo",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "center",
          headerWordWrap: true,
          showByDefault: true,
          formatter(cell) {
            const data = cell.getData();
            const reference = `${data.creditNoteNo}/${data.autoGeneratedClientInvoiceNo}`;
            return reference;
          },
        },
        {
          title: "Bank Type",
          field: "uuid",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: true,
          formatter(cell) {
            const data = cell.getData();
            const bankName = data.bankName;
            const options = {
              includeScore: true,
              shouldSort: true,
              isCaseSensitive: false,
              threshold: 0.3,
              keys: ["name", "bankType", "abbreviation"],
            };

            // Check whether able to direct get bank from bank lists or not.
            const getBankFromBankLists = banks.find((item) => {
              const splitName = item.bankType.split("-");
              return splitName[1] && splitName[1].trim() === bankName;
            });

            if (getBankFromBankLists) {
              return getBankFromBankLists.bankType;
            }

            let searchTextFormat = bankName
              .replace(/\b(?:bank|berhad|bhd|MALAYSIA|islamic|(M))\b/gi, "")
              .trim();

            const fuse = new Fuse(banks, options);
            const result = fuse.search(searchTextFormat);

            if (result.length == 0) {
              const getBankGuessBased = banks.find((item) => {
                return item.name.includes(bankName);
              });

              if (getBankGuessBased) {
                return getBankGuessBased.bankType;
              }

              // Final fallback if didn't found any similar bank
              return bankName;
            } else {
              // Loop for the search results, if got exact name matched with column 'name', direct return.
              for (const item of result) {
                const itemObj = item.item;
                const bankNameFromItemObjName = itemObj.name.split("/");
                for (const obj of bankNameFromItemObjName) {
                  const objBankname = obj.trim();
                  if (objBankname == searchTextFormat) {
                    return itemObj.bankType;
                  }
                }
              }

              let bankType = result[0].item.bankType
                ? result[0].item.bankType
                : bankName;
              return bankType;
            }
          },
        },
        {
          title: "Bank Account No.",
          field: "bankAccountNo",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: true,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: true,
          formatter(cell) {
            const data = cell.getValue();
            return data.replace(/[-\s]/g, "");
          },
        },
        {
          title: "Stages",
          field: "stages",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          // width: 250,
          headerWordWrap: true,
          showByDefault: false,
          formatter(cell) {
            let stageName = cell.getValue();
            const cellData = cell.getData();
            const today = moment().startOf("day");
            let trainingEndDate = null;
            let grantApplyDate = null;
            const SENT_CONFIRMATION_EMAIL_TO_CLIENT = stages.find(
              (stage) => stage.nameSlug == "editApplication"
            ).id;
            const MANUAL_INVOICE_TO_HRDC = stages.find(
              (stage) => stage.nameSlug == "manualInvoiceToHrdc"
            ).id;

            switch (cellData.stageNameSlug) {
              case "clientRevertT3Form":
                stageName = `
                  <span>
                    <span>JD14 & T3 Form</span>
                      <i aria-hidden="true" class="v-icon notranslate mdi mdi-check-circle theme--light green--text" style="cursor: pointer;"></i>
                    <br/>
                    <span>Grant No.</span>
                      <i aria-hidden="true" class="v-icon notranslate mdi mdi-check-circle theme--light green--text" style="cursor: pointer;"></i>
                  </span>
                `;
                break;
              case "manualInvoiceToHrdc":
                if (cellData.stageId == MANUAL_INVOICE_TO_HRDC) {
                  trainingEndDate = moment(
                    cellData.trainingEndDate,
                    "YYYY-MM-DD HH:mm:ssZ"
                  );
                  // If subscription date (26-29), can only submit claim on 30th (SOP to avoid HRDC query).
                  if (
                    trainingEndDate.isAfter(today) ||
                    trainingEndDate.isSame(today)
                  ) {
                    cell.getElement().style.backgroundColor =
                      COMPANY_UNUSUAL_COLOR;
                  } else {
                    cell.getElement().style.backgroundColor = SUCCESS_COLOR;
                  }
                }

                break;

              case "sentConfirmationEmailToClient":
                if (cellData.stageId == SENT_CONFIRMATION_EMAIL_TO_CLIENT) {
                  let cellColor = SUCCESS_COLOR;
                  if (cellData.grantApplyDate) {
                    grantApplyDate = moment(
                      cellData.grantApplyDate,
                      "YYYY-MM-DD HH:mm:ssZ"
                    );

                    if (today.isAfter(grantApplyDate)) {
                      cellColor = COMPANY_UNUSUAL_COLOR;
                    }
                  }
                  cell.getElement().style.backgroundColor = cellColor;
                }
                break;

              case "grantApproved":
                stageName = `
                  <span>
                    <span>Grant No.</span>
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-check-circle theme--light green--text" style="cursor: pointer;"></i>
                    <br/>
                    <span>JD14 & T3 Form</span>
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-close-circle theme--light red--text" style="cursor: pointer;"></i>
                  </span>
                `;
                break;

              case "formsCompleted":
                stageName = `
                  <span>
                    <span>JD14 & T3 Form</span>
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-check-circle theme--light green--text" style="cursor: pointer;"></i>
                    <br/>
                    <span>Grant No.</span>
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-close-circle theme--light red--text" style="cursor: pointer;"></i>
                  </span>
                `;
                break;

              case "clientRevertFormIncomplete":
                stageName = `
                  <span>
                    <span>JD14 & T3 Form</span>
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-close-circle theme--light red--text" style="cursor: pointer;"></i>
                    <br/>
                    <span>Grant No.</span>
                    <i aria-hidden="true" class="v-icon notranslate mdi mdi-close-circle theme--light red--text" style="cursor: pointer;"></i>
                  </span>
                `;
                break;

              case "claimApprovedFromHrdc":
                stageName = "Claim Approved From HRDC";
                if (cellData.isPaymentReceivedPassGracePeriod == true) {
                  stageName += `
                    <i title="Late than 7 days received payment" aria-hidden="true" class="v-icon notranslate mdi mdi-timer-sand-complete theme--light red--text" style="cursor: pointer;"></i>
                  `;
                }
                break;
            }
            return stageName;
          },
        },
        {
          title: "Revert Form Status",
          field: "revertFormStatus",
          dataType: "onlyLikeEqualNotEqual",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          visible: false,
          formatter(cell) {
            let cellStatus = cell.getValue();
            let revertStatus = null;
            if (cellStatus) {
              revertStatus = "Completed";
            } else {
              revertStatus = "Incompleted";
            }

            return revertStatus;
          },
        },
        {
          title: "Order By Refund Email Submission Date",
          field: "REFUND_EMAIL_SUBMISSION",
          dataType: "boolean",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: false,
          hozAlign: "left",
          headerWordWrap: false,
          showByDefault: false,
        },
        {
          title: "Order By Refund Email Approval 1 Date",
          field: "REFUND_EMAIL_APPROVAL_1",
          dataType: "boolean",
          filterable: true,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: false,
          hozAlign: "left",
          headerWordWrap: false,
          showByDefault: false,
        },
        {
          title: "Submission Date",
          field: "timestampCreated",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: false,
          formatter: (cell) => {
            const value = cell.getValue();
            let createdDate = moment(value).format("YYYY-MM-DD HH:mm");
            return createdDate;
          },
        },
        {
          title: "Finance AR Sent Date",
          field: "orderByRefundEmailFinanceAr",
          dataType: "onlyLikeEqualNotEqual",
          filterable: false,
          searchable: false,
          sortable: false,
          headerFilter: false,
          selectableColumn: true,
          hozAlign: "left",
          headerWordWrap: true,
          showByDefault: true,
          formatter: (cell) => {
            const value = cell.getValue();
            if (value) {
              let financeArPrepareDate =
                moment(value).format("YYYY-MM-DD HH:mm");
              return financeArPrepareDate;
            }

            return "-";
          },
        },
      ],
    },
  },
};
