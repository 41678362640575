export default {
  state: {
    data: {
      applicationUuid: null,
      stageId: null,
      firstNameAccEnrollment: null,
      lastNameAccEnrollment: null,
      contactNoAccEnrollment: null,
      email: null,
      viewId: null,
    },
    ssmToken: null,
  },
  getters: {},
  actions: {},
  mutations: {
    assignHrdcData(state, data) {
      state.data = data;
    },
    assignSsmAuthToken(state, token) {
      state.ssmToken = token;
    }
  },
};
