<template>
  <div class="pb-2">
    <v-expansion-panels accordion focusable>
      <v-expansion-panel>
        <v-expansion-panel-header
          >Training Days Reference Chart</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-simple-table dense class="w-50">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Price (RM)</th>
                  <th class="text-left">Training Days</th>
                  <th class="text-left">Claim Per Day (RM)</th>
                  <th class="text-left">Viewing Hours</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in staticDatas" :key="key">
                  <td>{{ item.price }}</td>
                  <td>{{ item.trainingDays }}</td>
                  <td>{{ claimPerDay(item) }} / day</td>
                  <td>{{ item.viewingHours }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  props: {
    datas: Array,
  },
  data: () => ({
    staticDatas: [],
  }),
  created() {
    this.staticDatas = this.datas.reverse();
  },
  methods: {
    claimPerDay(item) {
      return `${(item.price / item.trainingDays).toFixed(2)}`;
    },
  },
};
</script>
