import moment from "moment";
import momentTz from "moment-timezone";
import axios from "axios";
import stages from "@/objects/stages";

const HRDC_CLIENT_NO = "303715";
const ACCOUNT_CODE = "500R-04-02";
const TRACKING_DIVISION = "Division";
const TRACKING_DIVISION_OPTION = "CEO OFFICE";
const TRACKING_PRODUCT = "Product";
const TRACKING_PRODUCT_OPTION = "TaxPOD";
const PAYMENT_VOUCHER_SUCCESS_STATUS = "success";
const PAYMENT_VOUCHER_EXECUTING_STATUS = "executing";
const PAYMENT_VOUCHER_PENDING_STATUS = "pending";
const PAYMENT_VOUCHER_FAILED_STATUS = "failed";
const MENU_ACTIONS_MAP = {
  hrdc: [
    { title: "Edit Details", route: "EditHrdcApplication" },
    { title: "General Info", route: "AddGeneralInfo" },
    { title: "Send HRDC Email to Client", route: "SendHrdcEmailToClient" },
    { title: "Grant Approval & Revert Form", route: "ClientRevertForm" },
    { title: "Manual Invoice to HRDC", route: "ManualInvoiceToHrdc" },
    { title: "Claim Submitted to HRDC", route: "ClaimSubmitToHrdc" },
    { title: "Claim Approved from HRDC", route: "ClaimApprovedFromHrdc" },
    { title: "Payment Received from HRDC", route: "PaymentReceivedFromHrdc" },
  ],
  hrdc_tax_summit: [
    { title: "Edit Details", route: "EditHrdcTaxSummitApplication" },
    { title: "General Info", route: "HrdcTaxSummitAddGeneralInfo" },
    {
      title: "Send HRDC Email to Client",
      route: "HrdcTaxSummitSendEmailToClient",
    },
    {
      title: "Grant Approval & Revert Form",
      route: "HrdcTaxSummitClientRevertForm",
    },
    {
      title: "Manual Invoice to HRDC",
      route: "HrdcTaxSummitManualInvoiceToHrdc",
    },
    {
      title: "Claim Submitted to HRDC",
      route: "HrdcTaxSummitClaimSubmitToHrdc",
    },
    {
      title: "Claim Approved from HRDC",
      route: "HrdcTaxSummitClaimApprovedFromHrdc",
    },
    {
      title: "Payment Received from HRDC",
      route: "HrdcTaxSummitPaymentReceivedFromHrdc",
    },
  ],
  hrdc_tpdiy: [
    {
      title: "Edit Details",
      route: "EditHrdcTpdiyApplication",
    },
    {
      title: "General Info",
      route: "HrdcTpdiyAddGeneralInfo",
    },
    {
      title: "Send HRDC Email to Client",
      route: "HrdcTpdiySendEmailToClient",
    },
    {
      title: "Grant Approval & Revert Form",
      route: "HrdcTpdiyClientRevertForm",
    },
    {
      title: "Manual Invoice to HRDC",
      route: "HrdcTpdiyManualInvoiceToHrdc",
    },
    {
      title: "Claim Submitted to HRDC",
      route: "HrdcTpdiyClaimSubmitToHrdc",
    },
    {
      title: "Claim Approved from HRDC",
      route: "HrdcTpdiyClaimApprovedFromHrdc",
    },
    {
      title: "Payment Received from HRDC",
      route: "HrdcTpdiyPaymentReceivedFromHrdc",
    },
  ],
  hrdc_einvoicing: [
    {
      title: "Edit Details",
      route: "EditHrdcEinvoicingApplication",
    },
    {
      title: "General Info",
      route: "HrdcEinvoicingAddGeneralInfo",
    },
    {
      title: "Send HRDC Email to Client",
      route: "HrdcEinvoicingSendEmailToClient",
    },
    {
      title: "Grant Approval & Revert Form",
      route: "HrdcEinvoicingClientRevertForm",
    },
    {
      title: "Manual Invoice to HRDC",
      route: "HrdcEinvoicingManualInvoiceToHrdc",
    },
    {
      title: "Claim Submitted to HRDC",
      route: "HrdcEinvoicingClaimSubmitToHrdc",
    },
    {
      title: "Claim Approved from HRDC",
      route: "HrdcEinvoicingClaimApprovedFromHrdc",
    },
    {
      title: "Payment Received from HRDC",
      route: "HrdcEinvoicingPaymentReceivedFromHrdc",
    },
  ],
};

export default {
  data: () => ({
    HRDC_CLIENT_NO,
    name1: null,
    name2: null,
    name3: null,
    name4: null,
    confirmationLetterName: null,
    jd14Name: null,
    t3Name: null,
    agendaAtchName: null,
    guidelineName: null,
    flyerName: null,
    attendanceForm: null,
    attendanceFormName: null,
    hrdcType: null,
    xeroRedirectUri: null,
    languages: [
      {
        isoLanguageName: "English",
        isoLanguageCode: "en",
      },
      {
        isoLanguageName: "Chinese",
        isoLanguageCode: "zh",
      },
    ],
    PAYMENT_VOUCHER_SUCCESS_STATUS,
    PAYMENT_VOUCHER_EXECUTING_STATUS,
    PAYMENT_VOUCHER_PENDING_STATUS,
    PAYMENT_VOUCHER_FAILED_STATUS,
    hrdcEmailTemplate: "email-client-template",
    staticTaxpodPricing: [2888, 2388, 1998, 1798, 1498],
    staticTaxpodData: [
      {
        price: 1498,
        flyer: "2.0",
        trainingDays: 4,
        viewingHours: 28,
      },
      {
        price: 1798,
        flyer: "2.0",
        trainingDays: 4,
        viewingHours: 28,
      },
      {
        price: 1998,
        flyer: "",
        trainingDays: 3,
        viewingHours: 21,
      },
      {
        price: 2388,
        flyer: "",
        trainingDays: 3,
        viewingHours: 21,
      },
      {
        price: 2888,
        flyer: "2.0",
        trainingDays: 4,
        viewingHours: 28,
      },
    ],
    paymentAdviceOpts: [
      "Stripe",
      "iPay88",
      "Bank Transfer",
      "Cheque",
      "Credit Card Machine",
      "ATM Cash Deposit",
    ],
    grantRevertOpts: [
      { text: "Grant Approved", value: 1 },
      { text: "Grant Issue", value: 0 },
    ],
    formRevertOpts: [
      { text: "Forms Completed", value: 1 },
      { text: "Forms Issue", value: 0 },
    ],
    yycMobileHotline: "+60193686868",
    learnTaxPodEmail: process.env.VUE_APP_LEARN_EMAIL,
    isSalesperson: ["taxpod-salesperson"],
    isSalesManager: ["taxpod-sales-manager"],
    isHrdcAdmin: ["hrdc-admin"],
    isHrdcSuperAdmin: ["hrdc-super-admin"],
    isTaxpodAdmin: ["taxpod-admin"],
    isTaxpodApproval1Admin: ["hrdc-taxpod-approval-1-admin"],
    isTaxpodApproval2Admin: ["hrdc-taxpod-approval-2-admin"],
    isFinanceArAdmin: ["finance-ar-admin"],
    isFinanceApAdmin: ["finance-ap-admin"],
    isCustomerServiceAdmin: ["taxpod-customer-service"],
    isDmAdmin: ["hrdc-dm-admin"],
    isHrdcAdminRoles: false,
    isHrdcSuperAdminRoles: false,
    isTaxpodAdminRoles: false,
    isTaxpodApproval1AdminRoles: false,
    isTaxpodApproval2AdminRoles: false,
    isFinanceArAdminRoles: false,
    isFinanceApAdminRoles: false,
    isSalespersonRoles: false,
    isSalesManagerRoles: false,
    isCustomerServiceAdminRoles: false,
    isDmAdminRoles: false,
    HRDC_CARD_COLOR: "#ECEFF1",
    HRDC_BLUE_THEME_COLOR: "#063058",
    INVOICE_TYPE: "ACCREC",
    VUE_APP_API_AUTHORIZATION_VISION: "123abc",
    INDEX_MENU_ACTIONS: [],
    paymentMethodItems: [
      "Bank Transfer",
      "Credit Card/FPX/Grab Pay/Apple Pay/Google Pay",
      "Credit Card Instalment Plan",
      "Cheque",
      "iPay88",
      "Other",
    ],
    staticPricing: [],
    EVENT_TYPE: {
      hrdc_einvoicing: 1,
      hrdc_tax_summit: 2,
      hrdc_tpdiy: 3,
    },
    stagePriority: null,
    stageSlug: null,
    stageIndex: null,
    stageSlugToStageName: null,
    EVENT_BULK_NEW_APPLICATION_TEMPLATE_NAME: "Bulk Application Template.xlsx",
    EVENT_BULK_NEW_APPLICATION_DUMMY_TEMPLATE_NAME:
      "Bulk Application Template (Dummy).xlsx",
    EVENT_BULK_NEW_APPLICATION_TEMPLATE_FILE_URL:
      "assets/storage/bulk_application_template.xlsx",
    EVENT_BULK_NEW_APPLICATION_DUMMY_TEMPLATE_FILE_URL:
      "assets/storage/dummy_bulk_application_template.xlsx",
    EVENT_BULK_CASE_ASSIGNATION_PIC_EMAIL: "kokxiang.lim@yycadvisors.com",
    EVENT_BULK_CASE_ASSIGNATION_PIC_NAME: "Kenneth Lim Kok Xiang",
    speakers: [
      "Zen Chow",
      "Tifannyz",
      "Fong Ying",
      "Jeremy Tan Lai Fu",
      "Shin",
    ],
    CLAIM_APPLICATION_GUIDELINE: 1,
    COURSE_CONTENT_AGENDA: 2,
    staticTaxpodDocs: ["guideline", "flyer"],
    staticEventDocs: ["guideline", "agenda"],
    // Total Key Stages for taxPOD.
    taxPODPayAndRefundKeyStages: 14,
    taxPODClaimBasisKeyStages: 8,
    // Total Key Stages for Event.
    eventPayAndRefundKeyStages: 14,
    eventClaimBasisKeyStages: 7,
    SURVEY_RESTRICT_FIELD: ["textarea"],
    REFUND_EVENT_ATTACHMENTS: [
      "paymentAdviceFromHrdc",
      "creditNote",
      "autoGeneratedClientInvoice",
      "paymentAdviceFromClient",
    ],
    REFUND_TAXPOD_ATTACHMENTS: [
      "paymentAdviceFromHrdc",
      "creditNote",
      "autoGeneratedTaxpodInvoice",
      "paymentAdviceFromClient",
    ],
    TAXPOD_INVOICE_REFERENCE: `PEMBANGUNAN SUMBER MANUSIA BERHAD\nKEMENTERIAN SUMBER MANUSIA\nWISMA HRD CORP\nJALAN BERINGIN\nBUKIT DAMANSARA\nKUALA LUMPUR KUALA LUMPUR 50490\nMALAYSIA`,
    generateCnOption: [
      {
        text: "Normal CN",
        value: "NORMAL_CN",
      },
      {
        text: "Special CN",
        value: "SPECIAL_CN",
      },
    ],
  }),
  created() {
    const allStage = [];
    const allStagePriority = [];
    const allStageSlug = [];
    const allStageIndex = [];
    const allStageSlugToStageName = [];

    for (let i = 0; i < stages.length; i++) {
      let resultData = stages[i];
      let stageName = resultData.name.toUpperCase().replace(/ /g, "_");
      let stageNameSlug = resultData.nameSlug;

      allStage[stageName] = resultData.id;
      allStagePriority[stageNameSlug] = resultData.id;
      allStageSlug[stageNameSlug] = resultData.stagePriority;
      allStageIndex[resultData.id] = resultData.stagePriority;
      allStageSlugToStageName[stageNameSlug] = resultData.name;
    }

    this.stagePriority = allStagePriority;
    this.stageSlug = allStageSlug;
    this.stageIndex = allStageIndex;
    this.stageSlugToStageName = allStageSlugToStageName;
  },
  methods: {
    localeDate() {
      return momentTz().tz(process.env.VUE_APP_TZ);
    },
    // Check if form already filled.
    checkFormAlreadyFilledGte(currentStageId, maxStageId, errorMsg) {
      this.checkFormAlreadyFilled(currentStageId, maxStageId, true, errorMsg);
    },
    checkFormAlreadyFilledGreater(currentStageId, maxStageId, errorMsg) {
      this.checkFormAlreadyFilled(currentStageId, maxStageId, false, errorMsg);
    },
    checkFormAlreadyFilled(
      currentStageId,
      maxStageId,
      inclusive,
      errorMessage = null
    ) {
      if (
        (inclusive && currentStageId >= maxStageId) ||
        (!inclusive && currentStageId > maxStageId)
      ) {
        let errorMsg = "Please be informed that this form already filled in!";
        if (errorMessage) {
          errorMsg = errorMessage;
        }
        this.alertError.push(errorMsg);
        this.editMode = true;
      }
    },
    checkFormNotYetFilledLesser(
      currentStageId,
      maxStageId,
      errorMessage = null
    ) {
      if (currentStageId < maxStageId) {
        let errorMsg = "Please complete previous step";
        if (errorMessage) {
          errorMsg = errorMessage;
        }
        this.alertError.push(errorMsg);
        this.btnDisabled = true;
      }
    },
    roleMiddleware(roles, serviceKey) {
      let route = null;
      switch (serviceKey) {
        case "hrdc_einvoicing":
          route = "HrdcEinvoicingApplication";
          break;
        case "hrdc_tax_summit":
          route = "HrdcTaxSummitApplication";
          break;
        case "hrdc_tpdiy":
          route = "HrdcTpdiyApplication";
          break;
      }

      if (
        roles.length == 0 ||
        (roles.length == 1 &&
          roles.find((item) => item.slug == "taxpod-salesperson"))
      ) {
        this.$router.push({
          name: route,
          query: {
            viewId: "all",
          },
          params: {
            modelKey: serviceKey,
          },
        });

        return false;
      }
    },
    serviceDataAssignation(serviceKey) {
      switch (serviceKey) {
        case "hrdc":
          this.name1 = "taxPOD";
          this.xeroRedirectUri = `${process.env.VUE_APP_URL}/en/service/${serviceKey}/callback`;
          this.INDEX_MENU_ACTIONS = MENU_ACTIONS_MAP[serviceKey] || [];
          this.guidelineName = `Grant Application Guide - YYC TaxPOD - `;
          this.flyerName = `Flyer-`;
          break;
        case "hrdc_einvoicing":
          this.name1 = "e-Invoicing";
          this.name2 = "E - INVOICING";
          this.name3 = "E-Invoicing";
          this.name4 = "CONFERENCE";
          this.confirmationLetterName = `CONFIRMATION_LETTER_E_INVOICING_TEMPLATE`; // Render via auto embed data file name.
          this.jd14Name = `JD14_E_INVOICING_TEMPLATE`;
          this.t3Name = `T3_E_INVOICING_TEMPLATE`;
          this.agendaAtchName = `YYC e-Invoicing Conference Course Content`;
          this.guidelineName = `YYC E - INVOICING CONFERENCE - HRDC Grant and Claim Application Procedure`;
          this.hrdcType = 1;
          this.xeroRedirectUri = `${process.env.VUE_APP_URL}/en/service/${serviceKey}/callback_einvoicing`;
          this.INDEX_MENU_ACTIONS = MENU_ACTIONS_MAP[serviceKey] || [];
          this.staticPricing = [2600, 1800];
          this.hrdcEmailTemplate = "email-client-einvoicing-template";
          break;
        case "hrdc_tax_summit":
          this.name1 = "Tax Summit";
          this.name2 = "Tax Summit";
          this.name3 = "Tax Summit";
          this.name4 = "Tax Summit";
          this.confirmationLetterName = `CONFIRMATION_LETTER_TAX_SUMMIT_TEMPLATE`; // Render via auto embed data file name.
          this.jd14Name = `JD14_TAX_SUMMIT_TEMPLATE`;
          this.t3Name = `T3_TAX_SUMMIT_TEMPLATE`;
          this.agendaAtchName = `YYC Tax Summit Conference Course Content`;
          this.guidelineName = `YYC Tax Summit CONFERENCE - HRDC Grant and Claim Application Procedure`;
          this.hrdcType = 2;
          this.xeroRedirectUri = `${process.env.VUE_APP_URL}/en/service/${serviceKey}/callback_tax_summit`;
          this.INDEX_MENU_ACTIONS = MENU_ACTIONS_MAP[serviceKey] || [];
          this.staticPricing = [498, 998];
          break;
        case "hrdc_tpdiy":
          this.name1 = "TPDIY";
          this.name2 = "TPDIY";
          this.name3 = "Transfer Pricing Doc DIY Conference";
          this.name4 = "TPDIY";
          this.confirmationLetterName = `CONFIRMATION_LETTER_TPDIY_TEMPLATE`; // Render via auto embed data file name.
          this.jd14Name = `JD14_TPDIY_TEMPLATE`;
          this.t3Name = `T3_TPDIY_TEMPLATE`;
          this.agendaAtchName = `YYC Transfer Pricing Documentation Do It Yourself Conference Course Content`;
          this.guidelineName = `YYC TRANSFER PRICING DOCUMENTATION DIY CONFERENCE - HRDC Grant and Claim Application Procedure`;
          this.hrdcType = 3;
          this.xeroRedirectUri = `${process.env.VUE_APP_URL}/en/service/${serviceKey}/callback_tpdiy`;
          this.attendanceForm = "YYC TPDIY ATTENDANCE FORM";
          this.attendanceFormName = "ATTENDANCE_FORM_TPDIY_TEMPLATE";
          this.hrdcEmailTemplate = "email-client-tpdiy-template";
          this.INDEX_MENU_ACTIONS = MENU_ACTIONS_MAP[serviceKey] || [];
          this.staticPricing = [1400];
          break;
      }
    },
    getOriginalAttachmentFileExtension(fileObj) {
      return new Promise((resolve) => {
        var fileReader = new FileReader();
        fileReader.readAsArrayBuffer(fileObj);
        fileReader.onloadend = function (e) {
          var arr = new Uint8Array(e.target.result).subarray(0, 4);
          var header = "";
          for (var i = 0; i < arr.length; i++) {
            header += arr[i].toString(16);
          }

          let fileOriginalExtension = null;

          // Check the file signature against known types
          switch (header) {
            case "25504446":
              fileOriginalExtension = "application/pdf";
              break;
            case "89504e47":
              fileOriginalExtension = "image/png";
              break;
            case "ffd8ffe0":
            case "ffd8ffe1":
            case "ffd8ffe2":
            case "ffd8ffe3":
            case "ffd8ffe8":
              fileOriginalExtension = "image/jpeg";
              break;
            default:
              fileOriginalExtension = fileObj.type;
              break;
          }

          resolve(fileOriginalExtension);
        };
      });
    },
    getEnv() {
      let env = "local";
      switch (process.env.VUE_APP_ENV) {
        case "local":
          env = "local";
          break;
        case "dev":
          env = "dev";
          break;
        case "production":
          env = "production";
          break;
      }

      return env;
    },
    getInvoiceStatus() {
      let invoiceStatus = "DRAFT";
      const env = this.getEnv();
      if (env == "production") {
        invoiceStatus = "AUTHORISED";
      }

      return invoiceStatus;
    },
    getService(serviceKey) {
      switch (serviceKey) {
        case "hrdc":
          return {
            url: process.env.VUE_APP_SERVICE_URL_HRDC,
          };
        case "hrdc_einvoicing":
          return {
            url: process.env.VUE_APP_SERVICE_URL_HRDC_EINVOICING,
          };
        case "hrdc_tax_summit":
          return {
            url: process.env.VUE_APP_SERVICE_URL_HRDC_TAX_SUMMIT,
          };
        case "hrdc_tpdiy":
          return {
            url: process.env.VUE_APP_SERVICE_URL_HRDC_TPDIY,
          };
      }
    },
    requestAuthorizeApp(xeroParams = null) {
      const responseType = "code";
      const clientId = process.env.VUE_APP_XERO_CLIENT_ID;
      const scope =
        "openid offline_access accounting.transactions accounting.contacts accounting.settings accounting.attachments accounting.attachments.read";
      let redirectUri = `${process.env.VUE_APP_URL}/en/service/hrdc/callback`;
      let state = this.$route.query._ausk;

      if (xeroParams) {
        redirectUri = xeroParams.redirectCallbackUri;
        state = xeroParams.stateType;
      }

      const getAccessTokenUrl = `
        ${process.env.VUE_APP_XERO_LOGIN_URL}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}
      `;

      return getAccessTokenUrl;
    },
    parseJwt(token) {
      return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
    },
    // Get XERO basic authorization code.
    getXeroBasicAuthorizationCode() {
      const clientId = process.env.VUE_APP_XERO_CLIENT_ID;
      const clientSecret = process.env.VUE_APP_XERO_CLIENT_SECRET;
      const authorization = Buffer.from(clientId + ":" + clientSecret).toString(
        "base64"
      );

      return authorization;
    },
    // Request access token by refresh token.
    async xeroRequestAccessToken(url, bearerToken, refreshToken) {
      return await axios
        .post(
          url,
          {
            url: process.env.VUE_APP_XERO_EXCHANGE_CODE_URL,
            grant_type: "refresh_token",
            client_id: process.env.VUE_APP_XERO_CLIENT_ID,
            refresh_token: refreshToken,
            basic_auth_token: this.getXeroBasicAuthorizationCode(),
          },
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        )
        .then((response) => {
          if (
            !response ||
            response.status !== 200 ||
            !response.data.data.access_token
          ) {
            throw new Error(
              "Unable to generate access token via refresh token"
            );
          }

          return response.data;
        })
        .catch((err) => {
          // console.log(err)
          return err.message;
        });
    },
    async xeroRequestTenantId({ url, headers, postData }) {
      return await axios
        .post(
          url,
          { ...postData },
          {
            headers,
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          console.log(err);
          return err.message;
        });
    },
    async xeroCallApi(item) {
      let trackingCategories = null;
      let divisonTracking = null;
      let divisonTrackingCategory = null;
      let divisonTrackingOption = null;
      let productTracking = null;
      let productTrackingCategory = null;
      let productTrackingOption = null;
      const { authorization, ...data } = item;
      const domainUrl = this.getService(data.service_key).url;
      const url = `${domainUrl}/v1/en/console/xero/xero-api`;

      return await axios
        .post(url, data, {
          headers: {
            Authorization: `Bearer ${authorization}`,
          },
          ...(data.config && Object.keys(data.config).length > 0
            ? data.config
            : {}),
        })
        .then((response) => {
          switch (data.api_type) {
            case "get_contacts":
              return response.data.Contacts.find((item) => {
                return item.AccountNumber == HRDC_CLIENT_NO;
              });

            case "get_accounts":
              return response.data.Accounts.find((item) => {
                return item.Code == ACCOUNT_CODE;
              });

            case "get_tracking_categories":
              trackingCategories = response.data.TrackingCategories;

              divisonTrackingCategory = trackingCategories.find(
                (category) => category.Name === TRACKING_DIVISION
              );

              // Division - CEO Office.
              divisonTrackingOption = divisonTrackingCategory.Options.find(
                (option) => option.Name === TRACKING_DIVISION_OPTION
              );

              divisonTracking = {
                Name: divisonTrackingCategory.Name,
                TrackingCategoryID: divisonTrackingCategory.TrackingCategoryID,
                Option: divisonTrackingOption.Name,
              };

              // Product - TaxPOD.
              productTrackingCategory = trackingCategories.find(
                (category) => category.Name === TRACKING_PRODUCT
              );

              productTrackingOption = productTrackingCategory.Options.find(
                (option) => option.Name === TRACKING_PRODUCT_OPTION
              );

              productTracking = {
                Name: productTrackingCategory.Name,
                TrackingCategoryID: productTrackingCategory.TrackingCategoryID,
                Option: productTrackingOption.Name,
              };

              return {
                divisonTracking,
                productTracking,
              };

            default:
              return response;
          }
        });
    },
    calculateLineAmounts(amount, taxRate) {
      const unitAmount = amount / (taxRate / 100 + 1);
      const taxAmount = amount - unitAmount;
      return { unitAmount, taxAmount };
    },
    convertDateFormat(date) {
      if (date) {
        return moment(date, "YYYY-MM-DD HH:mm:ssZ").format("D MMMM YYYY");
      }

      return "-";
    },
    generateHrdcInvoiceDesc({
      programmeName,
      trainingDate,
      companyName,
      grantApprovalNo,
      note,
    }) {
      let description = `${programmeName}\nEvent Name: ${programmeName}\nDate (Day): ${trainingDate}\nCompany Name: ${companyName.toUpperCase()}\nGrant No: ${grantApprovalNo}`;
      if (note) {
        description = description + `\n\nNote: ${note}`;
      }

      return description;
    },
    getLanguageByIsoCode(isoCode) {
      if (!isoCode) {
        return "English";
      }

      return this.languages.find((lang) => lang.isoLanguageCode == isoCode)
        .isoLanguageName;
    },
    configMenu(data) {
      const disabledRolesForHrdc = "taxpod-salesperson";
      const rolesArray = data.roles.map((item) => item.slug);

      for (const menu of data.menu) {
        // Start with default permission
        menu.isAllowed = true;

        // Handle disabled roles logic
        const isSingleDisabledRole =
          rolesArray.length === 1 && rolesArray[0] === disabledRolesForHrdc;
        const isNoRole = rolesArray.length === 0;

        if (
          (isSingleDisabledRole || isNoRole) &&
          menu.disabledForRoles?.includes(disabledRolesForHrdc)
        ) {
          menu.isAllowed = false;
        }

        // Handle only allowed roles logic
        if (menu.onlyAllowForRoles) {
          menu.isAllowed = rolesArray.some((role) =>
            menu.onlyAllowForRoles.includes(role)
          );
        }
      }
    },
    formatEventTitle(item, hrdcType) {
      const languageName = this.getLanguageByIsoCode(item.language);

      switch (hrdcType) {
        case 2:
          return `${item.title} (${moment(item.startDate).format(
            "DD/MM/YYYY"
          )}) - ${languageName}`;
        default:
          return `${item.title} (${moment(item.startDate).format(
            "DD/MM/YYYY"
          )} - ${moment(item.endDate).format("DD/MM/YYYY")}) - ${languageName}`;
      }
    },
    showLoadingDialog() {
      this.loadingText = "Loading...";
      this.loadingDialog = true;
    },
    hideLoadingDialog() {
      this.loadingDialog = false;
    },
    getTaxpodBasePricing(price) {
      for (const pricing of this.staticTaxpodPricing) {
        if (price % pricing == 0) {
          return pricing;
        }
      }

      return false;
    },
    rolesAssignation(roles) {
      roles.forEach((item) => {
        if (this.isHrdcAdmin.includes(item.slug)) {
          this.isHrdcAdminRoles = true;
        }
        if (this.isHrdcSuperAdmin.includes(item.slug)) {
          this.isHrdcSuperAdminRoles = true;
        }
        if (this.isTaxpodAdmin.includes(item.slug)) {
          this.isTaxpodAdminRoles = true;
        }
        if (this.isTaxpodApproval1Admin.includes(item.slug)) {
          this.isTaxpodApproval1AdminRoles = true;
        }
        if (this.isTaxpodApproval2Admin.includes(item.slug)) {
          this.isTaxpodApproval2AdminRoles = true;
        }
        if (this.isFinanceArAdmin.includes(item.slug)) {
          this.isFinanceArAdminRoles = true;
        }
        if (this.isFinanceApAdmin.includes(item.slug)) {
          this.isFinanceApAdminRoles = true;
        }
        if (this.isSalesperson.includes(item.slug)) {
          this.isSalespersonRoles = true;
        }
        if (this.isSalesManager.includes(item.slug)) {
          this.isSalesManagerRoles = true;
        }
        if (this.isCustomerServiceAdmin.includes(item.slug)) {
          this.isCustomerServiceAdminRoles = true;
        }
        if (this.isDmAdmin.includes(item.slug)) {
          this.isDmAdminRoles = true;
        }
      });
    },
    getBasePricing(price) {
      for (const pricing of this.staticPricing) {
        if (price % pricing == 0) {
          return pricing;
        }
      }

      return false;
    },
    capitalizeFirstLetter(string) {
      if (!string) {
        return "";
      }

      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    calculateStagePercentage(item) {
      const { isPaymentMade, keyStage } = item;

      const totalKeyStages = !this.hrdcType
        ? isPaymentMade
          ? this.taxPODPayAndRefundKeyStages
          : this.taxPODClaimBasisKeyStages
        : isPaymentMade
        ? this.eventPayAndRefundKeyStages
        : this.eventClaimBasisKeyStages;

      // Calculate the percentage
      return Math.ceil((keyStage / totalKeyStages) * 100);
    },
    clearErrorMsg(field = null) {
      if (!field) {
        for (let error in this.errorField) {
          this.errorField[error] = null;
        }
      } else {
        this.errorField[field] = null;
      }
    },
    stringToColor(str) {
      // Generate a hash from the string
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      // Convert the hash to an RGB color
      let color = "#";
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff; // Extract RGB components
        color += ("00" + value.toString(16)).slice(-2); // Convert to hex
      }

      return color;
    },
  },
};
