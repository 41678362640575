<template>
  <v-container>
    <v-dialog
      persistent
      v-model="generateSpecialCnModal"
      transition="dialog-bottom-transition"
      max-width="700"
    >
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark
          >Generate Special CN Case</v-toolbar
        >
        <v-card-text class="pa-5">
          <div class="pb-2">
            Available HRDC Inv No.: {{ formData.hrdcInvoiceNo }}
          </div>
          <v-row cols="12">
            <v-col class="d-flex pb-0">
              <v-text-field
                ref="specialCnPax"
                type="number"
                dense
                filled
                label="CN pax"
                v-model="formData.specialCnPax"
                :error-messages="errorField.specialCnPax"
                @input="onInputDigits(null, 'specialCnPax')"
              ></v-text-field>
            </v-col>
          </v-row>

          <div
            v-for="(pax, index) in Array.from(
              { length: Number(formData.specialCnPax) },
              (_, i) => i + 1
            )"
            :key="index"
          >
            <hr />

            <!-- Client INV No. -->
            <v-row>
              <v-col cols="12" class="py-0">
                <div>
                  <span>Client Inv No. {{ pax }}</span>
                  <span class="text-danger"> *</span><br />
                </div>
              </v-col>
              <v-col cols="12" class="py-0">
                <div>
                  <v-text-field
                    ref="clientInvNo"
                    dense
                    filled
                    :error-messages="errorField.clientInvNo[index]"
                    placeholder="YGC-XXXXXX"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>

            <!-- HRDC INV No. -->
            <v-row>
              <v-col cols="12" class="py-0">
                <div>
                  <span>HRDC Inv No. {{ pax }}</span>
                  <span class="text-danger"> *</span><br />
                </div>
              </v-col>
              <v-col cols="12" class="py-0">
                <div>
                  <v-text-field
                    ref="hrdcInvNo"
                    dense
                    filled
                    :error-messages="errorField.hrdcInvNo[index]"
                    placeholder="YGC-XXXXXX"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>

            <!-- CN Amount -->
            <v-row>
              <v-col cols="12" class="pb-0">
                <div>
                  <span>CN Amount {{ pax }}</span>
                  <span class="text-danger"> *</span><br />
                </div>
              </v-col>
              <v-col cols="12" class="py-0">
                <div>
                  <v-text-field
                    ref="cnAmount"
                    type="number"
                    dense
                    filled
                    :error-messages="errorField.cnAmount[index]"
                    placeholder="2888, 2388, ..."
                    @input="onInputDigits(index, 'cnAmount')"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>

            <!-- CN Description -->
            <v-row>
              <v-col cols="12" class="py-0">
                <div>
                  <span>CN Description {{ pax }}</span>
                  <span class="text-danger"> *</span><br />
                </div>
              </v-col>
              <v-col cols="12" class="py-0">
                <div>
                  <v-textarea
                    ref="cnDesc"
                    dense
                    filled
                    auto-grow
                    :error-messages="errorField.cnDesc[index]"
                    :placeholder="cnDescPlaceholder"
                  ></v-textarea>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            elevation="0"
            color="green lighten-1"
            class="text-light"
            @click="saveGenerateSpecialCnInfoChild()"
          >
            Confirm
          </v-btn>

          <v-btn
            elevation="0"
            color="red lighten-1"
            class="text-light"
            @click="toggleGenerateSpecialCnModal(false)"
            >Discard</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "GenerateSpecialCn",
  props: {
    generateSpecialCnModal: Boolean,
    formData: Object,
    errorField: Object,
  },
  emits: ["toggleGenerateSpecialCnModal"],
  data: () => ({
    cnDescPlaceholder: `TaxPOD Masterclass 1-year pass to all trainings and webinars from <Start Date> to <End Date>\n- Stripe Ref: in_XXXXXX`,
  }),
  methods: {
    toggleGenerateSpecialCnModal(val) {
      this.$emit("toggleGenerateSpecialCnModal", val);
    },
    onInputDigits(index = null, field) {
      let input = null;
      if (index || index == 0) {
        input = this.$refs[field][index];
      } else {
        input = this.$refs[field];
      }

      input.$el.querySelector("input").value = input.$el
        .querySelector("input")
        .value.replace(/[^0-9]/g, "");
    },
    saveGenerateSpecialCnInfoChild() {
      this.$emit("saveGenerateSpecialCnInfoChild", this.$refs);
    },
  },
};
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
